/*-------- 12. Subscribe style ---------*/

.subscribe-form {
    .subscribe-form-style {
        .mc-form {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            @media #{$xs-layout} {
                display: block;
                text-align: center;
            }
            input {
                border: 2px solid #cccccc;
                color: #818181;
                font-style: italic;
                padding: 2px 18px;
                width: 440px;
                height: 55px;
                margin-right: 10px;
                @media #{$lg-layout} {
                    width: 360px;
                }
                @media #{$md-layout} {
                    width: 360px;
                }
                @media #{$xs-layout} {
                    width: 100%;
                    margin-right: 0px;
                }
                &::-webkit-input-placeholder {
                    color: #818181;
                    opacity: 1;
                }
                &::-moz-input-placeholder {
                    color: #818181;
                    opacity: 1;
                }
            }
            .mc-news {
                display: none;
            }
            .clear {
                margin-left: 10px;
                @media #{$xs-layout} {
                    margin-left: 0px;
                    margin-top: 15px;
                }
                input {
                    width: auto;
                    background-color: $theme-color;
                    color: $white;
                    font-size: 16px;
                    font-weight: 500;
                    border: none;
                    font-style: normal;
                    padding: 2px 33px;
                    &:hover {
                        background-color: $black;
                    }
                }
            }
        }
    }
}







/*-------- 14. Product details style ---------*/

.product-details-content {
    padding: 0 0 0 20px;
    @media #{$md-layout} {
        padding: 40px 0 0 0px;
    }
    @media #{$xs-layout} {
        padding: 30px 0 0 0px;
        &.pt-small-none {
            padding: 0px 0 0 0px;
        }
    }
    h2 {
        font-size: 30px;
        font-weight: 500;
        line-height: 1;
        margin: 0;
        @media #{$md-layout} {
            font-size: 25px;
        }
        @media #{$xs-layout} {
            font-size: 24px;
        }
    }
    .product-details-price {
        margin: 14px 0 23px;
        span {
            font-weight: 500;
            display: inline-block;
            &.old-price {
                color: #555252;
                font-size: 18px;
                text-decoration: line-through;
                margin-right: 12px;
            }
            &.new-price {
                color: $theme-color;
                font-size: 20px;
            }
        }
    }
    .product-details-review {
        display: flex;
        align-items: center;
        .product-rating {
            i {
                font-size: 18px;
                margin-right: 4px;
                color: $theme-color;
                @media #{$xs-layout} {
                    font-size: 16px;
                    margin-right: 2px;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        span {
            font-weight: 500;
            color: #9f9e9e;
            display: inline-block;
            margin-left: 20px;
        }
    }
    p {
        margin: 0 0 34px;
        @media #{$md-layout} {
            margin: 0 0 21px;
        }
        @media #{$xs-layout} {
            margin: 0 0 21px;
        }
    }
    .product-details-meta {
        border-bottom: 1px solid #f2f2f2;
        margin: 34px 0 40px;
        padding-bottom: 33px;
        @media #{$xs-layout} {
            margin: 18px 0 23px;
            padding-bottom: 18px;
        }
        > ul {
            > li {
                color: #9f9e9e;
                font-weight: 500;
                font-size: 16px;
                display: flex;
                align-items: center;
                margin: 0 0 13px;
                @media #{$xs-layout} {
                    margin: 0 0 7px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                span {
                    color: #5f5f5f;
                    margin-right: 5px;
                }
                ul {
                    display: flex;
                    li {
                        a {
                            color: #9f9e9e;
                            font-weight: 500;
                            font-size: 16px;
                            &:hover {
                                color: $theme-color;
                            }
                        }
                    }
                }
            }
        }
    }
    .social-icon-style-4 {
        a {
            background-color: #f2f2f2;
            width: 42px;
            height: 42px;
            line-height: 42px;
            font-size: 16px;
            color: #919191;
            display: inline-block;
            text-align: center;
            margin-right: 7px;
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                background-color: $theme-color;
                color: $white;
            }
        }
    }
    &.quickview-content {
        padding: 0 0 0 30px;
        @media #{$xs-layout} {
            padding: 26px 0 0 0px;
        }
        h2 {
            padding-right: 70px;
            @media #{$xs-layout} {
                padding-right: 0px;
            }
        }
    }
}
.product-details-small-img-wrap {
    position: relative;
}

.product-details-vertical-wrap {
    display: flex;
    align-items: center;
    .product-details-small-img-wrap {
        position: relative;
        width: 85px;
        margin-right: 15px;
        .product-details-small-img-slider-1 {
            height: 420px;
            @media #{$lg-layout} {
                height: 300px;
            }
            @media #{$xs-layout} {
                height: 190px;
            }
            @media #{$sm-layout} {
                height: 420px;
            }
        }
    }
    .product-details-big-img-slider-1 {
        width: calc(100% - 100px);
    }
}

.pd-small-img-style {
    .swiper-slide-thumb-active {
        .product-details-small-img {
            img {
                opacity: 1;
            }
        }
    }
    .product-details-small-img {
        img {
            width: 100%;
            opacity: .5;
            cursor: pointer;
        }
    }
    &.pd-small-img-style-modify {
        margin: 20px 30px 0;
    }
}

.pd-nav-style {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -43px;
    z-index: 5;
    @media #{$xs-layout} {
        top: -35px;
    }
    @media #{$sm-layout} {
        top: -21px;
    }
    i {
        font-size: 24px;
        color: $black;
        transition: all .3s ease 0s;
        @media #{$xs-layout} {
            font-size: 20px;
        }
        &:hover {
            color: $theme-color;
        }
    }
    &.pd-next {
        top: auto;
        bottom: -43px;
        @media #{$xs-layout} {
            bottom: -35px;
        }
        @media #{$sm-layout} {
            bottom: -21px;
        }
    }
}

.pd-nav-style-2 {
	position: absolute;
	left: 0;
	top: 47%;
	transform: translateY(-50%);
    @media #{$xs-layout} {
        top: 50%;
    }
    i {
        font-size: 24px;
        color: $black;
        transition: all .3s ease 0s;
        @media #{$xs-layout} {
            font-size: 20px;
        }
        &:hover {
            color: $theme-color;
        }
    }
    &.pd-next-2 {
        left: auto;
        right: 0;
    }
}

.easyzoom-style {
    position: relative;
    .easyzoom {
        display: block;
        > a {
            transition: none;
            img {
                width: 100%;
                transition: none;
            } 
        }
    }
    a {
        &.easyzoom-pop-up {
            position: absolute;
            right: 20px;
            top: 20px;
            width: 50px;
            height: 50px;
            line-height: 50px;
            display: inline-block;
            background-color: #fff;
            color: #000;
            font-size: 23px;
            text-align: center;
            border-radius: 100%;
            z-index: 992;
            box-shadow: 0 0 19px 2.9px rgba(0,0,0,.02);
            i {
                line-height: 50px;
            }
            &:hover {
                background-color: $theme-color;
                color: $white;
            }
        }
    }
}

.description-review-topbar {
    justify-content: center;
    background-color: #f2f2f2;
    padding: 13px 10px;
    margin: 0 0 42px;
    @media #{$xs-layout} {
        padding: 13px 5px;
        margin: 0 0 28px;
    }
    a {
        font-size: 16px;
        color: #919191;
        display: inline-block;
        margin: 0 21px;
        @media #{$xs-layout} {
            margin: 0 8px;
        }
        @media #{$sm-layout} {
            margin: 0 15px;
        }
        &.active {
            color: $theme-color;
        }
    }
}

.product-description-content {
    p {
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        margin: 0 auto 29px;
        width: 67%;
        font-family: $montserrat;
        @media #{$lg-layout} {
            width: 82%;
        }
        @media #{$md-layout} {
            font-size: 15px;
            width: 100%;
            line-height: 27px;
        }
        @media #{$xs-layout} {
            font-size: 14px;
            width: 100%;
            line-height: 27px;
            margin: 0 auto 20px;
        }
        &:last-child {
            margin: 0 auto 0px;
        }
    }
}

.specification-wrap {
    table {
        width: 100%;
        tbody {
            border: 1px solid #ebebeb;
            width: 100%;
            tr {
                border-bottom: 1px solid #ebebeb;
                td {
                    border-right: 1px solid #ebebeb;
                    font-size: 15px;
                    padding: 12px 10px;
                    @media #{$xs-layout} {
                        padding: 12px 15px;
                    }
                }
                td.width1 {
                    width: 215px;
                    font-size: 16px;
                    font-weight: 500;
                    @media #{$xs-layout} {
                        width: auto;
                    }
                }
            }
        }
    }
}
.review-wrapper {
    h3 {
        font-size: 24px;
        margin: 0 0 39px;
        line-height: 20px;
        font-weight: 500;
        @media #{$xs-layout} {
            font-size: 17px;
            margin: 0 0 18px;
        }
        @media #{$sm-layout} {
            font-size: 18px;
        }
    }
}

.single-review {
	margin: 0 0 33px;
    display: flex;
    border-bottom: 1px solid #ebebeb;
    padding: 0 0 34px;
    @media #{$xs-layout} {
        display: block;
        margin: 0 0 20px;
        padding: 0 0 20px;
    }
    @media #{$sm-layout} {
        display: flex;
    }
    .review-img {
        img {
            width: 100px;
        }
    }
    .review-content {
        margin-left: 30px;
        @media #{$xs-layout} {
            margin-left: 0px;
        }
        @media #{$sm-layout} {
            margin-left: 20px;
        }
        h5 {
            font-size: 14px;
            margin: 11px 0 8px;
            span {
                font-size: 14px;
            }
        }
        .review-rating {
            line-height: 1;
            @media #{$xs-layout} {
                margin: 10px 0 0px;
            }
            @media #{$sm-layout} {
                margin: 0px 0 0px;
            }
            a {
                color: #e97730;
            }
        }
        p {
            line-height: 25px;
            margin: 0;
            @media #{$xs-layout} {
                font-size: 15px;
            }
        }
    }
}

.ratting-form-wrapper {
    > h3 {
        display: block;
        line-height: 1;
        font-size: 20px;
        font-weight: 500;
        margin: 0;
    }
    > p {
        margin: 5px 0 0;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(0,0,0,.1);
        @media #{$xs-layout} {
            margin: 15px 0 0;
        }
    }
    .your-rating-wrap {
        display: flex;
        align-items: center;
        margin: 36px 0 0px;
        @media #{$xs-layout} {
            margin: 20px 0 0px;
        }
        span {
            font-size: 16px; 
            display: inline-block;
            margin-right: 20px;
        }
        .your-rating {
            a {
                color: #666;
                &:hover {
                    color: #e97730;
                }
            }
        }
    }
    .ratting-form {
        margin: 19px 0 0;
        .rating-form-style {
            label {
                margin: 0 0 7px;
                font-size: 14px;
                display: block;
                font-weight: 500;
            }
            input , textarea {
                border: 1px solid #f2f2f2;
                background-color: #f9f9f9;
                height: 50px;
                color: #8f8f8f;
                font-weight: 300;
                padding: 2px 20px;
                &::-moz-input-placeholder {
                    color: #8f8f8f;
                    opacity: 1;
                }
                &::-webkit-input-placeholder {
                    color: #8f8f8f;
                    opacity: 1;
                }
            }
            textarea {
                height: 150px;
                padding: 17px 20px;
                @media #{$xs-layout} {
                    height: 100px;
                }
            }
        }
        .save-email-option {
            > p {
                display: flex;
                align-items: center;
                margin: 0 0 5px;
                input {
                    width: auto;
                    height: auto;
                }
            }
            label {
                margin: 0 0 0 16px;
            }
        }
        .form-submit {
            margin-top: 9px;
            input {
                padding: 13px 45px;
                color: #fff;
                display: inline-block;
                width: auto;
                height: auto;
                font-size: 16px;
                text-transform: capitalize;
                background: $black;
                border: none;
                font-weight: 500;
                &:hover {
                    background: $theme-color;
                }
            }
        }
    }
}

.affiliate-btn {
    > a {
        display: inline-block;
        font-weight: 600;
        color: $white;
        background-color: $theme-color;
        padding: 20px 37px;
        z-index: 1;
        @media #{$md-layout} {
            padding: 17px 30px;
        }
        @media #{$xs-layout} {
            padding: 15px 17px;
        }
    }
}

.pro-details-group {
    margin: 0 0 33px;
    .single-pro-details-group {
        display: flex;
        align-items: center;
        margin: 0 0 33px;
        &:last-child {
            margin: 0 0 0;
        }
        .group-title-price {
            margin-left: 15px;
            h3 {
                font-weight: 500;
                margin: 0 0 10px;
                font-size: 16px;
            }
        }
    }
}







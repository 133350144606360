/*-----------------------------------------------------------------------------------

    Template Name: Urdan - Minimal eCommerce HTML Template
    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Template default CSS
    02. Header style
    03. Slider style
    04. Banner style
    05. Section title style
    06. Product style
    07. Service style
    08. Blog style
    09. Footer style
    10. Brand logo style
    11. Category style
    12. Subscribe style
    13. Testimonial style
    14. Product details style
    15. Breadcrumb style
    16. Sidebar style
    17. Shop style
    18. About us style
    19. Contact us style
    20. Cart style
    21. Wishlist style
    22. Login register style
    23. My account style
    24. Compare style
    25. Checkout style

-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  01. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,500&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Poppins:wght@300;400;500;600;700;800&display=swap");
@font-face {
  font-family: 'URWAbbeyW01';
  src: url("../fonts/urwabbeyw01-bold/URWAbbeyW01-Bold.eot");
  src: url("../fonts/urwabbeyw01-bold/URWAbbeyW01-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/urwabbeyw01-bold/URWAbbeyW01-Bold.woff2") format("woff2"), url("../fonts/urwabbeyw01-bold/URWAbbeyW01-Bold.woff") format("woff"), url("../fonts/urwabbeyw01-bold/URWAbbeyW01-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.row.gx-0 {
  margin-right: 0px;
  margin-left: 0px;
}

.row > * {
  padding-right: 15px;
  padding-left: 15px;
}

.row.gx-0 > * {
  padding-right: 0px;
  padding-left: 0px;
}

:root {
  scroll-behavior: unset;
}

/*-- Common Style --*/
*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
  visibility: visible;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  background-color: #ffffff;
}

body[dir="rtl"] {
  text-align: right;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #535353;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
  font-weight: 400;
  margin-top: 0;
  font-family: "Montserrat", sans-serif;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

a,
button {
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #000000;
}

a,
button,
img,
input,
span {
  transition: all .3s ease 0s;
}

*:focus {
  outline: none;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #e97730;
}

button,
input[type="submit"] {
  cursor: pointer;
}

.btn {
  font-size: 14px;
  border-radius: 0;
  line-height: 1;
  padding: 18px 36px;
}

.btn.btn-border-radius {
  border-radius: 50px;
}

input {
  box-shadow: none;
  font-size: 14px;
  width: 100%;
}

.select2-dropdown {
  z-index: 8;
}

select {
  width: 100%;
  background: transparent;
  border: 0px solid #eceff8;
  box-shadow: none;
  font-size: 14px;
}

option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  font-size: 14px;
}

input:focus {
  background: transparent;
}

textarea {
  resize: vertical;
  padding: 10px;
  width: 100%;
  font-size: 14px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-75 {
  margin-bottom: 75px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

@media only screen and (max-width: 767px) {
  .pb-65 {
    padding-bottom: 35px;
  }
}

.pb-70 {
  padding-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .pb-70 {
    padding-bottom: 40px;
  }
}

.pb-75 {
  padding-bottom: 75px;
}

@media only screen and (max-width: 767px) {
  .pb-75 {
    padding-bottom: 45px;
  }
}

.pb-85 {
  padding-bottom: 85px;
}

@media only screen and (max-width: 767px) {
  .pb-85 {
    padding-bottom: 55px;
  }
}

.pb-95 {
  padding-bottom: 95px;
}

@media only screen and (max-width: 767px) {
  .pb-95 {
    padding-bottom: 65px;
  }
}

.pb-90 {
  padding-bottom: 90px;
}

@media only screen and (max-width: 767px) {
  .pb-90 {
    padding-bottom: 60px;
  }
}

.pb-100 {
  padding-bottom: 100px;
}

@media only screen and (max-width: 767px) {
  .pb-100 {
    padding-bottom: 70px;
  }
}

.pt-80 {
  padding-top: 80px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-90 {
  padding-top: 90px;
}

@media only screen and (max-width: 767px) {
  .pt-90 {
    padding-top: 60px;
  }
}

.pt-95 {
  padding-top: 95px;
}

@media only screen and (max-width: 767px) {
  .pt-95 {
    padding-top: 65px;
  }
}

.pt-100 {
  padding-top: 100px;
}

@media only screen and (max-width: 767px) {
  .pt-100 {
    padding-top: 70px;
  }
}

.pt-145 {
  padding-top: 145px;
}

.bg-img {
  background-position: center center;
  background-size: cover;
}

.transparent-bar {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}

.custom-d-flex {
  display: flex !important;
}

.custom-align-item-center {
  align-items: center;
}

.custom-align-item-end {
  align-items: flex-end;
}

#scrollUp {
  width: 50px;
  height: 50px;
  background-color: #262626;
  color: #fff;
  right: 30px;
  bottom: 53px;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  z-index: 9811 !important;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    width: 35px;
    height: 35px;
    right: 20px;
  }
}

#scrollUp i {
  display: block;
  line-height: 50px;
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  #scrollUp i {
    line-height: 35px;
    font-size: 15px;
  }
}

#scrollUp:hover {
  background-color: #e97730;
}

.btn-outline-primary:active:focus {
  box-shadow: none;
}

.btn-outline-primary:focus, .btn:focus {
  box-shadow: none;
}

.bg-gray {
  background-color: #f5f3f3;
}

.bg-gray-2 {
  background-color: #f4f4f4;
}

.bg-gray-3 {
  background-color: #f0f0f0;
}

.bg-gray-4 {
  background-color: #f2f2f2;
}

.bg-powder-blue {
  background-color: #e6f2f2;
}

.bg-light-yellow {
  background-color: #f9f1ea;
}

.bg-dark-yellow {
  background-color: #eee4da;
}

.header-border-top-1 {
  border-top: 1px solid #f5f3f3;
}

.border-bottom-1 {
  border-bottom: 2px solid #e5e5e5;
}

.btn-hover a, .btn-hover button {
  position: relative;
  transition: all .5s ease-in-out 0s;
}

.btn-hover a:hover, .btn-hover button:hover {
  color: #fff;
}

.btn-hover a::before, .btn-hover a::after, .btn-hover button::before, .btn-hover button::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: -1;
}

.btn-hover a::after, .btn-hover button::after {
  width: 0;
  left: auto;
  right: 0;
  background: #000000;
}

.btn-hover a.hover-border-radius::before, .btn-hover a.hover-border-radius::after, .btn-hover button.hover-border-radius::before, .btn-hover button.hover-border-radius::after {
  border-radius: 50px;
}

.btn-hover a.theme-color::after, .btn-hover button.theme-color::after {
  background: #e97730;
}

.btn-hover a:hover::after, .btn-hover button:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}

@keyframes lastudioZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.85);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.section-padding-1 .container-fluid {
  padding: 0 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-1 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-2 .container-fluid {
  padding: 0 120px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-2 .container-fluid {
    padding: 0 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-2 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-2 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

.padding-22-row-col .row {
  margin-right: -11px;
  margin-left: -11px;
}

@media only screen and (max-width: 767px) {
  .padding-22-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-22-row-col .row div[class^="col-"] {
  padding-left: 11px;
  padding-right: 11px;
}

@media only screen and (max-width: 767px) {
  .padding-22-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-20-row-col .row {
  margin-right: -11px;
  margin-left: -11px;
}

@media only screen and (max-width: 767px) {
  .padding-20-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-20-row-col .row div[class^="col-"] {
  padding-left: 11px;
  padding-right: 11px;
}

@media only screen and (max-width: 767px) {
  .padding-20-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-10-row-col .row {
  margin-right: -5px;
  margin-left: -5px;
}

@media only screen and (max-width: 767px) {
  .padding-10-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .padding-10-row-col .row {
    margin-right: -5px;
    margin-left: -5px;
  }
}

.padding-10-row-col .row div[class^="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width: 767px) {
  .padding-10-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .padding-10-row-col .row div[class^="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.slider-bg-color-1 {
  background-color: #F2F2F2;
}

.slider-bg-color-2 {
  background-color: #E0DFDD;
}

.slider-bg-color-3 {
  background-color: #E6F2F2;
}

.slider-bg-color-4 {
  background-color: #474747;
}

/*-------- 02. Header style ---------*/
.logo a {
  display: inline-block;
}

.logo a img {
  max-width: 100%;
}

.welcome-text p {
  color: #383837;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

.language-currency-wrap {
  display: flex;
  justify-content: flex-end;
}

.language-currency-wrap .border-style {
  position: relative;
}

.language-currency-wrap .border-style::before {
  background-color: #383838;
  position: absolute;
  height: 12px;
  width: 1px;
  content: "";
  right: -21px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .language-currency-wrap .border-style::before {
    right: -18px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .language-currency-wrap .border-style::before {
    right: -21px;
  }
}

.language-currency-wrap .currency-wrap {
  margin-right: 44px;
}

@media only screen and (max-width: 767px) {
  .language-currency-wrap .currency-wrap {
    margin-right: 35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .language-currency-wrap .currency-wrap {
    margin-right: 44px;
  }
}

.language-currency-wrap .language-wrap a img {
  margin-right: 5px;
  margin-top: -3px;
}

.language-currency-wrap .currency-wrap,
.language-currency-wrap .language-wrap {
  position: relative;
}

.language-currency-wrap .currency-wrap > a,
.language-currency-wrap .language-wrap > a {
  color: #383838;
  display: inline-block;
  line-height: 51px;
}

.language-currency-wrap .currency-wrap > a i,
.language-currency-wrap .language-wrap > a i {
  font-size: 9px;
}

.language-currency-wrap .currency-wrap .currency-dropdown,
.language-currency-wrap .currency-wrap .language-dropdown,
.language-currency-wrap .language-wrap .currency-dropdown,
.language-currency-wrap .language-wrap .language-dropdown {
  background: #fff none repeat scroll 0 0;
  padding: 25px 15px 25px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 9999;
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  max-height: 636px;
  overflow-y: auto;
  display: none;
  width: 135px;
}

.language-currency-wrap .currency-wrap .currency-dropdown ul li,
.language-currency-wrap .currency-wrap .language-dropdown ul li,
.language-currency-wrap .language-wrap .currency-dropdown ul li,
.language-currency-wrap .language-wrap .language-dropdown ul li {
  display: block;
  padding: 0 0 12px;
}

.language-currency-wrap .currency-wrap .currency-dropdown ul li a,
.language-currency-wrap .currency-wrap .language-dropdown ul li a,
.language-currency-wrap .language-wrap .currency-dropdown ul li a,
.language-currency-wrap .language-wrap .language-dropdown ul li a {
  display: block;
  font-size: 13px;
}

.language-currency-wrap .currency-wrap .currency-dropdown ul li:last-child,
.language-currency-wrap .currency-wrap .language-dropdown ul li:last-child,
.language-currency-wrap .language-wrap .currency-dropdown ul li:last-child,
.language-currency-wrap .language-wrap .language-dropdown ul li:last-child {
  padding: 0 0 0px;
}

.language-currency-wrap .currency-wrap:hover > a,
.language-currency-wrap .language-wrap:hover > a {
  color: #e97730;
}

.language-currency-wrap.language-currency-wrap-modify {
  justify-content: flex-start;
}

.language-currency-wrap.language-currency-wrap-modify .currency-wrap .currency-dropdown,
.language-currency-wrap.language-currency-wrap-modify .currency-wrap .language-dropdown,
.language-currency-wrap.language-currency-wrap-modify .language-wrap .currency-dropdown,
.language-currency-wrap.language-currency-wrap-modify .language-wrap .language-dropdown {
  width: 120px;
  left: 0;
  right: auto;
}

@media only screen and (max-width: 767px) {
  .language-currency-wrap.language-currency-wrap-modify .currency-wrap .currency-dropdown,
  .language-currency-wrap.language-currency-wrap-modify .currency-wrap .language-dropdown,
  .language-currency-wrap.language-currency-wrap-modify .language-wrap .currency-dropdown,
  .language-currency-wrap.language-currency-wrap-modify .language-wrap .language-dropdown {
    width: 110px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .language-currency-wrap.language-currency-wrap-modify .currency-wrap .currency-dropdown,
  .language-currency-wrap.language-currency-wrap-modify .currency-wrap .language-dropdown,
  .language-currency-wrap.language-currency-wrap-modify .language-wrap .currency-dropdown,
  .language-currency-wrap.language-currency-wrap-modify .language-wrap .language-dropdown {
    width: 120px;
  }
}

.main-menu > nav > ul > li {
  display: inline-flex;
  margin-right: 36px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li {
    margin-right: 16px;
  }
}

.main-menu > nav > ul > li:last-child {
  margin-right: 0;
}

.main-menu > nav > ul > li > ul {
  background: #fff none repeat scroll 0 0;
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  display: block;
  left: 0;
  padding: 28px 25px 28px;
  position: absolute;
  top: 100%;
  transform: rotateX(90deg);
  transform-origin: center top 0;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  z-index: 99;
}

.main-menu > nav > ul > li > ul li {
  display: block;
  position: relative;
  line-height: 1;
  margin-bottom: 20px;
}

.main-menu > nav > ul > li > ul li:last-child {
  margin-bottom: 0px;
}

.main-menu > nav > ul > li > ul li a {
  display: block;
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
}

.main-menu > nav > ul > li > ul li:hover > a {
  color: #e97730;
}

.main-menu > nav > ul > li > ul.sub-menu-style {
  width: 190px;
  text-align: left;
}

.main-menu > nav > ul > li > ul.mega-menu-style {
  width: 750px;
  padding: 28px 30px 0px;
  text-align: left;
}

.main-menu > nav > ul > li > ul.mega-menu-style.mega-menu-mrg-1 {
  left: -85px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-style.mega-menu-mrg-1 {
    left: -164px;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-style > li > ul {
  display: flex;
  flex-wrap: wrap;
}

.main-menu > nav > ul > li > ul.mega-menu-style > li > ul > li {
  flex: 0 0 33.333%;
  max-width: 33.333%;
}

.main-menu > nav > ul > li > ul.mega-menu-style > li > ul > li > a.dropdown-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
}

.main-menu > nav > ul > li > ul.mega-menu-style > li > ul > li ul li {
  margin-bottom: 22px;
}

.main-menu > nav > ul > li > ul.mega-menu-style > li > ul > li ul li:last-child {
  margin-bottom: 0;
}

.main-menu > nav > ul > li > a {
  color: #383838;
  font-weight: 500;
  line-height: 120px;
  display: inline-block;
  position: relative;
}

.main-menu > nav > ul > li > a::after {
  content: "";
  height: 2px;
  right: 0px;
  bottom: 0px;
  left: auto;
  width: 0;
  position: absolute;
  transition: all .4s ease 0s;
  background-color: #e97730;
}

.main-menu > nav > ul > li:hover > a {
  color: #e97730;
}

.main-menu > nav > ul > li:hover > a::after {
  left: 0px;
  right: auto;
  width: 100%;
  transition: all .4s ease 0s;
}

.main-menu > nav > ul > li:hover > ul {
  transform: rotateX(0deg);
  visibility: visible;
}

.header-action-wrap {
  display: flex;
  justify-content: flex-end;
}

.header-action-wrap .header-action-style {
  margin-right: 17px;
}

@media only screen and (max-width: 767px) {
  .header-action-wrap .header-action-style {
    margin-right: 7px;
  }
}

.header-action-wrap .header-action-style:last-child {
  margin-right: 0px;
}

.header-action-wrap .header-action-style a {
  font-size: 23px;
  line-height: 1;
  display: flex;
}

.header-action-wrap .header-action-cart {
  margin-right: 10px !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-action-wrap .header-action-cart {
    margin-right: 17px !important;
  }
}

.header-action-wrap .header-action-cart a {
  position: relative;
}

.header-action-wrap .header-action-cart a span.product-count {
  position: absolute;
  top: -4px;
  right: -10px;
  width: 21px;
  height: 21px;
  line-height: 21px;
  border-radius: 100%;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  text-align: center;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-action-wrap .header-action-cart a span.product-count {
    top: -4px;
    right: -6px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-size: 11px;
  }
}

@media only screen and (max-width: 767px) {
  .header-action-wrap .header-action-cart a span.product-count {
    top: -4px;
    right: -6px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-size: 11px;
  }
}

.header-action-wrap .header-action-cart a span.product-count.bg-black {
  background-color: #000000;
}

.header-height-1 {
  min-height: 171px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-height-1 {
    min-height: 83px;
  }
}

@media only screen and (max-width: 767px) {
  .header-height-1 {
    min-height: 73px;
  }
}

.header-height-2 {
  min-height: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-height-2 {
    min-height: 85px;
  }
}

@media only screen and (max-width: 767px) {
  .header-height-2 {
    min-height: 75px;
  }
}

.sticky-bar.stick {
  animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  left: 0;
  background-color: #ffffff;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sticky-bar.stick.header-bottom {
    padding: 25px 0;
  }
}

@media only screen and (max-width: 767px) {
  .sticky-bar.stick.header-bottom {
    padding: 20px 0;
  }
}

.stick .main-menu > nav > ul > li > a {
  line-height: 80px;
}

.header-search-1 {
  position: relative;
}

.header-search-1 .search-toggle {
  z-index: 99;
}

.header-search-1 .search-toggle i {
  display: block;
}

.header-search-1 .search-toggle i.s-close {
  font-size: 35px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.header-search-1 .search-toggle .s-close {
  opacity: 0;
}

.header-search-1 .search-toggle.open .s-close {
  opacity: 1;
}

.header-search-1 .search-toggle.open .s-open {
  opacity: 0;
}

.search-wrap-1 {
  background-color: transparent;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  visibility: hidden;
  width: 340px;
  z-index: -99;
  padding-bottom: 1px;
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .search-wrap-1 {
    top: 200%;
    transform: inherit;
    width: 300px;
    left: -154px;
    margin-right: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .search-wrap-1 {
    top: 50%;
    transform: translateY(-50%);
    width: 300px;
    left: inherit;
    margin-right: 10px;
  }
}

.search-wrap-1 form {
  position: relative;
  overflow: hidden;
}

.search-wrap-1 form input {
  background-color: #fff;
  border: 1px solid #E2DCDC;
  color: #000000;
  line-height: 30px;
  padding: 5px 60px 5px 20px;
  width: 100%;
  border-radius: 50px;
}

.search-wrap-1 form input::-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}

.search-wrap-1 form input::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

.search-wrap-1 form button.button-search {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  border: none;
  background-color: transparent;
  height: 100%;
  font-size: 18px;
  border-left: 1px solid #E2DCDC;
  padding: 0 15px;
  color: #000000;
  line-height: 45px;
}

.search-wrap-1 form button.button-search:hover {
  color: #e97730;
}

.search-wrap-1.open {
  opacity: 1;
  right: 100%;
  visibility: visible;
  z-index: 99;
}

@media only screen and (max-width: 767px) {
  .search-wrap-1.open {
    right: inherit;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .search-wrap-1.open {
    right: 100%;
  }
}

.main-wrapper .body-overlay {
  background-color: #232324;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.main-wrapper.overlay-active .body-overlay {
  opacity: .7;
  visibility: visible;
  z-index: 9999;
}

.sidebar-cart-active {
  position: fixed;
  top: 0;
  width: 420px;
  padding: 104px 50px 50px;
  min-height: 100vh;
  bottom: 0;
  z-index: 9999;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-in;
  transform: translate(200px, 0);
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
}

.sidebar-cart-active.inside {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active {
    width: 350px;
    padding: 70px 30px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active {
    padding: 60px 15px 50px;
    width: 300px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sidebar-cart-active {
    padding: 60px 30px 50px;
    width: 340px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close {
  position: absolute;
  top: 37px;
  right: 50px;
  font-size: 50px;
  line-height: 30px;
  color: #6D6D6D;
  transition: all 250ms ease-out;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    font-size: 40px;
    right: 19px;
    top: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    top: 27px;
    right: 17px;
    font-size: 30px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close:hover {
  transform: rotate(90deg);
  color: #e97730;
}

.sidebar-cart-active .sidebar-cart-all .cart-content > h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 35px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul {
  margin: 0 -30px 0 0;
  padding: 0 30px 0 0;
  max-height: 310px;
  max-height: calc(100vh - 430px);
  overflow: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 365px);
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 360px);
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li {
  display: flex;
  margin-bottom: 30px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img {
  flex: 0 0 70px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img a img {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title {
  margin-left: 20px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 {
  font-size: 15px;
  margin: 0 0 8px;
  font-weight: 500;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 a {
  color: #000000;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 a:hover {
  color: #e97730;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title span {
  font-size: 15px;
  color: #000000;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete {
  display: flex;
  flex-grow: 100;
  justify-content: flex-end;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete a {
  display: block;
  font-size: 20px;
  height: 20px;
  line-height: 17px;
  overflow: hidden;
  width: 20px;
  text-align: right;
  color: #000000;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete a:hover {
  color: #e97730;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total {
  display: block;
  width: 100%;
  margin: 16px 0 26px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 {
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  color: #000000;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 {
    font-size: 15px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 span {
  font-size: 15px;
  color: #000000;
  float: right;
  font-weight: 500;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-btn,
.sidebar-cart-active .sidebar-cart-all .cart-content .checkout-btn {
  display: block;
  width: 100%;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-btn a,
.sidebar-cart-active .sidebar-cart-all .cart-content .checkout-btn a {
  width: 100%;
  display: block;
  text-align: center;
  padding: 18px 20px 17px;
  background-color: #000000;
  color: #fff;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  z-index: 1;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-btn a:first-child,
.sidebar-cart-active .sidebar-cart-all .cart-content .checkout-btn a:first-child {
  margin-top: 0;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-btn {
  margin-bottom: 10px;
}

.header-margin {
  margin: 30px 30px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-margin {
    margin: 38px 38px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header-margin {
    margin: 0px 0px 0;
  }
}

.main-wrapper-2 .body-overlay-2 {
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.main-wrapper-2.overlay-active-2 .body-overlay-2 {
  opacity: 1;
  visibility: visible;
}

.off-canvas-active {
  position: fixed;
  top: 0;
  width: 370px;
  min-height: 100vh;
  bottom: 0;
  z-index: 111;
  right: 0;
  visibility: hidden;
  opacity: 1;
  transition: all 400ms ease-out;
  transform: translate(370px, 0);
  background-color: #fff;
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  z-index: 9999;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .off-canvas-active {
    width: 320px;
  }
}

@media only screen and (max-width: 767px) {
  .off-canvas-active {
    width: 280px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .off-canvas-active {
    width: 300px;
  }
}

.off-canvas-active.inside {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

.off-canvas-active .off-canvas-close {
  position: absolute;
  left: -40px;
  top: 0px;
  z-index: 9;
  width: 40px;
  height: 40px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  background-color: #e97730;
}

@media only screen and (max-width: 767px) {
  .off-canvas-active .off-canvas-close {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    left: -30px;
  }
}

.off-canvas-active .off-canvas-close i {
  transition: all .3s ease-in-out;
  display: inline-block;
  color: #fff;
  line-height: 40px;
}

@media only screen and (max-width: 767px) {
  .off-canvas-active .off-canvas-close i {
    line-height: 30px;
  }
}

.off-canvas-active .off-canvas-close:hover i {
  transform: rotate(90deg);
}

.off-canvas-active .off-canvas-wrap {
  padding: 40px 30px 50px;
  overflow: auto;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .off-canvas-active .off-canvas-wrap {
    padding: 30px 20px 50px;
  }
}

.off-canvas-active .off-canvas-wrap .off-canvas-margin-padding {
  border-bottom: 1px solid #ddd;
  margin-bottom: 27px;
  padding-bottom: 33px;
}

.off-canvas-active .off-canvas-wrap .off-canvas-margin-padding-2 {
  border-bottom: 1px solid #ddd;
  margin-bottom: 22px;
  padding-bottom: 29px;
}

.slinky-mobile-menu {
  text-align: left;
}

.slinky-mobile-menu ul li a {
  color: #000000;
}

.slinky-mobile-menu ul li a span {
  color: #383838;
  font-size: 14px;
  font-weight: 500;
}

.slinky-mobile-menu ul li a:not(.back) {
  padding: 8px 0px;
}

.slinky-mobile-menu ul li a:hover span {
  color: #e97730;
}

.slinky-mobile-menu.slinky-theme-default .next::after {
  font-size: 11px;
  color: #000000;
}

.slinky-mobile-menu.slinky-theme-default .next:hover::after {
  color: #e97730;
}

.slinky-mobile-menu.slinky-theme-default .back::before {
  font-size: 11px;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.slinky-theme-default {
  background: transparent;
}

.slinky-theme-default ul li a {
  position: relative;
}

.slinky-theme-default ul li a:not(.back) {
  color: #fff;
}

.slinky-theme-default ul li a:not(.back):hover {
  color: #e97730;
  background: transparent;
}

.slinky-theme-default .next::after {
  content: "\e649";
  font-family: themify;
  font-weight: 900;
  opacity: 1;
  transition: all .3 ease 0s;
  color: #ffffff;
  background: none;
}

.slinky-theme-default .next:hover::after {
  color: #e97730;
}

.slinky-theme-default .back::before {
  content: "\e649";
  font-family: themify;
  font-weight: 900;
  opacity: 1;
  transition: all .3 ease 0s;
  color: #fff;
  background: none;
  padding: 0;
  background-color: #000000;
  text-align: center;
  margin: 0 0 13px 0px;
  border-radius: 3px;
}

.slinky-theme-default .back:hover::before {
  font-family: themify;
  background-color: #e97730;
  opacity: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-responsive-padding {
    padding: 25px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header-responsive-padding {
    padding: 20px 0;
  }
}

/*-------- 03. Slider style ---------*/
.slider-height-1 {
  height: 770px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-1 {
    height: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-1 {
    height: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-1 {
    height: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-1 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-1 {
    height: auto;
    padding: 60px 0;
  }
}

.slider-height-2 {
  height: 493px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-2 {
    height: 390px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-2 {
    height: 364px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-2 {
    height: 360px;
  }
}

.slider-height-3 {
  height: 700px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-3 {
    height: 650px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-3 {
    height: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-3 {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-3 {
    height: 480px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-3 {
    height: auto;
    padding: 60px 0;
  }
}

.slider-height-4 {
  height: 770px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-4 {
    height: 670px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-4 {
    height: 670px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-4 {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-4 {
    height: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-4 {
    height: 470px;
  }
}

.slider-height-5 {
  height: 647px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-5 {
    height: 513px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-5 {
    height: 379px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-5 {
    height: 350px;
  }
}

.slider-content-center {
  display: flex;
  align-items: center;
}

.slider-content-1 {
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  .slider-content-1 {
    text-align: center;
  }
}

.slider-content-1 h3 {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  position: relative;
  margin: 0;
  padding: 0 0 0 63px;
  display: inline-block;
}

.slider-content-1 h3:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 3px;
  background-color: #e97730;
}

.slider-content-1 h1 {
  font-size: 78px;
  line-height: 90px;
  font-family: "Poppins", sans-serif;
  margin: 19px 0 44px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-1 h1 {
    font-size: 65px;
    line-height: 70px;
    margin: 19px 0 35px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-1 h1 {
    font-size: 60px;
    line-height: 70px;
    margin: 19px 0 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-1 h1 {
    font-size: 55px;
    line-height: 60px;
    margin: 15px 0 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-1 h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 15px 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-1 h1 {
    font-size: 35px;
    line-height: 40px;
    margin: 10px 0 20px;
  }
}

.slider-btn a {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: .2px;
  background-color: #e97730;
  transition: all .3s ease ease 0s;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-btn a {
    font-size: 15px;
  }
  .slider-btn a.btn {
    padding: 15px 28px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-btn a {
    font-size: 14px;
  }
  .slider-btn a.btn {
    padding: 12px 24px 13px;
  }
}

.slider-btn a i {
  margin-left: 5px;
  top: 2px;
  position: relative;
}

.slider-btn a.btn-border-radius {
  border-radius: 50px;
}

.slider-btn a.btn-bg-white {
  background-color: #ffffff;
}

.slider-btn a.btn-padding-inc {
  padding: 19px 40px;
}

@media only screen and (max-width: 767px) {
  .slider-btn a.btn-padding-inc {
    padding: 14px 25px;
  }
}

.slider-btn a.btn {
  color: #ffffff;
  border: none;
}

.slider-btn a.btn.btn-text-black {
  color: #000000;
}

.slider-btn a.btn.btn-text-black:hover {
  color: #ffffff;
}

.hero-slider-img-1 {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .hero-slider-img-1 {
    margin: 40px 0 0;
  }
}

.hero-slider-img-1 img {
  width: 100%;
}

.hero-slider-img-1 .product-offer {
  position: absolute;
  width: 86px;
  height: 86px;
  line-height: 86px;
  border-radius: 100%;
  border: 2px solid #ffffff;
  text-align: center;
  background-color: #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  top: -2px;
  right: -8px;
  z-index: 5;
}

.hero-slider-img-1 .product-offer h5 {
  font-size: 22px;
  font-weight: 600;
  color: #e97730;
  text-transform: uppercase;
  margin: 0;
}

.hero-slider-img-1 .product-offer h5 span {
  display: block;
  font-size: 16px;
}

.swiper-slide-active .single-animation-wrap .slider-animated-1 h3 {
  animation-delay: 1.0s;
  animation-name: fadeInLeft;
}

.swiper-slide-active .single-animation-wrap .slider-animated-1 h1 {
  animation-delay: 1.5s;
  animation-name: fadeInLeft;
}

.swiper-slide-active .single-animation-wrap .slider-animated-1 a {
  animation-delay: 1.9s;
  animation-name: fadeInLeft;
}

.swiper-slide-active .single-animation-wrap .slider-animated-1 .animated-slider-img-1 {
  animation-delay: 1.5s;
  animation-name: fadeInUp;
}

.swiper-slide-active .single-animation-wrap .slider-animated-1 .product-offer {
  animation-delay: 2.2s;
  animation-name: fadeInDown;
}

.swiper-slide-active .single-animation-wrap .slider-animated-2 {
  animation-delay: .5s;
  animation-name: zoomIn;
}

.swiper-slide-active .single-animation-wrap .slider-animated-2 h3 {
  animation-delay: 1.0s;
  animation-name: zoomIn;
}

.swiper-slide-active .single-animation-wrap .slider-animated-2 h1 {
  animation-delay: 1.5s;
  animation-name: zoomIn;
}

.swiper-slide-active .single-animation-wrap .slider-animated-2 a {
  animation-delay: 1.9s;
  animation-name: zoomIn;
}

.swiper-slide-active .single-animation-wrap .slider-animated-2 h2 {
  animation-delay: 1.5s;
  animation-name: fadeInUp;
}

.swiper-slide-active .single-animation-wrap .slider-animated-2 img {
  animation-delay: 1.5s;
  animation-name: fadeInDown;
}

.swiper-slide-active .single-animation-wrap .slider-animated-3 h3 {
  animation-delay: 1.0s;
  animation-name: fadeInLeft;
}

.swiper-slide-active .single-animation-wrap .slider-animated-3 h1 {
  animation-delay: 1.5s;
  animation-name: fadeInLeft;
}

.swiper-slide-active .single-animation-wrap .slider-animated-3 a {
  animation-delay: 1.9s;
  animation-name: fadeInLeft;
}

.swiper-slide-active .single-animation-wrap .hm2-slider-animation * {
  animation-duration: 1.3s;
  animation-fill-mode: both;
}

.main-slider-nav {
  position: absolute;
  z-index: 9;
  left: 19.3%;
  bottom: 16%;
  font-size: 40px;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-slider-nav {
    left: 7.3%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-nav {
    left: 1.3%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-nav {
    left: 3.1%;
    bottom: 12%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-nav {
    left: 5.1%;
    bottom: 10%;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-nav {
    left: 43%;
    bottom: 1%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-slider-nav {
    left: 46%;
    bottom: 1%;
  }
}

.main-slider-nav.home-slider-next {
  margin-left: 40px;
}

.main-slider-nav.home-slider-prev::before {
  position: absolute;
  content: "";
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 20px;
  background-color: #e97730;
}

.main-slider-nav:hover {
  color: #e97730;
}

.slider-content-2-wrap {
  width: 500px;
  padding: 62px 0 67px 60px;
  position: relative;
  overflow: hidden;
  background-color: #f0d8c8;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .slider-content-2-wrap {
    padding: 62px 0 67px 15px;
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-2-wrap {
    padding: 62px 0 67px 30px;
  }
}

.slider-content-2 h3 {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  position: relative;
  margin: 0;
  padding: 0 0 0 63px;
  display: inline-block;
}

.slider-content-2 h3:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 3px;
  background-color: #e97730;
}

.slider-content-2 h1 {
  font-weight: 600;
  font-size: 60px;
  color: #242424;
  line-height: 74px;
  margin: 9px 0 24px;
  font-family: "Poppins", sans-serif;
  z-index: 5;
  position: relative;
}

.slider-content-2 h1.font-inc {
  font-size: 72px;
  line-height: 79px;
  margin: 20px 0 17px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-2 h1.font-inc {
    font-size: 55px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-2 h1.font-inc {
    font-size: 55px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-2 h1.font-inc {
    font-size: 42px;
    line-height: 47px;
    margin: 12px 0 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-2 h1.font-inc {
    font-size: 40px;
    line-height: 44px;
    margin: 12px 0 17px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-2 h1.font-inc {
    font-size: 40px;
    line-height: 45px;
    margin: 9px 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-2 h1 {
    font-size: 50px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-2 h1 {
    font-size: 45px;
    line-height: 52px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-2 h1 {
    font-size: 40px;
    line-height: 45px;
    margin: 9px 0 20px;
  }
}

.slider-content-2 img {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
}

.slider-content-2 h2 {
  position: absolute;
  left: 0;
  bottom: 34px;
  font-size: 72px;
  font-weight: 900;
  margin: 0px;
  color: #f0d8c8;
  line-height: 75px;
  letter-spacing: 18px;
  -webkit-text-stroke: 1px #efc8b2;
}

@media only screen and (max-width: 767px) {
  .slider-content-2 h2 {
    bottom: 12px;
    letter-spacing: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-2 h2 {
    bottom: 12px;
    letter-spacing: 10px;
  }
}

.slider-btn-2 a {
  display: inline-block;
  color: #242424;
}

.slider-btn-2 a.btn {
  font-weight: 500;
  border: 2px solid #000000;
  border-radius: 50px;
  padding: 14px 40px;
  z-index: 1;
  font-size: 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-btn-2 a.btn {
    font-size: 15px;
    padding: 12px 30px 13px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-btn-2 a.btn {
    font-size: 15px;
    padding: 12px 30px 13px;
  }
}

.slider-btn-2 a.btn:hover {
  border: 2px solid #e97730;
}

.slider-btn-3 a {
  display: inline-block;
  color: #242424;
  transition: all .5s ease 0s;
}

.slider-btn-3 a.btn {
  font-weight: 500;
  border: 2px solid #000000;
  padding: 15px 40px;
  z-index: 1;
  font-size: 16px;
  letter-spacing: .2px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-btn-3 a.btn {
    font-size: 15px;
    padding: 12px 30px 13px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-btn-3 a.btn {
    font-size: 15px;
    padding: 12px 30px 13px;
  }
}

.slider-btn-3 a.btn:hover {
  border: 2px solid #e97730;
}

.slider-content-2-mrg {
  margin: 0 0 0 30px;
}

.main-slider-nav2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50px;
  font-size: 50px;
  cursor: pointer;
  line-height: 1;
  transition: all .4s ease 0s;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-nav2 {
    left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-nav2 {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-nav2 {
    left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-nav2 {
    left: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-slider-nav2 {
    left: 30px;
  }
}

.main-slider-nav2.home-slider-next2 {
  left: auto;
  right: 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-slider-nav2.home-slider-next2 {
    right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-nav2.home-slider-next2 {
    right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-nav2.home-slider-next2 {
    right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-nav2.home-slider-next2 {
    right: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-slider-nav2.home-slider-next2 {
    right: 30px;
  }
}

.main-slider-nav2:hover {
  color: #e97730;
  opacity: 0;
  visibility: hidden;
}

.slider-active:hover .main-slider-nav2 {
  opacity: 1;
  visibility: visible;
}

.slider-margin {
  margin: 0 30px 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-margin {
    margin: 0 38px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-margin {
    margin: 0 0px 0px;
  }
}

.hero-slider-img-2 {
  margin: 0 110px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-img-2 {
    margin: 0 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-img-2 {
    margin: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-img-2 {
    margin: 30px 70px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-img-2 {
    margin: 40px 90px 0;
  }
}

.hero-slider-img-2 img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .slider-content-3 {
    text-align: center;
  }
}

.slider-content-3 h3 {
  font-size: 16px;
  font-weight: 600;
  color: #e97730;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 26px;
  margin: 0;
  padding-left: 15px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .slider-content-3 h3 {
    display: inline-block;
    text-align: left;
  }
}

.slider-content-3 h3:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 38px;
  width: 2px;
  background-color: #e97730;
}

.slider-content-3 h1 {
  font-size: 64px;
  color: #242424;
  line-height: 73px;
  font-weight: 800;
  margin: 18px 0 48px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-3 h1 {
    font-size: 55px;
    line-height: 65px;
    margin: 18px 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-3 h1 {
    font-size: 55px;
    line-height: 65px;
    margin: 18px 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-3 h1 {
    font-size: 42px;
    line-height: 48px;
    margin: 18px 0 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-3 h1 {
    font-size: 36px;
    line-height: 43px;
    margin: 18px 0 28px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-3 h1 {
    font-size: 30px;
    line-height: 38px;
    margin: 18px 0 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-3 h1 {
    font-size: 36px;
    line-height: 43px;
  }
}

.slider-content-4 h5 {
  position: relative;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 2px;
  margin: 0;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1;
}

.slider-content-4 h5:before, .slider-content-4 h5:after {
  position: absolute;
  content: "";
  width: 50px;
  height: 3px;
  background-color: #ffffff;
  top: 50%;
  transform: translateY(-50%);
}

.slider-content-4 h5:before {
  left: -65px;
}

.slider-content-4 h5:after {
  right: -65px;
}

.slider-content-4 h1 {
  font-size: 78px;
  line-height: 90px;
  margin: 6px 0 43px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-4 h1 {
    font-size: 70px;
    line-height: 80px;
    margin: 6px 0 35px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-4 h1 {
    font-size: 70px;
    line-height: 80px;
    margin: 6px 0 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-4 h1 {
    font-size: 60px;
    line-height: 65px;
    margin: 6px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-4 h1 {
    font-size: 55px;
    line-height: 60px;
    margin: 6px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-4 h1 {
    font-size: 35px;
    line-height: 40px;
    margin: 6px 0 20px;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-4.pt-145 {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-4.pt-145 {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-4.pt-145 {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-4.pt-145 {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-4.pt-145 {
    padding-top: 50px;
  }
}

.main-slider-nav3 {
  position: absolute;
  bottom: 2px;
  right: 2px;
  z-index: 9;
}

.main-slider-nav3 i {
  font-size: 60px;
  color: #000000;
  width: 60px;
  height: 76px;
  line-height: 76px;
  text-align: center;
  background-color: #ffffff;
  cursor: pointer;
  transition: all .4s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-nav3 i {
    font-size: 50px;
    width: 50px;
    height: 60px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-nav3 i {
    font-size: 50px;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

.main-slider-nav3.home-slider-prev3 {
  margin-right: 62px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-nav3.home-slider-prev3 {
    margin-right: 52px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider-nav3.home-slider-prev3 {
    margin-right: 52px;
  }
}

.main-slider-nav3:hover i {
  color: #ffffff;
  background-color: #e97730;
}

@media only screen and (max-width: 767px) {
  .slider-overly {
    position: relative;
  }
  .slider-overly:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
  }
}

/*-------- 04. Banner style ---------*/
.banner-wrap {
  position: relative;
  overflow: hidden;
}

.banner-wrap a {
  display: block;
}

.banner-wrap a img {
  width: 100%;
  transition: all .4s ease 0s;
}

.banner-wrap .banner-position-1 {
  position: absolute;
  left: 60px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-1 {
    left: 10px;
    right: 10px;
    text-align: center;
  }
}

.banner-wrap .banner-position-2 {
  position: absolute;
  left: 10px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.banner-wrap .banner-content-1 {
  position: absolute;
  left: 24px;
  bottom: 24px;
}

.banner-wrap .banner-content-1 h5 {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: .1px;
}

.banner-wrap .banner-content-1 h3 {
  font-size: 24px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin: 7px 0 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-1 h3 {
    margin: 7px 0 10px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-1 h3 {
    font-size: 20px;
  }
}

.banner-wrap .banner-content-1 .banner-btn a {
  display: inline-block;
  font-weight: 500;
  line-height: 1;
  border-bottom: 1px solid #000000;
}

.banner-wrap .banner-content-1 .banner-btn a:hover {
  border-bottom: 1px solid #e97730;
}

.banner-wrap .banner-content-2 {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-2 {
    left: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 {
    left: 20px;
  }
}

.banner-wrap .banner-content-2 span {
  font-size: 18px;
  font-weight: 500;
  color: #e97730;
  margin: 0;
  letter-spacing: .06px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-2 span {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 span {
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-2 span {
    font-size: 18px;
  }
}

.banner-wrap .banner-content-2 h2 {
  font-size: 36px;
  font-weight: 600;
  color: #414141;
  margin: 5px 0 4px;
  letter-spacing: .06px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-2 h2 {
    font-size: 24px;
    margin: 2px 0 2px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 h2 {
    font-size: 20px;
    margin: 2px 0 2px;
    line-height: 1;
  }
}

.banner-wrap .banner-content-2 p {
  width: 65%;
  color: #414141;
  margin: 0px 0 19px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-2 p {
    margin: 0px 0 6px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 p {
    width: 95%;
    margin: 0px 0 6px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-2 p {
    width: 75%;
    margin: 5px 0 12px;
  }
}

.banner-wrap .banner-content-3 {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 5;
  top: 23px;
  text-align: center;
}

.banner-wrap .banner-content-3 h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b9b5b5;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 h3 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-3 h3 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 h3 {
    font-size: 16px;
  }
}

.banner-wrap .banner-content-3 h3 img {
  margin: 0 3px;
  max-width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 h3 img {
    max-width: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-3 h3 img {
    max-width: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 h3 img {
    max-width: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-3 h3 img {
    max-width: 100%;
  }
}

.banner-wrap .banner-content-6 {
  position: absolute;
  left: 35px;
  top: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-6 {
    top: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-6 {
    top: 18px;
    left: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-6 {
    top: 50%;
    transform: translateY(-50%);
  }
}

.banner-wrap .banner-content-6 h2 {
  font-size: 36px;
  color: #141414;
  font-weight: 600;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-6 h2 {
    font-size: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-6 h2 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-6 h2 {
    font-size: 20px;
  }
}

.banner-wrap .banner-content-6 h3 {
  font-size: 24px;
  color: #e97730;
  font-weight: 500;
  margin: 10px 0 0px;
  display: inline-block;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-6 h3 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-6 h3 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-6 h3 {
    font-size: 16px;
  }
}

.banner-wrap .banner-content-6 h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -22px;
  width: 100%;
  height: 2px;
  background-color: #e97730;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-6 h3:before {
    bottom: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-6 h3:before {
    bottom: -12px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-6 h3:before {
    bottom: -12px;
  }
}

.banner-wrap .banner-content-7 {
  position: absolute;
  top: 40px;
  right: 60px;
  text-align: right;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-7 {
    right: 40px;
  }
}

.banner-wrap .banner-content-7 h2 {
  font-size: 70px;
  margin: 0;
  line-height: 1;
  font-family: "Great Vibes", cursive;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-7 h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-7 h2 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-7 h2 {
    font-size: 40px;
  }
}

.banner-wrap .banner-content-8 span {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  display: inline-block;
  line-height: 1;
  font-family: "Poppins", sans-serif;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-8 span {
    font-size: 15px;
    letter-spacing: 0.6px;
  }
}

.banner-wrap .banner-content-8 span.border-left {
  padding: 0 0 0 67px;
}

.banner-wrap .banner-content-8 span.border-left:before {
  position: absolute;
  content: "";
  left: 0px;
  width: 50px;
  height: 3px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #e97730;
}

.banner-wrap .banner-content-8 span.border-left-right:before {
  position: absolute;
  content: "";
  left: -67px;
  width: 50px;
  height: 3px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #e97730;
}

.banner-wrap .banner-content-8 span.border-left-right:after {
  position: absolute;
  content: "";
  right: -67px;
  width: 50px;
  height: 3px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #e97730;
}

.banner-wrap .banner-content-8 h2 {
  font-size: 48px;
  line-height: 55px;
  color: #ffffff;
  margin: 12px 0 29px;
  font-family: "URWAbbeyW01";
  letter-spacing: .5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-8 h2 {
    font-size: 35px;
    line-height: 38px;
    margin: 8px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-8 h2 {
    font-size: 36px;
    line-height: 40px;
    margin: 12px 0 22px;
  }
}

.banner-wrap .banner-content-8 h2.h2-width {
  width: 70%;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-8 h2.h2-width {
    width: 100%;
    font-size: 29px;
    line-height: 26px;
    margin: 4px 0 12px;
  }
}

.banner-wrap .banner-content-8 h2.font-dec {
  font-size: 34px;
  line-height: 37px;
  margin: 10px 0 20px;
}

.banner-wrap .banner-content-9 {
  position: absolute;
  left: 30px;
  top: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-9 {
    left: 20px;
    top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-9 {
    left: 20px;
    top: 20px;
  }
}

.banner-wrap .banner-content-9 h3 {
  color: #242424;
  font-weight: 600;
  font-size: 22px;
  margin: 0 0 6px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-9 h3 {
    font-size: 20px;
  }
}

.banner-wrap .banner-content-9 h4 {
  color: #242424;
  font-size: 15px;
  margin: 0;
}

.banner-wrap .banner-price-1 {
  position: absolute;
  left: 30px;
  bottom: 14px;
}

.banner-wrap .banner-price-1 span {
  color: #e97730;
  font-weight: 600;
  font-size: 20px;
}

.banner-wrap .banner-content-10-position {
  position: absolute;
  top: 39px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-10-position {
    top: 20px;
  }
}

.banner-wrap .banner-content-10-position.top-inc {
  top: 60px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-10-position.top-inc {
    top: 27px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-10-position.top-inc {
    top: 40px;
  }
}

.banner-wrap .banner-content-10-position.top-inc-2 {
  top: 55px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-10-position.top-inc-2 {
    top: 29px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-10-position.top-inc-2 {
    top: 27px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-10-position.top-inc-2 {
    top: 40px;
  }
}

.banner-wrap .banner-content-10-position .banner-content-10 {
  text-align: right;
}

.banner-wrap .banner-content-10-position .banner-content-10 h3 {
  font-size: 70px;
  font-family: "Great Vibes", cursive;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-10-position .banner-content-10 h3 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-10-position .banner-content-10 h3 {
    font-size: 38px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-10-position .banner-content-10 h3 {
    font-size: 50px;
  }
}

.banner-wrap .banner-content-10-position .banner-content-10 h4 {
  font-size: 16px;
  font-weight: 500;
  color: #e97730;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  display: block;
  margin: -15px 0 0;
  letter-spacing: 1.6px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-10-position .banner-content-10 h4 {
    margin: 5px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-10-position .banner-content-10 h4 {
    margin: 5px 0 0;
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-10-position .banner-content-10 h4 {
    margin: 5px 0 0;
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-10-position .banner-content-10.banner-content-10-responsive h3 {
    font-size: 60px;
  }
}

.banner-wrap .banner-content-11 {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.banner-wrap .banner-content-11 span {
  font-size: 20px;
  font-weight: 500;
  color: #e97730;
  letter-spacing: .25px;
}

.banner-wrap .banner-content-11 h3 {
  font-size: 22px;
  font-weight: 600;
  color: #242424;
  margin: 13px 0 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-11 h3 {
    margin: 8px 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-11 h3 {
    margin: 3px 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-11 h3 {
    margin: 3px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11 h3 {
    margin: 3px 0 0px;
  }
}

.banner-wrap .banner-content-11 h4 {
  font-size: 15px;
  color: #242424;
  margin: 6px 0 95px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-11 h4 {
    margin: 6px 0 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-11 h4 {
    margin: 6px 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-11 h4 {
    margin: 6px 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-11 h4 {
    margin: 6px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11 h4 {
    margin: 6px 0 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-11 h4 {
    margin: 6px 0 30px;
  }
}

.banner-wrap .banner-content-12 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}

.banner-wrap .banner-content-12.banner-content-12-width {
  width: 215px;
}

.banner-wrap .banner-content-12 h2 {
  font-size: 31px;
  margin: 0 0 6px;
  line-height: 1;
  font-family: "Great Vibes", cursive;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-12 h2 {
    font-size: 29px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-12 h2 {
    font-size: 20px;
    margin: 0 0 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-12 h2 {
    font-size: 26px;
    margin: 0 0 3px;
  }
}

@media only screen and (min-width: 479px) and (max-width: 575px) {
  .banner-wrap .banner-content-12 h2 {
    font-size: 23px;
    margin: 0 0 2px;
  }
}

.banner-wrap .banner-btn-1 {
  position: absolute;
  right: 55px;
  bottom: 30px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-btn-1 {
    right: 15px;
    bottom: 20px;
  }
}

.banner-wrap .banner-btn-1 a {
  display: inline-block;
  color: #e97730;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
  border-bottom: 1px solid #e97730;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-btn-1 a {
    font-size: 14px;
  }
}

.banner-wrap .banner-btn-1 a:hover {
  color: #000000;
  border-bottom: 1px solid #000000;
}

.banner-wrap.banner-wrap-margin {
  margin-left: -15px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap.banner-wrap-margin {
    margin-left: 0px;
    margin-top: 30px;
  }
}

.banner-wrap:hover a img {
  transform: scale(1.05);
}

.btn-style-2 a {
  display: inline-block;
  font-family: "Poppins", sans-serif;
  z-index: 1;
}

.btn-style-2 a.btn {
  color: #fdfdfd;
  background-color: #e97730;
  padding: 10px 23px 10px;
  border: none;
}

@media only screen and (max-width: 767px) {
  .btn-style-2 a.btn {
    padding: 7px 15px 9px;
  }
}

.btn-style-3 a {
  display: inline-block;
}

.btn-style-3 a.btn {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: .02px;
  border: none;
  background-color: #e97730;
  border-radius: 50px;
  z-index: 1;
  padding: 14px 42px 15px;
}

.btn-style-3 a.btn.border-radius-none {
  border-radius: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-3 a.btn {
    font-size: 15px;
    padding: 10px 25px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-3 a.btn {
    font-size: 15px;
    padding: 10px 25px 12px;
  }
}

.btn-style-4 {
  position: absolute;
  right: 60px;
  bottom: 30px;
}

.btn-style-4.btn-style-4-modify {
  right: 30px;
}

.btn-style-4.btn-style-4-modify a {
  font-size: 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-4 {
    right: 40px;
  }
}

.btn-style-4 a {
  display: inline-block;
  font-size: 16px;
  color: #e97730;
  letter-spacing: 1px;
  line-height: 1;
  padding: 0;
  text-transform: uppercase;
  border-bottom: 1px solid #e97730;
}

.btn-style-4 a:hover {
  border-bottom: 1px solid #000000;
}

.btn-style-5 a {
  display: inline-block;
}

.btn-style-5 a.btn {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: .2px;
  color: #000000;
  background-color: #ffffff;
  z-index: 1;
  border: none;
  padding: 12px 33px 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-5 a.btn {
    font-size: 14px;
    padding: 10px 22px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-5 a.btn {
    font-size: 14px;
    padding: 9px 20px 11px;
  }
}

.btn-style-5 a.btn:hover {
  color: #ffffff;
}

.banner-content-4 {
  padding: 0 0 0 110px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-content-4 {
    padding: 0 0 0 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-content-4 {
    padding: 0 0 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-content-4 {
    padding: 0 0 0 30px;
  }
}

.banner-content-4 h2 {
  font-size: 36px;
  font-weight: 600;
  line-height: 47px;
  color: #141414;
  margin: 0 0 43px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-content-4 h2 {
    font-size: 32px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-content-4 h2 {
    font-size: 28px;
    line-height: 37px;
  }
}

.banner-content-4 h2:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -22px;
  width: 100px;
  height: 2px;
  background-color: #e97730;
}

.banner-content-4 h3 {
  font-size: 30px;
  font-weight: 500;
  color: #e97730;
  margin: 0 0 30px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .banner-content-4 h3 {
    font-size: 24px;
    margin: 0 0 24px;
  }
}

.banner-content-5 {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.banner-content-5.banner-content-5-static {
  position: static;
  transform: inherit;
}

.banner-content-5.position-modify {
  top: 12%;
  transform: inherit;
}

.banner-content-5 span {
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #ffffff;
  position: relative;
}

.banner-content-5 span.black {
  color: #000000;
}

.banner-content-5 span:before {
  position: absolute;
  content: "";
  left: -67px;
  width: 50px;
  height: 3px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #e97730;
}

.banner-content-5 span:after {
  position: absolute;
  content: "";
  right: -67px;
  width: 50px;
  height: 3px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #e97730;
}

.banner-content-5 h2 {
  font-size: 40px;
  font-weight: 600;
  margin: 17px 0 40px;
  color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-content-5 h2 {
    margin: 17px 0 25px;
    font-size: 27px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-content-5 h2 {
    margin: 10px 0 22px;
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-content-5 h2 {
    margin: 10px 0 22px;
    font-size: 28px;
  }
}

.banner-content-5 h1 {
  font-size: 72px;
  line-height: 67px;
  margin: 25px 0 40px;
  font-family: "URWAbbeyW01";
  color: #ffffff;
  -webkit-text-stroke: 4px #e97730;
}

.banner-content-5 h1.font-montserrat {
  font-weight: bold;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  -webkit-text-stroke: inherit;
  margin: 21px 0 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-content-5 h1.font-montserrat {
    font-size: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-content-5 h1.font-montserrat {
    font-size: 54px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-content-5 h1.font-montserrat {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-content-5 h1.font-montserrat {
    margin: 12px 0 8px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-content-5 h1 {
    font-size: 60px;
    line-height: 58px;
    margin: 15px 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-content-5 h1 {
    font-size: 48px;
    line-height: 50px;
    margin: 15px 0 20px;
  }
}

.banner-content-5 p {
  font-size: 16px;
  margin: 0 0 40px;
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .banner-content-5 p {
    margin: 0 0 20px;
  }
}

.bg-padding-1 {
  padding: 52px 0 60px;
}

.bg-padding-2 {
  padding: 148px 0 153px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bg-padding-2 {
    padding: 100px 0 105px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-padding-2 {
    padding: 90px 0 95px;
  }
}

@media only screen and (max-width: 767px) {
  .bg-padding-2 {
    padding: 65px 0 70px;
  }
}

.bg-padding-3 {
  padding: 171px 0 176px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bg-padding-3 {
    padding: 100px 0 105px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-padding-3 {
    padding: 90px 0 95px;
  }
}

@media only screen and (max-width: 767px) {
  .bg-padding-3 {
    padding: 90px 0 95px;
  }
}

.btn-style-6 a {
  display: inline-block;
  z-index: 1;
  font-weight: 500;
}

.btn-style-6 a.btn {
  color: #fdfdfd;
  background-color: #000000;
  padding: 11px 20px 11px;
  border: none;
}

.btn-style-6 a.btn.padding-dec {
  padding: 10px 17px 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-6 a.btn {
    font-size: 13px;
    padding: 9px 14px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-6 a.btn {
    font-size: 13px;
    padding: 9px 14px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-6 a.btn {
    padding: 7px 15px 9px;
    font-size: 13px;
  }
}

.btn-style-6-position {
  position: absolute;
  left: 30px;
  bottom: 20px;
}

.btn-style-7 a {
  display: inline-block;
  font-weight: 500;
  line-height: 1;
  font-size: 16px;
  border-bottom: 1px solid #000000;
}

@media only screen and (max-width: 767px) {
  .btn-style-7 a {
    font-size: 14px;
  }
}

.btn-style-7 a:hover {
  border-bottom: 1px solid #e97730;
}

@media only screen and (max-width: 767px) {
  .banner-area.pt-80 {
    padding-top: 70px;
  }
  .banner-responsive-mt {
    margin-top: 30px;
  }
}

/*-------- 05. Section title style ---------*/
.section-title-timer-wrap {
  display: inline-flex;
  align-items: center;
  z-index: 2;
  position: relative;
  padding: 0 44px 0 0;
}

@media only screen and (max-width: 767px) {
  .section-title-timer-wrap {
    padding: 0 0px 0 0;
    display: block;
    text-align: center;
  }
}

.section-title-timer-wrap .section-title-1 h2 {
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: .3px;
  margin: 0;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.section-border {
  position: relative;
}

.section-border::before {
  background-color: #e1e1e1;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.4s ease 0s;
  width: 100%;
  z-index: 1;
}

.section-border-margin-1 {
  margin: 0 107px -29px 0;
}

@media only screen and (max-width: 767px) {
  .section-border-margin-1 {
    margin: 0 0px 0px 0;
  }
}

.section-title-tab-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .section-title-tab-wrap {
    display: block;
    text-align: center;
  }
  .section-title-tab-wrap.mb-75 {
    margin-bottom: 35px;
  }
}

.section-title-2 h2 {
  font-size: 36px;
  font-weight: 600;
  color: #2c2c2c;
  margin: 0;
  line-height: 1;
  display: inline-block;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-2 h2 {
    font-size: 34px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-2 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-2 h2 {
    font-size: 24px;
  }
}

.section-title-2 h2::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -17px;
  width: 100px;
  height: 2px;
  background-color: #e97730;
}

@media only screen and (max-width: 767px) {
  .section-title-2 h2::before {
    right: 0;
    margin: auto;
  }
}

.section-title-2.border-none h2::before {
  display: none;
}

.section-title-2.st-border-center h2::before {
  left: 0;
  right: 0;
  margin: auto;
}

@media only screen and (max-width: 767px) {
  .section-title-2.st-border-center h2::before {
    bottom: -10px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-2.mb-75 {
    margin-bottom: 35px;
  }
}

.section-title-3 h2 {
  font-size: 40px;
  font-weight: 600;
  color: #2c2c2c;
  margin: 0;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .section-title-3 h2 {
    font-size: 30px;
  }
}

.section-title-3 p {
  color: #7e7e7e;
  font-size: 16px;
  margin: 20px auto 0;
  width: 46%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-3 p {
    width: 56%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-3 p {
    width: 75%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-3 p {
    font-size: 15px;
    width: 100%;
    margin: 12px auto 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-3.mb-55 {
    margin-bottom: 25px;
  }
}

.section-title-4 h2 {
  font-size: 36px;
  font-weight: 600;
  color: #323232;
  margin: 0;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-4 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-4 h2 {
    font-size: 24px;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-4.mb-50 {
    margin-bottom: 25px;
  }
  .section-title-4.mb-55 {
    margin-bottom: 30px;
  }
}

/*-------- 06. Product style ---------*/
.timer-style-1 {
  display: flex;
  background-color: #e97730;
  border-radius: 50px;
  padding: 7px 18px;
  margin-left: 37px;
}

@media only screen and (max-width: 767px) {
  .timer-style-1 {
    margin-left: 0px;
    margin-top: 12px;
    display: inline-flex;
  }
}

.timer-style-1 span {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  margin-right: 10px;
  line-height: 1;
  display: inline-block;
}

.timer-style-1 .syotimer__body {
  display: flex;
}

.timer-style-1 .syotimer__body .syotimer-cell {
  position: relative;
  margin-right: 14px;
}

.timer-style-1 .syotimer__body .syotimer-cell:before {
  position: absolute;
  content: "";
  right: -8px;
  top: 3px;
  background-color: #ffffff;
  width: 2px;
  height: 2px;
}

.timer-style-1 .syotimer__body .syotimer-cell:after {
  position: absolute;
  content: "";
  right: -8px;
  bottom: 5px;
  background-color: #ffffff;
  width: 2px;
  height: 2px;
}

.timer-style-1 .syotimer__body .syotimer-cell:last-child {
  margin-right: 0px;
}

.timer-style-1 .syotimer__body .syotimer-cell:last-child:before {
  display: none;
}

.timer-style-1 .syotimer__body .syotimer-cell:last-child:after {
  display: none;
}

.timer-style-1 .syotimer__body .syotimer-cell .syotimer-cell__value {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  line-height: 1;
}

.timer-style-1 .syotimer__body .syotimer-cell .syotimer-cell__unit {
  display: none;
}

.product-wrap {
  overflow: hidden;
  position: relative;
}

.product-wrap .product-img {
  overflow: hidden;
  position: relative;
}

.product-wrap .product-img a {
  display: block;
}

.product-wrap .product-img a img {
  width: 100%;
  transition: all .4s ease 0s;
}

.product-wrap .product-img .product-badge {
  position: absolute;
  z-index: 5;
}

.product-wrap .product-img .product-badge.badge-top {
  top: 11px;
}

.product-wrap .product-img .product-badge.badge-right {
  right: 11px;
}

.product-wrap .product-img .product-badge.badge-pink {
  color: #fd1748;
}

.product-wrap .product-img .product-badge span {
  font-size: 16px;
  font-weight: 500;
}

.product-wrap .product-img .product-badge-2 {
  position: absolute;
  top: 25px;
  right: 30px;
  transition: all .5s ease 0s;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
}

.product-wrap .product-img .product-badge-2 span {
  font-size: 16px;
  font-weight: 500;
  color: #fd1748;
}

.product-wrap .product-img .product-action-3-wrap {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%) scale(0.7);
  transition: all .5s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.product-wrap .product-img .product-action-3-wrap a {
  display: inline-block;
  font-weight: 500;
  color: #202020;
  border-bottom: 1px solid #9d9998;
  text-transform: uppercase;
  line-height: 1;
}

.product-wrap .product-img .product-action-3-wrap a:hover {
  color: #e97730;
  border-bottom: 1px solid #e97730;
}

.product-wrap .product-img .product-action-wrap {
  display: inline-block;
  left: 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.4s ease 0s;
}

.product-wrap .product-img .product-action-wrap .product-action-btn-1 {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: inline-block;
  font-size: 23px;
  height: 45px;
  margin: 0 3px;
  text-align: center;
  width: 45px;
  border: none;
  border-radius: 6px;
  transition: all 0.4s ease 0s;
}

.product-wrap .product-img .product-action-wrap .product-action-btn-1 i {
  line-height: 45px;
}

.product-wrap .product-img .product-action-wrap .product-action-btn-1:first-child {
  transform: translateY(-20px);
}

.product-wrap .product-img .product-action-wrap .product-action-btn-1:last-child {
  transform: translateY(20px);
}

.product-wrap .product-img .product-action-wrap .product-action-btn-1:hover {
  color: #ffffff;
  background-color: #e97730;
}

.product-wrap .product-img .product-action-2-wrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  opacity: 0;
  visibility: hidden;
  transition: all .4s ease 0s;
}

.product-wrap .product-img .product-action-2-wrap .product-action-btn-2 {
  border: none;
  color: #fff;
  padding: 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  background-color: #000000;
  transition: all .4s ease 0s;
  width: 100%;
  padding: 9px 10px 10px;
  justify-content: center;
}

.product-wrap .product-img .product-action-2-wrap .product-action-btn-2 i {
  font-size: 22px;
  margin-right: 5px;
}

.product-wrap .product-img .product-action-2-wrap:hover .product-action-btn-2 {
  background-color: #e97730;
}

.product-wrap .product-content h3 {
  font-weight: 500;
  margin: 0 0 10px;
  font-size: 16px;
}

.product-wrap .product-content h3 a {
  color: #000000;
}

.product-wrap .product-content h3 a:hover {
  color: #e97730;
}

.product-wrap .product-content.product-content-position {
  position: absolute;
  bottom: 40px;
  left: 40px;
  z-index: 2;
  transition: all .5s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.product-wrap.product-overly:before {
  position: absolute;
  content: "";
  left: 40px;
  right: 40px;
  top: 40px;
  bottom: 40px;
  background-color: rgba(245, 245, 245, 0.88);
  transition: all .5s ease 0s;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.product-wrap:hover .product-img a img {
  transform: scale(1.1);
}

.product-wrap:hover .product-img .product-badge-2 {
  top: 15px;
  right: 20px;
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .product-img .product-action-3-wrap {
  opacity: 1;
  visibility: visible;
  transform: translateY(-50%) scale(1);
}

.product-wrap:hover .product-action-wrap {
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .product-action-wrap .product-action-btn-1:first-child {
  transform: translateY(0px);
}

.product-wrap:hover .product-action-wrap .product-action-btn-1:last-child {
  transform: translateY(0px);
}

.product-wrap:hover .product-action-2-wrap {
  bottom: 0px;
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover.product-overly:before {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
  visibility: visible;
}

.product-wrap:hover .product-content.product-content-position {
  bottom: 20px;
  left: 25px;
  opacity: 1;
  visibility: visible;
}

.product-nav-1 {
  position: absolute;
  z-index: 999;
  right: 0;
  top: 0;
}

@media only screen and (max-width: 767px) {
  .product-nav-1 {
    right: 45%;
    top: 20px;
    transform: translateX(50%);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-nav-1 {
    right: 47%;
  }
}

.product-nav-1 i {
  font-size: 24px;
  color: #555252;
  width: 30px;
  height: 30px;
  text-align: center;
  border: 1px solid #e1e1e1;
  transition: all .3s ease 0s;
}

.product-nav-1 i:hover {
  color: #ffffff;
  background-color: #e97730;
}

.product-nav-1.product-prev-1 {
  margin-right: 35px;
}

.product-slider-active-1.swiper-container {
  padding: 90px 0 0;
}

@media only screen and (max-width: 767px) {
  .product-slider-active-1.swiper-container {
    padding: 85px 0 0;
  }
}

.home-single-product-img {
  margin-left: 70px;
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-single-product-img {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-single-product-img {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .home-single-product-img {
    margin-left: 0px;
    margin-bottom: 25px;
  }
}

.home-single-product-img a {
  display: block;
}

.home-single-product-img a img {
  width: 100%;
  transition: all .4s ease 0s;
  transform: scale(1);
}

.home-single-product-img:hover a img {
  transform: scale(0.9);
}

.home-single-product-content h2 {
  font-weight: 600;
  font-size: 30px;
  margin: 0;
}

.home-single-product-content h3 {
  color: #e97730;
  font-weight: 600;
  font-size: 24px;
  margin: 10px 0 28px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-single-product-content h3 {
    margin: 10px 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .home-single-product-content h3 {
    margin: 10px 0 15px;
  }
}

.home-single-product-content p {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin: 0;
}

.product-color {
  display: flex;
  align-items: center;
  margin: 32px 0 56px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-color {
    margin: 25px 0 31px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-color {
    margin: 20px 0 26px;
  }
}

@media only screen and (max-width: 767px) {
  .product-color {
    margin: 20px 0 26px;
  }
}

.product-color span {
  color: #303030;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  margin: 0 20px 0 0;
}

.product-color ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.product-color ul li {
  margin-right: 15px;
  display: flex;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-color ul li {
    margin-right: 12px;
  }
}

.product-color ul li:last-child {
  margin-right: 0;
}

.product-color ul li a {
  width: 20px;
  height: 20px;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.product-color ul li a.pink {
  background-color: #e930d5;
}

.product-color ul li a.yellow {
  background-color: #e97730;
}

.product-color ul li a.purple {
  background-color: #8b30e9;
}

.product-color.product-details-color {
  margin: 22px 0 32px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-color.product-details-color {
    margin: 22px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-color.product-details-color {
    margin: 22px 0 20px;
  }
}

.product-color.product-details-color ul li a {
  position: relative;
}

.product-color.product-details-color ul li a.active:before {
  position: absolute;
  content: "\e64d";
  font-family: 'themify';
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-size: 14px;
  color: #ffffff;
  line-height: 1;
}

.product-details-action-wrap {
  display: flex;
  align-items: center;
}

.product-quality {
  width: 80px;
  overflow: hidden;
  position: relative;
  border: 1px solid #e8e8e8;
}

.product-quality > input {
  width: 83px;
  border: none;
  height: 60px;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
  color: #8f8f8f;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.product-quality > input::-moz-input-placeholder {
  color: #8f8f8f;
  opacity: 1;
}

.product-quality > input::-webkit-input-placeholder {
  color: #8f8f8f;
  opacity: 1;
}

.product-quality .qtybutton {
  position: absolute;
  color: #8f8f8f;
  left: 10px;
  cursor: pointer;
}

.product-quality .qtybutton.inc {
  left: auto;
  right: 10px;
  font-size: 20px;
  top: 15px;
}

.product-quality .qtybutton.dec {
  top: -2px;
  font-size: 30px;
  line-height: 60px;
}

.single-product-cart {
  margin: 0 24px 0 10px;
}

.single-product-cart.mrg-none {
  margin: 0 24px 0 0px;
}

@media only screen and (max-width: 767px) {
  .single-product-cart {
    margin: 0 10px 0 10px;
  }
}

.single-product-cart > a {
  display: inline-block;
  font-weight: 600;
  color: #ffffff;
  background-color: #e97730;
  padding: 20px 37px;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .single-product-cart > a {
    padding: 20px 17px;
  }
}

.single-product-wishlist {
  margin-right: 23px;
}

.single-product-wishlist a,
.single-product-compare a {
  font-size: 18px;
  color: #010101;
}

.single-product-wishlist a:hover,
.single-product-compare a:hover {
  color: #e97730;
}

.tab-content.jump .tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.tab-content.jump .tab-pane.active {
  display: block;
  height: auto;
  opacity: 1;
  overflow: visible;
  animation-name: lastudioZoomIn;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.26, 0.69, 0.37, 0.96);
  animation-play-state: running;
}

.quickview-modal-style .modal-dialog {
  max-width: 1070px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quickview-modal-style .modal-dialog {
    max-width: 910px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quickview-modal-style .modal-dialog {
    max-width: 700px;
  }
}

@media only screen and (max-width: 767px) {
  .quickview-modal-style .modal-dialog {
    max-width: 320px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .quickview-modal-style .modal-dialog {
    max-width: 500px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 479px) and (max-width: 575px) {
  .quickview-modal-style .modal-dialog {
    max-width: 450px;
    margin: 0 auto;
  }
}

.quickview-modal-style .modal-dialog .modal-content {
  border: none;
  border-radius: 0;
  position: relative;
  padding: 20px;
}

@media only screen and (max-width: 767px) {
  .quickview-modal-style .modal-dialog .modal-content {
    padding: 15px 15px 30px;
  }
}

.quickview-modal-style .modal-dialog .modal-content .modal-header {
  padding: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .quickview-modal-style .modal-dialog .modal-content .modal-header {
    right: 15px;
    top: 15px;
  }
}

.quickview-modal-style .modal-dialog .modal-content .modal-header a {
  background-color: #333;
  font-size: 15px;
  color: #ffffff;
  text-align: center;
  height: 40px;
  width: 40px;
  line-height: 42px;
}

@media only screen and (max-width: 767px) {
  .quickview-modal-style .modal-dialog .modal-content .modal-header a {
    font-size: 11px;
    height: 32px;
    width: 32px;
    line-height: 32px;
  }
}

.quickview-modal-style .modal-dialog .modal-content .modal-header a i {
  line-height: 1;
}

.quickview-modal-style .modal-dialog .modal-content .modal-header a:hover {
  background-color: #e97730;
  color: #ffffff;
}

.quickview-modal-style .modal-dialog .modal-content .modal-body {
  padding: 0;
}

.quickview-modal-style .modal-dialog .modal-content .modal-body .modal-img-wrap img {
  width: 100%;
}

.grid-sizer {
  width: 25%;
}

.product-price span {
  color: #808080;
  font-size: 18px;
  font-weight: 500;
}

.product-price span.new-price {
  color: #fd1748;
}

.product-price span.old-price {
  margin-right: 13px;
  color: #555252;
  text-decoration: line-through;
}

@media only screen and (max-width: 767px) {
  .tab-style-1 {
    margin: 35px 0 0;
    justify-content: center;
  }
}

.tab-style-1 a {
  font-size: 18px;
  display: inline-block;
  font-weight: 500;
  color: #b9b9b9;
  font-family: "Poppins", sans-serif;
  border-bottom: 2px solid transparent;
  margin: 0 23px 0 0;
  padding: 0 0 12px;
}

@media only screen and (max-width: 767px) {
  .tab-style-1 a {
    margin: 0 15px 0 0;
    font-size: 16px;
    padding: 0 0 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tab-style-1 a {
    margin: 0 20px 0 0;
    font-size: 18px;
  }
}

.tab-style-1 a:last-child {
  margin: 0 0 0 0;
}

.tab-style-1 a.active {
  color: #e97730;
  border-bottom: 2px solid #e97730;
}

.tab-style-1.tab-center {
  justify-content: center;
}

.tab-style-1.tab-border-none a {
  border-bottom: none;
  padding: 0 0 0px;
}

.tab-style-1.tab-border-none a.active {
  border-bottom: none;
}

@media only screen and (max-width: 767px) {
  .tab-style-1.tab-border-none a {
    padding: 0 0 3px;
  }
}

.tab-style-1.tab-style-1-margin {
  margin: 0 0 55px;
}

@media only screen and (max-width: 767px) {
  .tab-style-1.tab-style-1-margin {
    margin: 0 0 30px;
  }
}

.tab-style-1.tab-style-1-modify {
  margin: 0 0 60px;
  border-bottom: 2px solid #f1f1f1;
}

@media only screen and (max-width: 767px) {
  .tab-style-1.tab-style-1-modify {
    margin: 0 0 30px;
  }
}

.tab-style-1.tab-style-1-modify a {
  padding: 0 0 6px;
  margin: 0 55px -2px 0;
}

@media only screen and (max-width: 767px) {
  .tab-style-1.tab-style-1-modify a {
    margin: 0 11px 8px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tab-style-1.tab-style-1-modify a {
    margin: 0 15px -2px 0;
  }
}

.tab-style-1.tab-style-1-modify a:last-child {
  margin: 0 0px -2px 0;
}

@media only screen and (max-width: 767px) {
  .product-area.pb-60 {
    padding-bottom: 30px;
  }
}

/*-------- 07. Service style ---------*/
.service-wrap {
  display: flex;
  align-items: center;
}

.service-wrap .service-img {
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  background-color: #f2f2f2;
  border-radius: 100%;
  margin-right: 12px;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .service-wrap .service-img {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
}

.service-wrap .service-img img {
  max-width: 100%;
  transition: all .3s ease 0s;
}

.service-wrap .service-img-3 {
  margin-right: 20px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .service-wrap .service-img-3 {
    margin-right: 10px;
  }
}

.service-wrap .service-img-3 img {
  max-width: 100%;
  transition: all .3s ease 0s;
}

.service-wrap .service-content h3 {
  font-size: 18px;
  margin: 0 0 4px;
  font-family: "Poppins", sans-serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-wrap .service-content h3 {
    font-size: 16px;
  }
}

.service-wrap .service-content p {
  color: #6a6a6a;
  margin: 0;
}

.service-wrap.service-padding {
  padding: 25px 10px 25px 20px;
}

.service-wrap:hover .service-img {
  background-color: #e97730;
}

.service-wrap:hover .service-img img {
  transform: scale(0.8);
}

.service-wrap:hover .service-img-3 img {
  animation: 500ms ease-in-out 0s normal none 1 running tada;
}

.service-wrap-2 .service-img-2 img {
  max-width: 100%;
  transition: all .3s ease 0s;
}

.service-wrap-2 .service-content-2 h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 20px 0 34px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap-2 .service-content-2 h3 {
    margin: 15px 0 34px;
  }
}

.service-wrap-2 .service-content-2 h3:before {
  position: absolute;
  content: "";
  height: 2px;
  width: 50px;
  background-color: #e4e4e4;
  left: 0;
  right: 0;
  bottom: -20px;
  margin: auto;
}

.service-wrap-2 .service-content-2 p {
  font-size: 16px;
  color: #444444;
  font-weight: 300;
  line-height: 28px;
  margin: 0;
}

.service-wrap-2:hover .service-img-2 img {
  animation: 500ms ease-in-out 0s normal none 1 running tada;
}

.service-bg-color-1 {
  background-color: #ece6f2;
}

.service-bg-color-2 {
  background-color: #e6f2f2;
}

.service-bg-color-3 {
  background-color: #f2f2e6;
}

.service-bg-color-4 {
  background-color: #ffe5e5;
}

/*-------- 08. Blog style ---------*/
.blog-wrap .blog-img-date-wrap {
  position: relative;
}

.blog-wrap .blog-img-date-wrap .blog-img {
  overflow: hidden;
}

.blog-wrap .blog-img-date-wrap .blog-img a {
  display: block;
}

.blog-wrap .blog-img-date-wrap .blog-img a img {
  width: 100%;
  transition: all .4s ease 0s;
}

.blog-wrap .blog-img-date-wrap .blog-date {
  position: absolute;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border: 2px solid #e2e2e2;
  text-align: center;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  bottom: -30px;
  right: 16px;
  z-index: 5;
}

.blog-wrap .blog-img-date-wrap .blog-date h5 {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: #3b3b3b;
  margin: 0;
}

.blog-wrap .blog-img-date-wrap .blog-date h5 span {
  display: block;
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-img-date-wrap.mb-5 {
    margin-bottom: 1rem !important;
  }
}

.blog-wrap .blog-content .blog-meta ul {
  display: flex;
}

.blog-wrap .blog-content .blog-meta ul li {
  text-transform: uppercase;
  color: #777777;
  font-weight: 500;
  margin: 0 5px 0 0;
}

.blog-wrap .blog-content .blog-meta ul li a {
  color: #777777;
  font-weight: 500;
}

.blog-wrap .blog-content .blog-meta ul li a:hover {
  color: #e97730;
}

.blog-wrap .blog-content h3 {
  font-size: 24px;
  color: #4a4a4a;
  font-weight: 500;
  margin: 8px 0 17px;
  line-height: 33px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .blog-wrap .blog-content h3 {
    font-size: 23px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap .blog-content h3 {
    font-size: 23px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-content h3 {
    font-size: 18px;
    margin: 5px 0 6px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-content h3 {
    font-size: 19px;
    line-height: 30px;
    margin: 8px 0 8px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content h3 {
    font-size: 18px;
    line-height: 30px;
    margin: 8px 0 8px;
  }
}

.blog-wrap .blog-content h3 a {
  color: #4a4a4a;
}

.blog-wrap .blog-content h3 a:hover {
  color: #e97730;
}

.blog-wrap .blog-content p {
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  line-height: 28px;
  margin: 0 0 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-content p {
    margin: 0 0 15px;
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-content p {
    font-size: 15px;
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content p {
    font-size: 15px;
    margin: 0 0 15px;
  }
}

.blog-wrap .blog-content .blog-btn a {
  display: inline-block;
  color: #000000;
  font-weight: 500;
  line-height: 1;
  border-bottom: 1px solid #b0b0b0;
}

.blog-wrap .blog-content .blog-btn a:hover {
  color: #e97730;
}

.blog-wrap .blog-content .blog-btn-2 a {
  display: inline-block;
}

.blog-wrap .blog-content .blog-btn-2 a.btn {
  font-weight: 500;
  border: 2px solid #000000;
  border-radius: 50px;
  padding: 12px 25px;
  z-index: 1;
}

.blog-wrap .blog-content .blog-btn-2 a.btn:hover {
  border: 2px solid #e97730;
}

.blog-wrap:hover .blog-img-date-wrap .blog-img a img {
  transform: scale(1.1);
}

.blog-details-wrapper .blog-details-img-date-wrap {
  position: relative;
}

.blog-details-wrapper .blog-details-img-date-wrap .blog-details-img img {
  width: 100%;
}

.blog-details-wrapper .blog-details-img-date-wrap .blog-details-date {
  position: absolute;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border: 2px solid #e2e2e2;
  text-align: center;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  bottom: -30px;
  right: 16px;
  z-index: 5;
}

.blog-details-wrapper .blog-details-img-date-wrap .blog-details-date h5 {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: #3b3b3b;
  margin: 0;
}

.blog-details-wrapper .blog-details-img-date-wrap .blog-details-date h5 span {
  display: block;
}

.blog-details-wrapper .blog-meta-2 ul {
  display: flex;
}

.blog-details-wrapper .blog-meta-2 ul li {
  text-transform: uppercase;
  color: #777777;
  font-weight: 500;
  margin: 0 5px 0 0;
}

.blog-details-wrapper .blog-meta-2 ul li a {
  color: #777777;
  font-weight: 500;
}

.blog-details-wrapper .blog-meta-2 ul li a:hover {
  color: #e97730;
}

.blog-details-wrapper h1 {
  font-size: 30px;
  font-weight: 600;
  color: #4a4a4a;
  margin: 6px 0 18px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper h1 {
    font-size: 20px;
  }
}

.blog-details-wrapper > p {
  font-size: 16px;
  font-weight: 300;
  color: #5e5e5e;
  line-height: 28px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper > p {
    font-size: 15px;
    line-height: 26px;
  }
}

.blog-details-wrapper .blockquote-wrap {
  position: relative;
  margin: 35px 0 31px 125px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blockquote-wrap {
    margin: 35px 0 31px 46px;
  }
}

.blog-details-wrapper .blockquote-wrap .quote-img {
  position: absolute;
  top: 3px;
  left: -46px;
}

.blog-details-wrapper .blockquote-wrap h2 {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 28px;
  line-height: 32px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-wrapper .blockquote-wrap h2 {
    font-size: 18px;
    margin: 0 0 15px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blockquote-wrap h2 {
    font-size: 16px;
    margin: 0 0 12px;
    line-height: 26px;
  }
}

.blog-details-wrapper .blockquote-wrap h4 {
  position: relative;
  margin: 0 0 0 33px;
  font-size: 18px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blockquote-wrap h4 {
    font-size: 16px;
  }
}

.blog-details-wrapper .blockquote-wrap h4:before {
  position: absolute;
  content: "";
  left: -35px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 2px;
  background-color: #e97730;
}

.blog-details-wrapper .blog-details-middle-img-wrap {
  margin: 56px 0 23px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-details-middle-img-wrap {
    margin: 33px 0 3px;
  }
}

.blog-details-wrapper .blog-details-middle-img-wrap .blog-details-middle-img {
  overflow: hidden;
}

.blog-details-wrapper .blog-details-middle-img-wrap .blog-details-middle-img img {
  width: 100%;
  transition: all .3s ease 0s;
}

.blog-details-wrapper .blog-details-middle-img-wrap .blog-details-middle-img:hover img {
  transform: scale(1.05);
}

.blog-details-wrapper .tag-social-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  padding: 12px 0 12px;
  margin: 51px 0 60px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .tag-social-wrap {
    display: block;
    margin: 31px 0 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-details-wrapper .tag-social-wrap {
    display: flex;
  }
}

.blog-details-wrapper .tag-social-wrap .tag-wrap {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .tag-social-wrap .tag-wrap {
    margin: 0 0 6px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-details-wrapper .tag-social-wrap .tag-wrap {
    margin: 0 0 0px;
  }
}

.blog-details-wrapper .tag-social-wrap .tag-wrap span {
  font-size: 16px;
  font-weight: 600;
  color: #e97730;
  margin-right: 11px;
}

.blog-details-wrapper .tag-social-wrap .tag-wrap ul li {
  display: inline-block;
  margin-right: 3px;
}

.blog-details-wrapper .tag-social-wrap .tag-wrap ul li a {
  display: inline-block;
  color: #878787;
  font-family: "Poppins", sans-serif;
}

.blog-details-wrapper .tag-social-wrap .tag-wrap ul li a:hover {
  color: #e97730;
}

.blog-details-wrapper .tag-social-wrap .social-comment-digit-wrap {
  display: flex;
}

.blog-details-wrapper .tag-social-wrap .social-comment-digit-wrap .social-icon-style-2 {
  margin-right: 40px;
}

.blog-details-wrapper .tag-social-wrap .social-comment-digit-wrap .social-icon-style-2 a {
  font-size: 14px;
  margin-right: 15px;
}

.blog-details-wrapper .tag-social-wrap .social-comment-digit-wrap .social-icon-style-2 a:last-child {
  margin-right: 0px;
}

.blog-details-wrapper .tag-social-wrap .social-comment-digit-wrap .comment-digit a {
  font-weight: 500;
}

.blog-details-wrapper .tag-social-wrap .social-comment-digit-wrap .comment-digit a i {
  margin-left: 10px;
}

.blog-details-wrapper .blog-author-wrap-2 {
  display: flex;
  align-items: center;
  padding: 40px 40px;
  background-color: #f4f4f4;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-wrapper .blog-author-wrap-2 {
    padding: 30px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-author-wrap-2 {
    display: block;
    text-align: center;
  }
}

.blog-details-wrapper .blog-author-wrap-2 .blog-author-img-2 {
  flex: 0 0 200px;
  margin-right: 30px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-author-wrap-2 .blog-author-img-2 {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}

.blog-details-wrapper .blog-author-wrap-2 .blog-author-img-2 img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-author-wrap-2 .blog-author-img-2 img {
    width: auto;
    max-width: 100%;
  }
}

.blog-details-wrapper .blog-author-wrap-2 .blog-author-content-2 h2 {
  font-weight: 500;
  font-size: 24px;
  color: #4a4a4a;
  margin: 0;
}

.blog-details-wrapper .blog-author-wrap-2 .blog-author-content-2 p {
  color: #5e5e5e;
  font-weight: 300;
  line-height: 28px;
  margin: 15px 0 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-wrapper .blog-author-wrap-2 .blog-author-content-2 p {
    line-height: 26px;
    margin: 10px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-author-wrap-2 .blog-author-content-2 p {
    line-height: 26px;
    margin: 10px 0 20px;
  }
}

.blog-details-wrapper .blog-author-wrap-2 .social-icon-style-3 a {
  margin-right: 17px;
}

.blog-details-wrapper .blog-author-wrap-2 .social-icon-style-3 a:last-child {
  margin-right: 0;
}

.blog-details-wrapper .blog-next-previous-post {
  display: flex;
  justify-content: space-between;
  margin: 60px 0 93px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-next-previous-post {
    display: block;
    margin: 40px 0 33px;
  }
}

.blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap,
.blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap,
  .blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap {
    margin: 0 0 20px;
  }
}

.blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-prev-post-icon a,
.blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-next-post-icon a,
.blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-prev-post-icon a,
.blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-next-post-icon a {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  background-color: #e97730;
  color: #ffffff;
  font-size: 30px;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-prev-post-icon a,
  .blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-next-post-icon a,
  .blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-prev-post-icon a,
  .blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-next-post-icon a {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-prev-post-icon a,
  .blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-next-post-icon a,
  .blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-prev-post-icon a,
  .blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-next-post-icon a {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-prev-post-icon a,
  .blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-next-post-icon a,
  .blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-prev-post-icon a,
  .blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-next-post-icon a {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}

.blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-prev-post-icon a:hover,
.blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-next-post-icon a:hover,
.blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-prev-post-icon a:hover,
.blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-next-post-icon a:hover {
  background-color: #000000;
}

.blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-prev-post-content h3,
.blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-next-post-content h3,
.blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-prev-post-content h3,
.blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-next-post-content h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 13px;
  line-height: 25px;
  width: 80%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-prev-post-content h3,
  .blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-next-post-content h3,
  .blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-prev-post-content h3,
  .blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-next-post-content h3 {
    width: 100%;
    font-size: 17px;
    margin: 0 0 8px;
    line-height: 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-prev-post-content h3,
  .blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-next-post-content h3,
  .blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-prev-post-content h3,
  .blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-next-post-content h3 {
    font-size: 17px;
    margin: 0 0 8px;
    line-height: 23px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-prev-post-content h3,
  .blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-next-post-content h3,
  .blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-prev-post-content h3,
  .blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-next-post-content h3 {
    font-size: 17px;
    margin: 0 0 8px;
    line-height: 23px;
  }
}

.blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-prev-post-content h3 a,
.blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-next-post-content h3 a,
.blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-prev-post-content h3 a,
.blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-next-post-content h3 a {
  color: #4a4a4a;
}

.blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-prev-post-content h3 a:hover,
.blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-next-post-content h3 a:hover,
.blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-prev-post-content h3 a:hover,
.blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-next-post-content h3 a:hover {
  color: #e97730;
}

.blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-prev-post-content span,
.blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-next-post-content span,
.blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-prev-post-content span,
.blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-next-post-content span {
  color: #adadad;
  font-weight: 500;
  text-transform: uppercase;
}

.blog-details-wrapper .blog-next-previous-post .blog-prev-post-wrap .blog-prev-post-content {
  margin-left: 20px;
}

.blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap {
  text-align: right;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap {
    text-align: left;
    flex-direction: inherit;
  }
}

.blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-prev-post-content {
  margin-left: 20px;
}

.blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-next-post-content {
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-next-post-content {
    margin-right: 0px;
    margin-left: 20px;
  }
}

.blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-next-post-content h3 {
  margin: 0 0 13px auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-next-post-content h3 {
    width: 100%;
    margin: 0 0 8px auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-next-post-content h3 {
    margin: 0 0 8px auto;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-next-previous-post .blog-next-post-wrap .blog-next-post-content h3 {
    margin: 0 0 8px 0;
  }
}

.blog-details-wrapper .blog-comment-wrapper {
  margin: 0 0 100px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-comment-wrapper {
    margin: 0 0 40px;
  }
}

.blog-details-wrapper .blog-comment-wrapper .blog-dec-title {
  font-size: 24px;
  font-weight: 600;
  color: #4a4a4a;
  margin: 0 0 60px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-comment-wrapper .blog-dec-title {
    margin: 0 0 20px;
    font-size: 20px;
  }
}

.blog-details-wrapper .blog-comment-wrapper .single-comment-wrapper {
  display: flex;
}

.blog-details-wrapper .blog-comment-wrapper .single-comment-wrapper .blog-comment-img {
  flex: 0 0 120px;
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-comment-wrapper .single-comment-wrapper .blog-comment-img {
    flex: 0 0 80px;
  }
}

.blog-details-wrapper .blog-comment-wrapper .single-comment-wrapper .blog-comment-img img {
  width: 100%;
}

.blog-details-wrapper .blog-comment-wrapper .single-comment-wrapper .comment-info-reply-wrap {
  display: flex;
  justify-content: space-between;
  margin: 0 0 15px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-comment-wrapper .single-comment-wrapper .comment-info-reply-wrap {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-details-wrapper .blog-comment-wrapper .single-comment-wrapper .comment-info-reply-wrap {
    display: flex;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-comment-wrapper .single-comment-wrapper .comment-info-reply-wrap .comment-info {
    margin: 0 0 7px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-details-wrapper .blog-comment-wrapper .single-comment-wrapper .comment-info-reply-wrap .comment-info {
    margin: 0 0 0px;
  }
}

.blog-details-wrapper .blog-comment-wrapper .single-comment-wrapper .comment-info-reply-wrap .comment-info span {
  font-weight: 500;
  color: #adadad;
  text-transform: uppercase;
}

.blog-details-wrapper .blog-comment-wrapper .single-comment-wrapper .comment-info-reply-wrap .comment-info h4 {
  font-size: 16px;
  font-weight: 500;
  color: #363636;
  margin: 7px 0 0;
}

.blog-details-wrapper .blog-comment-wrapper .single-comment-wrapper .comment-info-reply-wrap .comment-reply a {
  display: inline-block;
  line-height: 1;
  font-weight: bold;
  color: #e97730;
  border-bottom: 1px solid #e97730;
  padding: 0 0 1px;
}

.blog-details-wrapper .blog-comment-wrapper .single-comment-wrapper .comment-info-reply-wrap .comment-reply a:hover {
  color: #000000;
  border-bottom: 1px solid #000000;
}

.blog-details-wrapper .blog-comment-wrapper .single-comment-wrapper p {
  color: #5e5e5e;
  line-height: 28px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-comment-wrapper .single-comment-wrapper p {
    line-height: 26px;
  }
}

.blog-details-wrapper .blog-comment-wrapper .single-comment-wrapper.single-comment-border {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #e1e1e1;
}

.blog-details-wrapper .blog-comment-wrapper .single-comment-wrapper.single-comment-ml {
  margin-left: 120px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-comment-wrapper .single-comment-wrapper.single-comment-ml {
    margin-left: 0px;
  }
}

.blog-details-wrapper .blog-comment-form-wrap {
  background-color: #f4f4f4;
  border: 1px solid #ededed;
  padding: 44px 50px 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-wrapper .blog-comment-form-wrap {
    padding: 44px 40px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-comment-form-wrap {
    padding: 44px 25px 50px;
  }
}

.blog-details-wrapper .blog-comment-form-wrap .blog-comment-form-title {
  margin: 0 0 40px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-comment-form-wrap .blog-comment-form-title {
    margin: 0 0 25px;
  }
}

.blog-details-wrapper .blog-comment-form-wrap .blog-comment-form-title h2 {
  font-size: 24px;
  font-weight: 600;
  color: #4a4a4a;
  margin: 0 0 10px;
}

.blog-details-wrapper .blog-comment-form-wrap .blog-comment-form-title p {
  font-size: 16px;
  color: #4a4a4a;
  margin: 0;
}

.blog-details-wrapper .blog-comment-form-wrap .blog-comment-form form .single-blog-comment-form input,
.blog-details-wrapper .blog-comment-form-wrap .blog-comment-form form .single-blog-comment-form textarea {
  border: 1px solid #e9e9e9;
  background-color: #ffffff;
  height: 50px;
  padding: 2px 24px;
  font-weight: 300;
  color: #4a4a4a;
  margin-bottom: 20px;
}

.blog-details-wrapper .blog-comment-form-wrap .blog-comment-form form .single-blog-comment-form input::-moz-input-placeholder,
.blog-details-wrapper .blog-comment-form-wrap .blog-comment-form form .single-blog-comment-form textarea::-moz-input-placeholder {
  color: #4a4a4a;
  opacity: 1;
}

.blog-details-wrapper .blog-comment-form-wrap .blog-comment-form form .single-blog-comment-form input::-webkit-input-placeholder,
.blog-details-wrapper .blog-comment-form-wrap .blog-comment-form form .single-blog-comment-form textarea::-webkit-input-placeholder {
  color: #4a4a4a;
  opacity: 1;
}

.blog-details-wrapper .blog-comment-form-wrap .blog-comment-form form .single-blog-comment-form textarea {
  height: 140px;
  padding: 18px 24px;
}

.blog-details-wrapper .blog-comment-form-wrap .blog-comment-form form .single-blog-comment-checkbox {
  display: flex;
  align-items: flex-start;
  margin: 0 0 36px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-comment-form-wrap .blog-comment-form form .single-blog-comment-checkbox {
    margin: 0 0 25px;
  }
}

.blog-details-wrapper .blog-comment-form-wrap .blog-comment-form form .single-blog-comment-checkbox input {
  width: auto;
  height: auto;
  margin: 5px 0 0;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-comment-form-wrap .blog-comment-form form .single-blog-comment-checkbox input {
    margin: 3px 0 0;
  }
}

.blog-details-wrapper .blog-comment-form-wrap .blog-comment-form form .single-blog-comment-checkbox label {
  color: #4a4a4a;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  margin-left: 9px;
}

.blog-details-wrapper .blog-comment-form-wrap .blog-comment-form form .comment-submit-btn button {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: .02px;
  color: #ffffff;
  background-color: #e97730;
  padding: 5px 10px;
  border: none;
  padding: 15px 43px;
  z-index: 1;
}

.blog-details-wrapper .blog-comment-form-wrap .blog-comment-form form .comment-submit-btn button i {
  font-size: 16px;
  position: relative;
  top: 2px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-comment-form-wrap .blog-comment-form form .comment-submit-btn button {
    padding: 12px 27px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap.mb-50 {
    margin-bottom: 40px;
  }
}

/*-------- 09. Footer style ---------*/
.footer-about .footer-logo a {
  display: block;
}

.footer-about .footer-logo a img {
  max-width: 100%;
}

.footer-about p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 300;
  color: #4a4a4a;
  margin: 23px 0 20px;
}

@media only screen and (max-width: 767px) {
  .footer-about p {
    font-size: 14px;
  }
}

.footer-about .payment-img a {
  display: block;
}

.footer-about .payment-img a img {
  max-width: 100%;
}

.footer-widget h3.footer-title {
  font-size: 20px;
  font-weight: 600;
  color: #323232;
  margin: 0 0 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget h3.footer-title {
    font-size: 19px;
  }
}

.footer-list ul li {
  margin-bottom: 12px;
}

.footer-list ul li:last-child {
  margin-bottom: 0;
}

.footer-list ul li a {
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 767px) {
  .footer-list ul li a {
    font-size: 14px;
  }
}

.footer-list ul li a:hover {
  color: #e97730;
}

.footer-address ul li {
  font-size: 16px;
  font-weight: 300;
  margin: 0 0 6px;
}

@media only screen and (max-width: 767px) {
  .footer-address ul li {
    font-size: 14px;
  }
}

.footer-address ul li span {
  color: #4a4a4a;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.open-time {
  margin: 20px 0 0;
}

.open-time p {
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 300;
  margin: 0 0 2px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .open-time p {
    font-size: 15px;
  }
}

.open-time p span {
  color: #e97730;
}

.open-time p:last-child {
  margin: 0 0 0;
}

.copyright {
  padding: 14px 0 12px;
}

.copyright p {
  margin: 0;
  color: #333;
  font-weight: 300;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.copyright p i {
  color: #fa0909;
  margin: 0 5px;
}

.copyright p a {
  color: #e97730;
  font-weight: 500;
  margin-left: 5px;
}

.copyright p a:hover {
  color: #000000;
}

.footer-widget-margin-1 {
  margin-left: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget-margin-1 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget-margin-1 {
    margin-left: 0px;
  }
}

.footer-widget-margin-2 {
  margin-left: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget-margin-2 {
    margin-left: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget-margin-2 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget-margin-2 {
    margin-left: 0px;
  }
}

.footer-area-margin-lr {
  margin: 0 120px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-area-margin-lr {
    margin: 0 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-area-margin-lr {
    margin: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-area-margin-lr {
    margin: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-area-margin-lr {
    margin: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-area-margin-lr {
    margin: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-top.pt-80 {
    padding-top: 70px;
  }
  .footer-top.pb-35 {
    padding-bottom: 25px;
  }
}

/*-------- 10. Brand logo style ---------*/
.brand-logo-active {
  border: 1px solid #e1e1e1;
  padding: 48px 10px;
}

.brand-logo-active .swiper-wrapper {
  align-items: center;
}

.brand-logo-active .swiper-wrapper .single-brand-logo {
  display: flex;
  justify-content: center;
}

.brand-logo-active .swiper-wrapper .single-brand-logo a {
  display: block;
}

.brand-logo-active .swiper-wrapper .single-brand-logo a img {
  max-width: 100%;
  transform: scale(1);
  transition: all .4s ease 0s;
}

.brand-logo-active .swiper-wrapper .single-brand-logo:hover a img {
  transform: scale(0.8);
}

/*-------- 11. Category style ---------*/
.single-category-weap .category-img a img {
  width: 100%;
  transition: all .4s ease 0s;
}

.single-category-weap .category-content h4 {
  font-size: 16px;
  margin: 0;
}

.single-category-weap .category-content h4 a {
  color: #000000;
}

.single-category-weap .category-content h4 a:hover {
  color: #e97730;
}

.single-category-weap:hover .category-img a img {
  transform: scale(0.9);
}

.slider-category-area {
  position: relative;
}

.category-area-position {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 46px;
}

.single-category-wrap-2 .category-img-2 a {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.single-category-wrap-2 .category-img-2 a .category-normal-img {
  bottom: 0px;
  opacity: 1;
  visibility: visible;
  transition: all .3s ease 0s;
  position: relative;
}

.single-category-wrap-2 .category-img-2 a .category-hover-img {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  bottom: -20px;
  transition: all .4s ease 0s;
}

.single-category-wrap-2 .category-img-2 a img {
  max-width: 100%;
}

.single-category-wrap-2 .category-content-2 {
  margin-top: 13px;
}

.single-category-wrap-2 .category-content-2 h4 {
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  margin: 0;
}

.single-category-wrap-2 .category-content-2 h4 a {
  color: #ffffff;
}

.single-category-wrap-2 .category-content-2.category-content-2-black h4 {
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  margin: 0;
}

.single-category-wrap-2 .category-content-2.category-content-2-black h4 a {
  color: #000000;
}

.single-category-wrap-2:hover .category-img-2 a .category-normal-img {
  opacity: 0;
  visibility: hidden;
  bottom: 20px;
}

.single-category-wrap-2:hover .category-img-2 a .category-hover-img {
  opacity: 1;
  visibility: visible;
  bottom: 0px;
}

.single-category-wrap-3 {
  position: relative;
  overflow: hidden;
}

.single-category-wrap-3 a {
  display: block;
}

.single-category-wrap-3 a img {
  width: 100%;
  transition: all .5s ease 0s;
}

.single-category-wrap-3 .category-content-3 {
  position: absolute;
  left: 20px;
  bottom: 0px;
  background-color: #ffffff;
  padding: 18px 64px 14px 20px;
  transition: all .5s ease 0s;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-category-wrap-3 .category-content-3 {
    padding: 18px 20px 14px 20px;
  }
}

.single-category-wrap-3 .category-content-3 h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 5px;
  line-height: 1;
  letter-spacing: .5px;
}

.single-category-wrap-3 .category-content-3 span {
  font-weight: 500;
  letter-spacing: .2px;
}

.single-category-wrap-3:hover a img {
  transform: scale(1.1);
  transition: all .3s ease 0s;
}

.single-category-wrap-3:hover .category-content-3 {
  bottom: 20px;
  opacity: 1;
  visibility: visible;
}

/*-------- 12. Subscribe style ---------*/
.subscribe-form .subscribe-form-style .mc-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .subscribe-form .subscribe-form-style .mc-form {
    display: block;
    text-align: center;
  }
}

.subscribe-form .subscribe-form-style .mc-form input {
  border: 2px solid #cccccc;
  color: #818181;
  font-style: italic;
  padding: 2px 18px;
  width: 440px;
  height: 55px;
  margin-right: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-form .subscribe-form-style .mc-form input {
    width: 360px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form .subscribe-form-style .mc-form input {
    width: 360px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form .subscribe-form-style .mc-form input {
    width: 100%;
    margin-right: 0px;
  }
}

.subscribe-form .subscribe-form-style .mc-form input::-webkit-input-placeholder {
  color: #818181;
  opacity: 1;
}

.subscribe-form .subscribe-form-style .mc-form input::-moz-input-placeholder {
  color: #818181;
  opacity: 1;
}

.subscribe-form .subscribe-form-style .mc-form .mc-news {
  display: none;
}

.subscribe-form .subscribe-form-style .mc-form .clear {
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .subscribe-form .subscribe-form-style .mc-form .clear {
    margin-left: 0px;
    margin-top: 15px;
  }
}

.subscribe-form .subscribe-form-style .mc-form .clear input {
  width: auto;
  background-color: #e97730;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  border: none;
  font-style: normal;
  padding: 2px 33px;
}

.subscribe-form .subscribe-form-style .mc-form .clear input:hover {
  background-color: #000000;
}

/*-------- 13. Testimonial style ---------*/
.single-testimonial {
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-top: 2px solid transparent;
  padding: 0 24px 33px 33px;
  position: relative;
}

.single-testimonial:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  bottom: 0;
  transform-origin: left bottom;
  transform: skewY(5deg);
  border-bottom: 2px solid #dedbdb;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial:before {
    transform: skewY(6deg);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial:before {
    transform: skewY(6deg);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-testimonial:before {
    transform: skewY(3deg);
  }
}

@media only screen and (min-width: 479px) and (max-width: 575px) {
  .single-testimonial:before {
    transform: skewY(3deg);
  }
}

.single-testimonial:after {
  content: '';
  width: 2px;
  height: 33px;
  position: absolute;
  bottom: -32px;
  right: -2px;
  background-color: #dedbdb;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial:after {
    bottom: -31px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial:after {
    bottom: -22px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial:after {
    bottom: -29px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-testimonial:after {
    bottom: -27px;
  }
}

@media only screen and (min-width: 479px) and (max-width: 575px) {
  .single-testimonial:after {
    bottom: -23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial {
    padding: 0 20px 33px 20px;
  }
}

.single-testimonial .testimonial-img {
  margin-top: -40px;
  opacity: 0;
  visibility: hidden;
}

.single-testimonial .testimonial-img img {
  max-width: 100%;
}

.single-testimonial p {
  font-size: 16px;
  color: #4b4b4b;
  font-weight: 300;
  font-style: italic;
  margin: 17px 0 15px;
  line-height: 29px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial p {
    font-size: 15px;
    line-height: 26px;
  }
}

.single-testimonial .testimonial-info h4 {
  font-weight: 600;
  font-size: 15px;
  color: #575555;
  margin: 0 0 5px;
  position: relative;
  padding-left: 10px;
}

.single-testimonial .testimonial-info h4:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 2px;
  background-color: #575555;
}

.single-testimonial .testimonial-info span {
  font-size: 14px;
  color: #746e6e;
  font-weight: 500;
}

.testimonial-active .swiper-slide {
  padding: 40px 0 35px;
}

.testimonial-active .swiper-slide.swiper-slide-active .single-testimonial {
  border-left: 2px solid #dedbdb;
  border-right: 2px solid #dedbdb;
  border-top: 2px solid #dedbdb;
}

.testimonial-active .swiper-slide.swiper-slide-active .single-testimonial .testimonial-img {
  opacity: 1;
  visibility: visible;
}

.testimonial-active .swiper-slide.swiper-slide-active .single-testimonial:before {
  opacity: 1;
  visibility: visible;
}

.testimonial-active .swiper-slide.swiper-slide-active .single-testimonial:after {
  opacity: 1;
  visibility: visible;
}

/*-------- 14. Product details style ---------*/
.product-details-content {
  padding: 0 0 0 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content {
    padding: 40px 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content {
    padding: 30px 0 0 0px;
  }
  .product-details-content.pt-small-none {
    padding: 0px 0 0 0px;
  }
}

.product-details-content h2 {
  font-size: 30px;
  font-weight: 500;
  line-height: 1;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content h2 {
    font-size: 24px;
  }
}

.product-details-content .product-details-price {
  margin: 14px 0 23px;
}

.product-details-content .product-details-price span {
  font-weight: 500;
  display: inline-block;
}

.product-details-content .product-details-price span.old-price {
  color: #555252;
  font-size: 18px;
  text-decoration: line-through;
  margin-right: 12px;
}

.product-details-content .product-details-price span.new-price {
  color: #e97730;
  font-size: 20px;
}

.product-details-content .product-details-review {
  display: flex;
  align-items: center;
}

.product-details-content .product-details-review .product-rating i {
  font-size: 18px;
  margin-right: 4px;
  color: #e97730;
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-review .product-rating i {
    font-size: 16px;
    margin-right: 2px;
  }
}

.product-details-content .product-details-review .product-rating i:last-child {
  margin-right: 0;
}

.product-details-content .product-details-review span {
  font-weight: 500;
  color: #9f9e9e;
  display: inline-block;
  margin-left: 20px;
}

.product-details-content p {
  margin: 0 0 34px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content p {
    margin: 0 0 21px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content p {
    margin: 0 0 21px;
  }
}

.product-details-content .product-details-meta {
  border-bottom: 1px solid #f2f2f2;
  margin: 34px 0 40px;
  padding-bottom: 33px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-meta {
    margin: 18px 0 23px;
    padding-bottom: 18px;
  }
}

.product-details-content .product-details-meta > ul > li {
  color: #9f9e9e;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin: 0 0 13px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-meta > ul > li {
    margin: 0 0 7px;
  }
}

.product-details-content .product-details-meta > ul > li:last-child {
  margin-bottom: 0;
}

.product-details-content .product-details-meta > ul > li span {
  color: #5f5f5f;
  margin-right: 5px;
}

.product-details-content .product-details-meta > ul > li ul {
  display: flex;
}

.product-details-content .product-details-meta > ul > li ul li a {
  color: #9f9e9e;
  font-weight: 500;
  font-size: 16px;
}

.product-details-content .product-details-meta > ul > li ul li a:hover {
  color: #e97730;
}

.product-details-content .social-icon-style-4 a {
  background-color: #f2f2f2;
  width: 42px;
  height: 42px;
  line-height: 42px;
  font-size: 16px;
  color: #919191;
  display: inline-block;
  text-align: center;
  margin-right: 7px;
}

.product-details-content .social-icon-style-4 a:last-child {
  margin-right: 0;
}

.product-details-content .social-icon-style-4 a:hover {
  background-color: #e97730;
  color: #ffffff;
}

.product-details-content.quickview-content {
  padding: 0 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .product-details-content.quickview-content {
    padding: 26px 0 0 0px;
  }
}

.product-details-content.quickview-content h2 {
  padding-right: 70px;
}

@media only screen and (max-width: 767px) {
  .product-details-content.quickview-content h2 {
    padding-right: 0px;
  }
}

.product-details-small-img-wrap {
  position: relative;
}

.product-details-vertical-wrap {
  display: flex;
  align-items: center;
}

.product-details-vertical-wrap .product-details-small-img-wrap {
  position: relative;
  width: 85px;
  margin-right: 15px;
}

.product-details-vertical-wrap .product-details-small-img-wrap .product-details-small-img-slider-1 {
  height: 420px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-vertical-wrap .product-details-small-img-wrap .product-details-small-img-slider-1 {
    height: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-vertical-wrap .product-details-small-img-wrap .product-details-small-img-slider-1 {
    height: 190px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-vertical-wrap .product-details-small-img-wrap .product-details-small-img-slider-1 {
    height: 420px;
  }
}

.product-details-vertical-wrap .product-details-big-img-slider-1 {
  width: calc(100% - 100px);
}

.pd-small-img-style .swiper-slide-thumb-active .product-details-small-img img {
  opacity: 1;
}

.pd-small-img-style .product-details-small-img img {
  width: 100%;
  opacity: .5;
  cursor: pointer;
}

.pd-small-img-style.pd-small-img-style-modify {
  margin: 20px 30px 0;
}

.pd-nav-style {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -43px;
  z-index: 5;
}

@media only screen and (max-width: 767px) {
  .pd-nav-style {
    top: -35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pd-nav-style {
    top: -21px;
  }
}

.pd-nav-style i {
  font-size: 24px;
  color: #000000;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .pd-nav-style i {
    font-size: 20px;
  }
}

.pd-nav-style i:hover {
  color: #e97730;
}

.pd-nav-style.pd-next {
  top: auto;
  bottom: -43px;
}

@media only screen and (max-width: 767px) {
  .pd-nav-style.pd-next {
    bottom: -35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pd-nav-style.pd-next {
    bottom: -21px;
  }
}

.pd-nav-style-2 {
  position: absolute;
  left: 0;
  top: 47%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .pd-nav-style-2 {
    top: 50%;
  }
}

.pd-nav-style-2 i {
  font-size: 24px;
  color: #000000;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .pd-nav-style-2 i {
    font-size: 20px;
  }
}

.pd-nav-style-2 i:hover {
  color: #e97730;
}

.pd-nav-style-2.pd-next-2 {
  left: auto;
  right: 0;
}

.easyzoom-style {
  position: relative;
}

.easyzoom-style .easyzoom {
  display: block;
}

.easyzoom-style .easyzoom > a {
  transition: none;
}

.easyzoom-style .easyzoom > a img {
  width: 100%;
  transition: none;
}

.easyzoom-style a.easyzoom-pop-up {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  background-color: #fff;
  color: #000;
  font-size: 23px;
  text-align: center;
  border-radius: 100%;
  z-index: 992;
  box-shadow: 0 0 19px 2.9px rgba(0, 0, 0, 0.02);
}

.easyzoom-style a.easyzoom-pop-up i {
  line-height: 50px;
}

.easyzoom-style a.easyzoom-pop-up:hover {
  background-color: #e97730;
  color: #ffffff;
}

.description-review-topbar {
  justify-content: center;
  background-color: #f2f2f2;
  padding: 13px 10px;
  margin: 0 0 42px;
}

@media only screen and (max-width: 767px) {
  .description-review-topbar {
    padding: 13px 5px;
    margin: 0 0 28px;
  }
}

.description-review-topbar a {
  font-size: 16px;
  color: #919191;
  display: inline-block;
  margin: 0 21px;
}

@media only screen and (max-width: 767px) {
  .description-review-topbar a {
    margin: 0 8px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .description-review-topbar a {
    margin: 0 15px;
  }
}

.description-review-topbar a.active {
  color: #e97730;
}

.product-description-content p {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin: 0 auto 29px;
  width: 67%;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-description-content p {
    width: 82%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-description-content p {
    font-size: 15px;
    width: 100%;
    line-height: 27px;
  }
}

@media only screen and (max-width: 767px) {
  .product-description-content p {
    font-size: 14px;
    width: 100%;
    line-height: 27px;
    margin: 0 auto 20px;
  }
}

.product-description-content p:last-child {
  margin: 0 auto 0px;
}

.specification-wrap table {
  width: 100%;
}

.specification-wrap table tbody {
  border: 1px solid #ebebeb;
  width: 100%;
}

.specification-wrap table tbody tr {
  border-bottom: 1px solid #ebebeb;
}

.specification-wrap table tbody tr td {
  border-right: 1px solid #ebebeb;
  font-size: 15px;
  padding: 12px 10px;
}

@media only screen and (max-width: 767px) {
  .specification-wrap table tbody tr td {
    padding: 12px 15px;
  }
}

.specification-wrap table tbody tr td.width1 {
  width: 215px;
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .specification-wrap table tbody tr td.width1 {
    width: auto;
  }
}

.review-wrapper h3 {
  font-size: 24px;
  margin: 0 0 39px;
  line-height: 20px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .review-wrapper h3 {
    font-size: 17px;
    margin: 0 0 18px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .review-wrapper h3 {
    font-size: 18px;
  }
}

.single-review {
  margin: 0 0 33px;
  display: flex;
  border-bottom: 1px solid #ebebeb;
  padding: 0 0 34px;
}

@media only screen and (max-width: 767px) {
  .single-review {
    display: block;
    margin: 0 0 20px;
    padding: 0 0 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-review {
    display: flex;
  }
}

.single-review .review-img img {
  width: 100px;
}

.single-review .review-content {
  margin-left: 30px;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-review .review-content {
    margin-left: 20px;
  }
}

.single-review .review-content h5 {
  font-size: 14px;
  margin: 11px 0 8px;
}

.single-review .review-content h5 span {
  font-size: 14px;
}

.single-review .review-content .review-rating {
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content .review-rating {
    margin: 10px 0 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-review .review-content .review-rating {
    margin: 0px 0 0px;
  }
}

.single-review .review-content .review-rating a {
  color: #e97730;
}

.single-review .review-content p {
  line-height: 25px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .single-review .review-content p {
    font-size: 15px;
  }
}

.ratting-form-wrapper > h3 {
  display: block;
  line-height: 1;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.ratting-form-wrapper > p {
  margin: 5px 0 0;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 767px) {
  .ratting-form-wrapper > p {
    margin: 15px 0 0;
  }
}

.ratting-form-wrapper .your-rating-wrap {
  display: flex;
  align-items: center;
  margin: 36px 0 0px;
}

@media only screen and (max-width: 767px) {
  .ratting-form-wrapper .your-rating-wrap {
    margin: 20px 0 0px;
  }
}

.ratting-form-wrapper .your-rating-wrap span {
  font-size: 16px;
  display: inline-block;
  margin-right: 20px;
}

.ratting-form-wrapper .your-rating-wrap .your-rating a {
  color: #666;
}

.ratting-form-wrapper .your-rating-wrap .your-rating a:hover {
  color: #e97730;
}

.ratting-form-wrapper .ratting-form {
  margin: 19px 0 0;
}

.ratting-form-wrapper .ratting-form .rating-form-style label {
  margin: 0 0 7px;
  font-size: 14px;
  display: block;
  font-weight: 500;
}

.ratting-form-wrapper .ratting-form .rating-form-style input, .ratting-form-wrapper .ratting-form .rating-form-style textarea {
  border: 1px solid #f2f2f2;
  background-color: #f9f9f9;
  height: 50px;
  color: #8f8f8f;
  font-weight: 300;
  padding: 2px 20px;
}

.ratting-form-wrapper .ratting-form .rating-form-style input::-moz-input-placeholder, .ratting-form-wrapper .ratting-form .rating-form-style textarea::-moz-input-placeholder {
  color: #8f8f8f;
  opacity: 1;
}

.ratting-form-wrapper .ratting-form .rating-form-style input::-webkit-input-placeholder, .ratting-form-wrapper .ratting-form .rating-form-style textarea::-webkit-input-placeholder {
  color: #8f8f8f;
  opacity: 1;
}

.ratting-form-wrapper .ratting-form .rating-form-style textarea {
  height: 150px;
  padding: 17px 20px;
}

@media only screen and (max-width: 767px) {
  .ratting-form-wrapper .ratting-form .rating-form-style textarea {
    height: 100px;
  }
}

.ratting-form-wrapper .ratting-form .save-email-option > p {
  display: flex;
  align-items: center;
  margin: 0 0 5px;
}

.ratting-form-wrapper .ratting-form .save-email-option > p input {
  width: auto;
  height: auto;
}

.ratting-form-wrapper .ratting-form .save-email-option label {
  margin: 0 0 0 16px;
}

.ratting-form-wrapper .ratting-form .form-submit {
  margin-top: 9px;
}

.ratting-form-wrapper .ratting-form .form-submit input {
  padding: 13px 45px;
  color: #fff;
  display: inline-block;
  width: auto;
  height: auto;
  font-size: 16px;
  text-transform: capitalize;
  background: #000000;
  border: none;
  font-weight: 500;
}

.ratting-form-wrapper .ratting-form .form-submit input:hover {
  background: #e97730;
}

.affiliate-btn > a {
  display: inline-block;
  font-weight: 600;
  color: #ffffff;
  background-color: #e97730;
  padding: 20px 37px;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .affiliate-btn > a {
    padding: 17px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .affiliate-btn > a {
    padding: 15px 17px;
  }
}

.pro-details-group {
  margin: 0 0 33px;
}

.pro-details-group .single-pro-details-group {
  display: flex;
  align-items: center;
  margin: 0 0 33px;
}

.pro-details-group .single-pro-details-group:last-child {
  margin: 0 0 0;
}

.pro-details-group .single-pro-details-group .group-title-price {
  margin-left: 15px;
}

.pro-details-group .single-pro-details-group .group-title-price h3 {
  font-weight: 500;
  margin: 0 0 10px;
  font-size: 16px;
}

/*-------- 15. Breadcrumb style ---------*/
.breadcrumb-area {
  position: relative;
  overflow: hidden;
}

.breadcrumb-area .breadcrumb-img-1 {
  position: absolute;
  left: 0;
  bottom: 0;
}

.breadcrumb-area .breadcrumb-img-1 img {
  max-width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .breadcrumb-area .breadcrumb-img-1 img {
    max-width: 280px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-area .breadcrumb-img-1 img {
    max-width: 280px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-area .breadcrumb-img-1 img {
    max-width: 230px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-area .breadcrumb-img-1 img {
    max-width: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-area .breadcrumb-img-1 img {
    max-width: 80px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb-area .breadcrumb-img-1 img {
    max-width: 100px;
  }
}

.breadcrumb-area .breadcrumb-img-2 {
  position: absolute;
  right: 0;
  bottom: 0;
}

.breadcrumb-area .breadcrumb-img-2 img {
  max-width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .breadcrumb-area .breadcrumb-img-2 img {
    max-width: 280px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-area .breadcrumb-img-2 img {
    max-width: 280px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-area .breadcrumb-img-2 img {
    max-width: 230px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-area .breadcrumb-img-2 img {
    max-width: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-area .breadcrumb-img-2 img {
    max-width: 80px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb-area .breadcrumb-img-2 img {
    max-width: 100px;
  }
}

.breadcrumb-area:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: -25px;
  margin: 0 auto;
  width: 342px;
  height: 342px;
  border-radius: 100%;
  background-color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-area:before {
    width: 280px;
    height: 280px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-area:before {
    width: 220px;
    height: 220px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-area:before {
    width: 180px;
    height: 180px;
  }
}

.breadcrumb-content {
  position: relative;
}

.breadcrumb-content h2 {
  font-size: 48px;
  font-weight: 500;
  color: #323232;
  line-height: 1;
  margin: 0 0 29px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-content h2 {
    font-size: 40px;
    margin: 0 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-content h2 {
    font-size: 40px;
    margin: 0 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-content h2 {
    font-size: 30px;
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content h2 {
    font-size: 25px;
    margin: 0 0 10px;
  }
}

.breadcrumb-content ul {
  display: flex;
  justify-content: center;
}

.breadcrumb-content ul li {
  margin-right: 7px;
  color: #686868;
}

.breadcrumb-content ul li:last-child {
  margin-right: 0;
}

.breadcrumb-content ul li a {
  font-weight: 500;
  font-size: 13px;
  display: inline-block;
}

.breadcrumb-content ul li i {
  font-size: 13px;
  line-height: 1;
  position: relative;
  top: 2px;
  color: #000000;
}

.breadcrumb-padding-1 {
  padding: 151px 0 152px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-padding-1 {
    padding: 101px 0 102px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-padding-1 {
    padding: 81px 0 82px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-padding-1 {
    padding: 61px 0 62px;
  }
}

/*-------- Sidebar style ---------*/
.sidebar-widget.sidebar-widget-border {
  border-bottom: 1px solid #b7b7b7;
}

.sidebar-widget .search-wrap-2 .search-2-form {
  position: relative;
}

.sidebar-widget .search-wrap-2 .search-2-form input {
  border: 1px solid #b7b7b7;
  height: 45px;
  color: #6d6d6d;
  font-weight: 300;
  font-style: italic;
  padding: 2px 50px 2px 15px;
}

.sidebar-widget .search-wrap-2 .search-2-form input::-moz-input-placeholder {
  color: #6d6d6d;
  opacity: 1;
}

.sidebar-widget .search-wrap-2 .search-2-form input::-webkit-input-placeholder {
  color: #6d6d6d;
  opacity: 1;
}

.sidebar-widget .search-wrap-2 .search-2-form .button-search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  border: none;
  background-color: transparent;
  font-size: 18px;
  color: #000000;
  padding: 0;
}

.sidebar-widget .sidebar-widget-title h3 {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: #343434;
  line-height: 1;
  padding-left: 12px;
  font-family: "Poppins", sans-serif;
}

.sidebar-widget .sidebar-widget-title h3:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #e97730;
}

.sidebar-widget .sidebar-widget-title-2 h3 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  line-height: 1;
  color: #4a4a4a;
  padding-left: 35px;
  position: relative;
}

.sidebar-widget .sidebar-widget-title-2 h3:before {
  position: absolute;
  content: "";
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  background-color: #e97730;
}

.sidebar-widget .sidebar-widget-title-2 h3:after {
  position: absolute;
  content: "";
  left: 7px;
  top: 7px;
  width: 16px;
  height: 16px;
  background-color: #e97730;
  border: 2px solid #ffffff;
}

.sidebar-widget .price-filter #slider-range {
  background: rgba(0, 0, 0, 0.1);
  border: medium none;
  border-radius: 0px;
  height: 3px;
  margin-bottom: 12px;
  margin-left: auto;
  margin-top: 5px;
}

.sidebar-widget .price-filter #slider-range span {
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-range {
  position: absolute;
  display: block;
  border: 0;
  background: #b7b7b7;
  border: none;
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-handle {
  background: #ffffff;
  border: medium none;
  border-radius: 50%;
  height: 13px;
  margin-left: 0;
  width: 13px;
  margin-top: -1px;
  border: 2px solid #b7b7b7;
}

.sidebar-widget .price-filter .price-slider-amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 0;
}

.sidebar-widget .price-filter .price-slider-amount .label-input {
  display: flex;
}

.sidebar-widget .price-filter .price-slider-amount .label-input label {
  font-size: 14px;
  color: #575757;
  font-family: "Poppins", sans-serif;
  margin-right: 2px;
}

.sidebar-widget .price-filter .price-slider-amount .label-input input {
  height: auto;
  width: 50%;
  font-size: 14px;
  background-color: transparent;
  border: none;
  color: #575757;
  font-family: "Poppins", sans-serif;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-widget .price-filter .price-slider-amount .label-input input {
    width: 55%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .price-filter .price-slider-amount .label-input input {
    width: 65%;
  }
}

.sidebar-widget .price-filter .price-slider-amount button {
  background-color: transparent;
  border: medium none;
  color: #575757;
  line-height: 1;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
  transition: all .3s ease 0s;
  font-family: "Poppins", sans-serif;
}

.sidebar-widget .price-filter .price-slider-amount button:hover {
  color: #ed3503;
}

.sidebar-widget .sidebar-list-style ul li {
  display: block;
  margin: 0 0 14px;
}

.sidebar-widget .sidebar-list-style ul li:last-child {
  margin: 0 0 0;
}

.sidebar-widget .sidebar-list-style ul li a {
  color: #575757;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
}

.sidebar-widget .sidebar-list-style ul li a:hover {
  color: #e97730;
}

.sidebar-widget .sidebar-list-style.sidebar-widget-color ul li a {
  padding-left: 32px;
  position: relative;
}

.sidebar-widget .sidebar-list-style.sidebar-widget-color ul li a:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 11px;
  height: 11px;
  border-radius: 100%;
}

.sidebar-widget .sidebar-list-style.sidebar-widget-color ul li a.black:before {
  background-color: #000000;
}

.sidebar-widget .sidebar-list-style.sidebar-widget-color ul li a.blue:before {
  background-color: #0000ff;
}

.sidebar-widget .sidebar-list-style.sidebar-widget-color ul li a.brown:before {
  background-color: #b2aaaa;
}

.sidebar-widget .sidebar-list-style.sidebar-widget-color ul li a.red:before {
  background-color: #ff0000;
}

.sidebar-widget .sidebar-list-style.sidebar-widget-color ul li a.orange:before {
  background-color: #ffba00;
}

.sidebar-widget .sidebar-widget-tag a {
  color: #575757;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  display: inline-block;
  margin-right: 14px;
  margin-bottom: 10px;
}

.sidebar-widget .sidebar-widget-tag a:hover {
  color: #e97730;
}

.sidebar-widget .search-wrap-3 .search-3-form {
  position: relative;
}

.sidebar-widget .search-wrap-3 .search-3-form input {
  border: 1px solid #dcdcdc;
  height: 60px;
  color: #a6a6a6;
  font-weight: 300;
  font-style: italic;
  padding: 2px 70px 2px 25px;
}

.sidebar-widget .search-wrap-3 .search-3-form input::-moz-input-placeholder {
  color: #a6a6a6;
  opacity: 1;
}

.sidebar-widget .search-wrap-3 .search-3-form input::-webkit-input-placeholder {
  color: #a6a6a6;
  opacity: 1;
}

.sidebar-widget .search-wrap-3 .search-3-form .button-search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 19px;
  border: none;
  background-color: transparent;
  font-size: 18px;
  color: #000000;
  padding: 0;
  color: #818181;
}

.sidebar-widget .search-wrap-3 .search-3-form .button-search:before {
  position: absolute;
  content: "";
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 30px;
  background-color: #dcdcdc;
}

.sidebar-widget .search-wrap-3 .search-3-form .button-search:hover {
  color: #e97730;
}

.sidebar-widget .blog-author-content {
  background-color: #f4f4f4;
  padding: 40px 20px 35px;
}

.sidebar-widget .blog-author-content > a {
  display: inline-block;
  max-width: 100%;
}

.sidebar-widget .blog-author-content h2 {
  font-size: 24px;
  font-weight: 500;
  color: #4a4a4a;
  margin: 16px 0 0;
  line-height: 1;
}

.sidebar-widget .blog-author-content h4 {
  font-size: 16px;
  color: #4a4a4a;
  margin: 9px 0 25px;
}

.sidebar-widget .blog-author-content .social-icon-style-1 a {
  font-size: 14px;
  margin: 0 8px;
  display: inline-block;
}

.sidebar-widget .sidebar-list-style-2 ul li {
  display: block;
  margin: 0 0 8px;
}

.sidebar-widget .sidebar-list-style-2 ul li:last-child {
  margin: 0 0 0px;
}

.sidebar-widget .sidebar-list-style-2 ul li a {
  font-size: 16px;
  font-weight: 300;
  color: #2f2f2f;
  display: inline-block;
  font-family: "Poppins", sans-serif;
}

.sidebar-widget .sidebar-list-style-2 ul li a:hover {
  color: #e97730;
}

.sidebar-widget .latest-post-wrap .single-latest-post {
  display: flex;
  margin-bottom: 26px;
}

.sidebar-widget .latest-post-wrap .single-latest-post:last-child {
  margin-bottom: 0;
}

.sidebar-widget .latest-post-wrap .single-latest-post .latest-post-img {
  flex: 0 0 115px;
  margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .latest-post-wrap .single-latest-post .latest-post-img {
    flex: 0 0 80px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .latest-post-wrap .single-latest-post .latest-post-img {
    flex: 0 0 110px;
    margin-right: 10px;
  }
}

.sidebar-widget .latest-post-wrap .single-latest-post .latest-post-img a {
  display: inline-block;
}

.sidebar-widget .latest-post-wrap .single-latest-post .latest-post-img a img {
  width: 100%;
}

.sidebar-widget .latest-post-wrap .single-latest-post .latest-post-content span {
  text-transform: uppercase;
  font-weight: 500;
  color: #777777;
}

.sidebar-widget .latest-post-wrap .single-latest-post .latest-post-content h4 {
  font-weight: 600;
  font-size: 16px;
  color: #4a4a4a;
  margin: 4px 0 7px;
  line-height: 23px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .latest-post-wrap .single-latest-post .latest-post-content h4 {
    line-height: 20px;
    font-size: 15px;
    margin: 4px 0 6px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .latest-post-wrap .single-latest-post .latest-post-content h4 {
    line-height: 20px;
    font-size: 15px;
    margin: 4px 0 6px;
  }
}

.sidebar-widget .latest-post-wrap .single-latest-post .latest-post-content h4 a {
  color: #4a4a4a;
}

.sidebar-widget .latest-post-wrap .single-latest-post .latest-post-content h4 a:hover {
  color: #e97730;
}

.sidebar-widget .latest-post-wrap .single-latest-post .latest-post-content .latest-post-btn a {
  font-weight: 500;
  color: #8b8a89;
  display: inline-block;
  text-transform: uppercase;
}

.sidebar-widget .latest-post-wrap .single-latest-post .latest-post-content .latest-post-btn a:hover {
  color: #e97730;
}

.sidebar-widget .sidebar-widget-tag-2 a {
  font-weight: 500;
  color: #959595;
  display: inline-block;
  line-height: 1;
  border: 1px solid #eeeeee;
  padding: 11px 14px;
  margin: 0 11px 15px 0;
}

.sidebar-widget .sidebar-widget-tag-2 a:hover {
  color: #ffffff;
  background-color: #e97730;
  border: 1px solid #e97730;
}

.sidebar-widget .sidebar-banner {
  overflow: hidden;
}

.sidebar-widget .sidebar-banner a {
  display: block;
}

.sidebar-widget .sidebar-banner a img {
  width: 100%;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-widget .sidebar-banner a img {
    width: inherit;
    max-width: 100%;
  }
}

.sidebar-widget .sidebar-banner:hover a img {
  transform: scale(1.04);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-wrapper {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-wrapper {
    margin-top: 40px;
  }
  .sidebar-wrapper.blog-sidebar-mt {
    margin-top: 50px;
  }
  .shop-page-responsive.pb-100 {
    padding-bottom: 55px;
  }
}

/*-------- 17. Shop style ---------*/
.shop-topbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .shop-topbar-wrapper {
    display: block;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-topbar-wrapper {
    display: flex;
    text-align: inherit;
  }
}

.shop-topbar-wrapper .shop-topbar-left span {
  color: #6d6d6d;
  font-weight: 500;
  font-style: italic;
}

.shop-topbar-wrapper .shop-topbar-right {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .shop-topbar-wrapper .shop-topbar-right {
    justify-content: center;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-topbar-wrapper .shop-topbar-right {
    justify-content: flex-end;
    margin-top: 0px;
  }
}

.shop-topbar-wrapper .shop-topbar-right .shop-sorting-area {
  margin-right: 10px;
}

.shop-topbar-wrapper .shop-topbar-right .shop-sorting-area .nice-select.nice-select-style-1 {
  height: 33px;
  line-height: 32px;
  border: solid 1px #b7b7b7;
  padding-left: 17px;
  padding-right: 50px;
  color: #6d6d6d;
  font-style: italic;
  border-radius: 0;
}

.shop-topbar-wrapper .shop-topbar-right .shop-sorting-area .nice-select.nice-select-style-1::before {
  position: absolute;
  content: "\e64b";
  font-family: 'themify';
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  font-style: normal;
  color: #000000;
}

.shop-topbar-wrapper .shop-topbar-right .shop-sorting-area .nice-select.nice-select-style-1::after {
  display: none;
}

.shop-topbar-wrapper .shop-topbar-right .shop-sorting-area .nice-select.nice-select-style-1.open::before {
  content: "\e648";
}

.shop-topbar-wrapper .shop-topbar-right .shop-view-mode a {
  width: 33px;
  height: 33px;
  line-height: 33px;
  font-size: 18px;
  color: #606060;
  text-align: center;
  border: 1px solid transparent;
  margin-right: 10px;
  background-color: #f3f3f3;
}

.shop-topbar-wrapper .shop-topbar-right .shop-view-mode a.active {
  border: 1px solid #b7b7b7;
  background-color: transparent;
}

.shop-topbar-wrapper .shop-topbar-right .shop-view-mode a:last-child {
  margin-right: 0;
}

.shop-list-wrap .product-list-img {
  position: relative;
}

.shop-list-wrap .product-list-img a {
  display: block;
}

.shop-list-wrap .product-list-img a img {
  width: 100%;
}

.shop-list-wrap .product-list-img .product-list-badge {
  position: absolute;
  top: 7px;
}

.shop-list-wrap .product-list-img .product-list-badge.badge-right {
  right: 11px;
}

.shop-list-wrap .product-list-img .product-list-badge.badge-pink span {
  color: #fd1748;
}

.shop-list-wrap .product-list-img .product-list-badge span {
  font-size: 16px;
  font-weight: 500;
}

.shop-list-wrap .product-list-img .product-list-quickview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
  margin-top: 20px;
}

.shop-list-wrap .product-list-img .product-list-quickview .product-action-btn-2 {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: inline-block;
  font-size: 23px;
  height: 45px;
  text-align: center;
  width: 45px;
  line-height: 45px;
  border: none;
  border-radius: 6px;
  padding: 0;
  transition: all 0.4s ease 0s;
}

.shop-list-wrap .product-list-img .product-list-quickview .product-action-btn-2:hover {
  color: #ffffff;
  background-color: #e97730;
}

.shop-list-wrap .shop-list-content h3 {
  font-weight: 500;
  margin: 0 0 10px;
  font-size: 18px;
}

.shop-list-wrap .shop-list-content h3 a {
  color: #000000;
}

.shop-list-wrap .shop-list-content h3 a:hover {
  color: #e97730;
}

.shop-list-wrap .shop-list-content .product-list-rating {
  margin: 11px 0 0;
}

.shop-list-wrap .shop-list-content .product-list-rating i {
  font-size: 15px;
  margin-right: 2px;
  color: #e97730;
}

.shop-list-wrap .shop-list-content p {
  margin: 8px 0 14px;
}

.shop-list-wrap .shop-list-content .product-list-action .product-action-btn-3 {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: inline-block;
  font-size: 23px;
  height: 45px;
  margin-right: 10px;
  text-align: center;
  width: 45px;
  line-height: 47px;
  border: none;
  border-radius: 6px;
  transition: all 0.4s ease 0s;
  padding: 0;
}

.shop-list-wrap .shop-list-content .product-list-action .product-action-btn-3:last-child {
  margin-right: 0;
}

.shop-list-wrap .shop-list-content .product-list-action .product-action-btn-3:hover {
  color: #ffffff;
  background-color: #e97730;
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .shop-list-content {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-list-wrap .shop-list-content {
    margin-top: 0px;
  }
}

.shop-list-wrap:hover .product-list-quickview {
  margin-top: 0px;
  opacity: 1;
  visibility: visible;
}

.pagination-style-1 ul {
  display: flex;
  justify-content: center;
}

.pagination-style-1 ul li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  border-radius: 100%;
  margin: 0 4px;
  color: #333;
  text-align: center;
}

.pagination-style-1 ul li a:hover {
  color: #e97730;
}

.pagination-style-1 ul li a.active {
  background-color: #e97730;
  color: #ffffff;
}

.single-store h3 {
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 28px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .single-store h3 {
    font-size: 20px;
    margin-bottom: 8px;
  }
}

.single-store ul li {
  margin-bottom: 7px;
  color: #000000;
}

.single-store ul li:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .single-store.mb-50 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-location.pb-45 {
    padding-bottom: 35px;
  }
}

/*-------- 18. About us style ---------*/
.about-img {
  margin: 0 37px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-img {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-img {
    text-align: center;
    margin: 30px 0px 0;
  }
}

@media only screen and (max-width: 767px) {
  .about-img {
    text-align: center;
    margin: 30px 0px 0;
  }
}

.about-img img {
  width: 100%;
  transition: all .4s ease 0s;
  transform: scale(1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-img img {
    max-width: 100%;
    width: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .about-img img {
    max-width: 100%;
    width: inherit;
  }
}

.about-img:hover img {
  transform: scale(0.9);
}

.about-content h2 {
  font-size: 30px;
  color: #e97730;
  font-family: "Great Vibes", cursive;
  margin: 0;
}

.about-content h1 {
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
  margin: 12px 0 28px;
}

@media only screen and (max-width: 767px) {
  .about-content h1 {
    font-size: 32px;
    margin: 10px 0 18px;
  }
}

.about-content p {
  color: #5e5e5e;
  font-size: 15px;
  font-weight: 300;
  margin: 0 0 26px;
  line-height: 26px;
}

@media only screen and (max-width: 767px) {
  .about-content p {
    font-size: 14px;
    margin: 0 0 16px;
  }
}

.about-content p.mrg-inc {
  margin: 0 0 52px;
}

@media only screen and (max-width: 767px) {
  .about-content p.mrg-inc {
    margin: 0 0 23px;
  }
}

.single-funfact .funfact-img img {
  max-width: 100%;
  transition: all .3s ease 0s;
}

.single-funfact h2 {
  font-weight: bold;
  font-size: 36px;
  color: #3a3a3a;
  margin: 15px 0 5px;
  line-height: 1;
}

.single-funfact span {
  color: #3a3a3a;
  font-size: 15px;
  display: inline-block;
  position: relative;
  padding: 0 0 10px;
}

.single-funfact span:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  width: 48px;
  height: 1px;
  margin: 0 auto;
  background-color: #e97730;
}

.single-funfact:hover .funfact-img img {
  animation: 500ms ease-in-out 0s normal none 1 running tada;
}

.single-team-wrap {
  position: relative;
  border: 1px solid transparent;
  border-radius: 100%;
  overflow: hidden;
  transition: all .4s ease 0s;
}

.single-team-wrap img {
  width: 100%;
  transition: all .4s ease 0s;
}

.single-team-wrap .team-info-position {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  background-color: #fff;
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all .4s ease 0s;
}

.single-team-wrap .team-info-position .team-info h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-team-wrap .team-info-position .team-info h3 {
    margin: 0 0 7px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-team-wrap .team-info-position .team-info h3 {
    font-size: 16px;
    margin: 0 0 3px;
  }
}

.single-team-wrap .team-info-position .team-info span {
  font-size: 16px;
  color: #666;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-team-wrap .team-info-position .team-info span {
    font-size: 14px;
  }
}

.single-team-wrap:hover {
  border: 1px solid #eee;
}

.single-team-wrap:hover img {
  transform: scale(1.08);
}

.single-team-wrap:hover .team-info-position {
  bottom: 0px;
  opacity: 1;
  visibility: visible;
}

/*-------- 19. Contact us style ---------*/
.contact-us-info-wrap .contact-us-info-title {
  margin: 0 0 27px;
}

@media only screen and (max-width: 767px) {
  .contact-us-info-wrap .contact-us-info-title {
    margin: 0 0 12px;
  }
}

.contact-us-info-wrap .contact-us-info-title h3 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  position: relative;
  display: inline-block;
  padding-left: 24px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .contact-us-info-wrap .contact-us-info-title h3 {
    font-size: 20px;
  }
}

.contact-us-info-wrap .contact-us-info-title h3:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #000000;
}

.contact-us-info-wrap .contact-us-info {
  margin: 0 0 18px;
}

@media only screen and (max-width: 767px) {
  .contact-us-info-wrap .contact-us-info {
    margin: 0 0 12px;
  }
}

.contact-us-info-wrap .contact-us-info p {
  font-size: 16px;
  color: #323232;
  margin: 0 0 6px;
}

@media only screen and (max-width: 767px) {
  .contact-us-info-wrap .contact-us-info p {
    font-size: 15px;
    margin: 0 0 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .contact-us-info-wrap .contact-us-info p {
    font-size: 14px;
  }
}

.contact-us-info-wrap .contact-us-info span {
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 767px) {
  .contact-us-info-wrap .contact-us-info span {
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .contact-us-info-wrap .contact-us-info span {
    font-size: 14px;
  }
}

.map iframe {
  height: 500px;
  width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .map iframe {
    height: 400px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .map iframe {
    height: 400px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .map iframe {
    height: 350px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .map iframe {
    height: 350px;
  }
}

@media only screen and (max-width: 767px) {
  .map iframe {
    height: 300px;
  }
}

.contact-form-style input, .contact-form-style textarea {
  border: 1px solid #f2f2f2;
  background-color: #f9f9f9;
  height: 50px;
  color: #8f8f8f;
  font-weight: 300;
  padding: 2px 20px;
  margin: 0 0 30px;
}

.contact-form-style input::-moz-input-placeholder, .contact-form-style textarea::-moz-input-placeholder {
  color: #8f8f8f;
  opacity: 1;
}

.contact-form-style input::-webkit-input-placeholder, .contact-form-style textarea::-webkit-input-placeholder {
  color: #8f8f8f;
  opacity: 1;
}

.contact-form-style textarea {
  padding: 26px 30px;
  height: 290px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-style textarea {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-style textarea {
    height: 150px;
  }
}

.contact-form-style .contact-us-btn {
  text-align: right;
}

.contact-form-style .contact-us-btn button {
  display: inline-block;
  background-color: #e97730;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  padding: 18px 40px;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .contact-form-style .contact-us-btn button {
    font-size: 15px;
    padding: 12px 25px;
  }
}

.contact-form-wrap p.form-messege {
  margin: 0;
  text-align: right;
}

.contact-form-wrap p.form-messege.success {
  margin: 10px 0 0;
}

/*-------- 20. Cart style ---------*/
.cart-table-content .table-content table {
  width: 100%;
}

.cart-table-content .table-content table thead {
  background-color: #f3f3f3;
}

.cart-table-content .table-content table thead > tr th {
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 14px 23px 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th {
    padding: 14px 8px 14px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th {
    padding: 14px 8px 14px;
  }
}

.cart-table-content .table-content table thead > tr th.width-thumbnail {
  min-width: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-thumbnail {
    min-width: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-thumbnail {
    min-width: 80px;
  }
}

.cart-table-content .table-content table thead > tr th.width-name {
  min-width: 400px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-table-content .table-content table thead > tr th.width-name {
    min-width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-name {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-name {
    min-width: 130px;
  }
}

.cart-table-content .table-content table thead > tr th.width-price {
  min-width: 162px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-price {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-price {
    min-width: 100px;
  }
}

.cart-table-content .table-content table thead > tr th.width-quantity {
  min-width: 170px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-quantity {
    min-width: 140px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-quantity {
    min-width: 140px;
  }
}

.cart-table-content .table-content table thead > tr th.width-subtotal {
  min-width: 145px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-subtotal {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-subtotal {
    min-width: 100px;
  }
}

.cart-table-content .table-content table thead > tr th.width-remove {
  min-width: 101px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-remove {
    min-width: 67px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-remove {
    min-width: 67px;
  }
}

.cart-table-content .table-content table tbody > tr {
  border-bottom: 1px solid #e5e5e5;
}

.cart-table-content .table-content table tbody > tr td {
  padding: 23px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table tbody > tr td {
    padding: 23px 8px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table tbody > tr td {
    padding: 23px 8px;
  }
}

.cart-table-content .table-content table tbody > tr td.product-thumbnail a {
  display: block;
}

.cart-table-content .table-content table tbody > tr td.product-thumbnail a img {
  width: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table tbody > tr td.product-thumbnail a img {
    width: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table tbody > tr td.product-thumbnail a img {
    width: 60px;
  }
}

.cart-table-content .table-content table tbody > tr td.product-name h5 {
  display: block;
  font-size: 15px;
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table tbody > tr td.product-name h5 {
    line-height: 1.3;
  }
}

.cart-table-content .table-content table tbody > tr td.product-name h5 a {
  color: #000000;
}

.cart-table-content .table-content table tbody > tr td.product-name h5 a:hover {
  color: #e97730;
}

.cart-table-content .table-content table tbody > tr td.product-cart-price span, .cart-table-content .table-content table tbody > tr td.product-total span {
  font-size: 15px;
}

.cart-table-content .table-content table tbody > tr td.product-remove a {
  font-size: 18px;
  color: #000000;
  display: inline-block;
}

.cart-table-content .table-content table tbody > tr td.product-remove a:hover {
  color: #e97730;
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px 95px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-shiping-update-wrapper {
    padding: 30px 0px 45px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper {
    display: block;
    padding: 30px 0px 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cart-shiping-update-wrapper {
    display: flex;
    padding: 30px 0px 30px;
  }
}

.cart-shiping-update-wrapper .cart-shiping-update > a,
.cart-shiping-update-wrapper .cart-clear > button,
.cart-shiping-update-wrapper .cart-clear > a {
  background-color: #f2f2f2;
  display: inline-block;
  font-size: 15px;
  line-height: 1;
  padding: 17px 30px 17px;
  text-transform: capitalize;
  letter-spacing: .3px;
  border-radius: 0px;
  z-index: 1;
  transition: all .5s ease-in-out 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-shiping-update-wrapper .cart-shiping-update > a,
  .cart-shiping-update-wrapper .cart-clear > button,
  .cart-shiping-update-wrapper .cart-clear > a {
    padding: 17px 25px 17px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .cart-shiping-update > a,
  .cart-shiping-update-wrapper .cart-clear > button,
  .cart-shiping-update-wrapper .cart-clear > a {
    margin: 0 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cart-shiping-update-wrapper .cart-shiping-update > a,
  .cart-shiping-update-wrapper .cart-clear > button,
  .cart-shiping-update-wrapper .cart-clear > a {
    margin: 0 0 0px;
    padding: 17px 22px 17px;
  }
}

.cart-shiping-update-wrapper .cart-clear > button {
  border: medium none;
  cursor: pointer;
  margin-right: 27px;
  border-radius: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-shiping-update-wrapper .cart-clear > button {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .cart-clear > button {
    margin-right: 10px;
  }
}

.cart-calculate-discount-wrap h4 {
  font-size: 20px;
  margin: 0 0 40px;
  font-weight: 500;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-calculate-discount-wrap h4 {
    margin: 0 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-calculate-discount-wrap h4 {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-calculate-discount-wrap {
    margin: 0px 0 40px;
  }
}

.select-style select {
  width: 100%;
}

.select-style .select2-container--default {
  display: block;
  width: 100%;
}

.select-style .select2-container--default .select2-selection--single {
  border: 1px solid #e5e5e5;
  border-radius: 0;
  height: 52px;
  line-height: 52px;
}

.select-style .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 13px;
  right: 10px;
}

.select-style .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #000000;
  line-height: 52px;
  padding-left: 16px;
  padding-right: 20px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #e5e5e5;
  height: 52px;
  background-color: transparent;
}

.calculate-discount-content p {
  font-size: 14px;
  margin-bottom: 18px;
  display: block;
  color: #666;
  line-height: 1;
}

.calculate-discount-content .input-style {
  margin-bottom: 15px;
}

.calculate-discount-content .input-style input {
  color: #000000;
  height: 52px;
  border: 1px solid #e5e5e5;
  background-color: transparent;
  padding: 2px 16px;
  width: 100%;
}

.calculate-discount-content .input-style input:-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}

.calculate-discount-content .input-style input:-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

.calculate-discount-content .calculate-discount-btn a {
  font-size: 15px;
  color: #ffffff;
  background-color: #000000;
  z-index: 1;
  border: none;
}

.calculate-discount-content .calculate-discount-btn a.btn {
  border-radius: 0px;
  padding: 12px 25px 12px;
}

.calculate-discount-content .calculate-discount-btn a:focus {
  box-shadow: none;
}

.cart-clear-wrap {
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grand-total-wrap {
    margin: 10px 0 0;
  }
}

.grand-total-wrap .grand-total-content {
  padding: 30px;
  padding: 34px 30px 33px;
  background-color: #f5f5f5;
}

.grand-total-wrap .grand-total-content h3 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding: 0 0 18px;
}

.grand-total-wrap .grand-total-content h3 span {
  font-size: 16px;
}

.grand-total-wrap .grand-total-content .grand-shipping {
  padding: 38px 0 32px;
}

.grand-total-wrap .grand-total-content .grand-shipping > span {
  font-size: 16px;
}

.grand-total-wrap .grand-total-content .grand-shipping ul {
  padding: 17px 0 0px;
}

.grand-total-wrap .grand-total-content .grand-shipping ul li {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.grand-total-wrap .grand-total-content .grand-shipping ul li:last-child {
  margin-bottom: 0;
}

.grand-total-wrap .grand-total-content .grand-shipping ul li input {
  width: auto;
  height: auto;
}

.grand-total-wrap .grand-total-content .grand-shipping ul li label {
  font-size: 15px;
  font-weight: 400;
  color: #666;
  margin-left: 10px;
}

.grand-total-wrap .grand-total-content .shipping-country {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 35px;
  margin-bottom: 30px;
}

.grand-total-wrap .grand-total-content .shipping-country p {
  display: block;
  padding: 10px 20px;
  background-color: #4bb1cf;
  font-weight: 400;
  color: #fff;
  font-size: 14px;
  line-height: 1.7;
  border-left: 6px solid #3198b6;
  margin: 0;
}

.grand-total-wrap .grand-total h4 {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  margin: 0;
  font-weight: 500;
}

.grand-total-wrap .grand-total h4 span {
  color: #e97730;
}

.grand-total-wrap .grand-total-btn {
  margin-top: 24px;
}

.grand-total-wrap .grand-total-btn a {
  font-size: 15px;
  text-transform: capitalize;
  background-color: #181818;
  display: block;
  border: none;
  color: #ffffff;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .grand-total-wrap .grand-total-btn a {
    font-size: 16px;
  }
}

.grand-total-wrap .grand-total-btn a.btn {
  padding: 15px 20px;
  border-radius: 0;
}

.grand-total-wrap .grand-total-btn a:focus {
  box-shadow: none;
}

/*-------- 21. Wishlist style ---------*/
.wishlist-table-content .table-content table {
  width: 100%;
}

.wishlist-table-content .table-content table thead {
  background-color: #f3f3f3;
}

.wishlist-table-content .table-content table thead > tr th {
  color: #000000;
  font-size: 14px;
  text-transform: uppercase;
  padding: 16px 12px 14px;
  font-weight: 600;
}

.wishlist-table-content .table-content table thead > tr th.width-thumbnail {
  min-width: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content .table-content table thead > tr th.width-thumbnail {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table thead > tr th.width-thumbnail {
    min-width: 80px;
  }
}

.wishlist-table-content .table-content table thead > tr th.width-name {
  min-width: 300px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .wishlist-table-content .table-content table thead > tr th.width-name {
    min-width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content .table-content table thead > tr th.width-name {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table thead > tr th.width-name {
    min-width: 150px;
  }
}

.wishlist-table-content .table-content table thead > tr th.width-price {
  min-width: 162px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content .table-content table thead > tr th.width-price {
    min-width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table thead > tr th.width-price {
    min-width: 120px;
  }
}

.wishlist-table-content .table-content table thead > tr th.width-stock-status {
  min-width: 170px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content .table-content table thead > tr th.width-stock-status {
    min-width: 140px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table thead > tr th.width-stock-status {
    min-width: 140px;
  }
}

.wishlist-table-content .table-content table thead > tr th.width-subtotal {
  min-width: 145px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content .table-content table thead > tr th.width-subtotal {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table thead > tr th.width-subtotal {
    min-width: 100px;
  }
}

.wishlist-table-content .table-content table thead > tr th.width-remove {
  min-width: 50px;
}

.wishlist-table-content .table-content table tbody > tr {
  border-bottom: 1px solid #e5e5e5;
}

.wishlist-table-content .table-content table tbody > tr td {
  padding: 23px 12px;
}

.wishlist-table-content .table-content table tbody > tr td.product-thumbnail a {
  display: block;
}

.wishlist-table-content .table-content table tbody > tr td.product-thumbnail a img {
  width: 80px;
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table tbody > tr td.product-thumbnail a img {
    width: 60px;
  }
}

.wishlist-table-content .table-content table tbody > tr td.product-name h5 {
  display: block;
  font-size: 15px;
  color: #000000;
}

.wishlist-table-content .table-content table tbody > tr td.product-name h5 a {
  color: #000000;
}

.wishlist-table-content .table-content table tbody > tr td.product-name h5 a:hover {
  color: #e97730;
}

.wishlist-table-content .table-content table tbody > tr td.product-wishlist-price span {
  font-size: 15px;
}

.wishlist-table-content .table-content table tbody > tr td.stock-status span {
  font-size: 15px;
}

.wishlist-table-content .table-content table tbody > tr td.product-remove a {
  font-size: 28px;
  color: #c3c3c3;
  display: inline-block;
}

.wishlist-table-content .table-content table tbody > tr td.product-remove a:hover {
  color: #e97730;
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-cart a {
  display: inline-block;
  font-size: 15px;
  color: #ffffff;
  padding: 10px 15px 10px;
  line-height: 1;
  white-space: pre;
  background-color: #e97730;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .wishlist-table-content .table-content table tbody > tr td.wishlist-cart a {
    font-size: 14px;
    padding: 10px 12px 9px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-table-content .table-content table tbody > tr td.wishlist-cart a {
    font-size: 14px;
    padding: 10px 12px 9px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table tbody > tr td.wishlist-cart a {
    font-size: 14px;
    padding: 10px 12px 9px;
  }
}

/*-------- 22. Login register style ---------*/
.login-register-wrapper .login-register-tab-list {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-register-tab-list {
    margin-bottom: 20px;
  }
}

.login-register-wrapper .login-register-tab-list a {
  position: relative;
}

.login-register-wrapper .login-register-tab-list a::before {
  background-color: #454545;
  bottom: 5px;
  content: "";
  height: 18px;
  margin: 0 auto;
  position: absolute;
  right: -2px;
  transition: all 0.4s ease 0s;
  width: 1px;
}

.login-register-wrapper .login-register-tab-list a h4 {
  font-size: 27px;
  margin: 0 20px;
  font-weight: 500;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}

.login-register-wrapper .login-register-tab-list a:last-child::before {
  display: none;
}

.login-register-wrapper .login-register-tab-list a.active h4 {
  color: #e97730;
}

.login-register-wrapper .login-form-container {
  background: transparent none repeat scroll 0 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  padding: 80px;
  text-align: left;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-register-wrapper .login-form-container {
    padding: 80px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-form-container {
    padding: 40px 15px;
  }
}

.login-register-wrapper .login-form-container .login-register-form form input {
  background-color: transparent;
  border: 1px solid #ebebeb;
  font-size: 15px;
  height: 50px;
  margin-bottom: 30px;
  padding: 0 15px;
  color: #000000;
  width: 100%;
}

.login-register-wrapper .login-form-container .login-register-form form input::-moz-input-placeholder {
  opacity: 1;
  color: #000000;
}

.login-register-wrapper .login-form-container .login-register-form form input::-webkit-input-placeholder {
  opacity: 1;
  color: #000000;
}

.login-register-wrapper .login-form-container .login-register-form form input[type="checkbox"] {
  height: 15px;
  margin: 0;
  position: relative;
  top: 2px;
  width: 17px;
  color: #000000;
}

.login-register-wrapper .login-form-container .login-register-form form input[type="checkbox"]:-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}

.login-register-wrapper .login-form-container .login-register-form form input[type="checkbox"]:-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

.login-register-wrapper .login-form-container .login-register-form form .login-toggle-btn {
  padding: 10px 0 19px;
}

.login-register-wrapper .login-form-container .login-register-form form .login-toggle-btn label {
  font-size: 15px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-form-container .login-register-form form .login-toggle-btn label {
    font-size: 14px;
  }
}

.login-register-wrapper .login-form-container .login-register-form form .login-toggle-btn a {
  float: right;
  font-size: 15px;
  color: #000000;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-form-container .login-register-form form .login-toggle-btn a {
    font-size: 14px;
  }
}

.login-register-wrapper .login-form-container .login-register-form form .login-toggle-btn a:hover {
  color: #e97730;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box button {
  background-color: #e97730;
  color: #fff;
  border: medium none;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  padding: 14px 30px 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  z-index: 1;
}

/*-------- 23. My account style ---------*/
.myaccount-tab-menu {
  flex-direction: column;
}

.myaccount-tab-menu a {
  border: 1px solid #ccc;
  border-bottom: none;
  display: block;
  padding: 10px 15px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
}

.myaccount-tab-menu a:last-child {
  border-bottom: 1px solid #ccc;
}

.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active {
  background-color: #e97730;
  border-color: #e97730;
  color: #ffffff;
}

.myaccount-tab-menu a i.fa {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

@media only screen and (max-width: 767px) {
  #myaccountContent {
    margin-top: 30px;
  }
}

.myaccount-content {
  border: 1px solid #eeeeee;
  padding: 30px;
}

@media only screen and (max-width: 767px) {
  .myaccount-content {
    padding: 20px 15px;
  }
}

.myaccount-content form {
  margin-top: -20px;
}

.myaccount-content h3 {
  font-size: 20px;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
  margin-bottom: 25px;
}

.myaccount-content .welcome a:hover {
  color: #e97730;
}

.myaccount-content .welcome strong {
  font-weight: 600;
  color: #e97730;
}

.myaccount-content fieldset {
  margin-top: 20px;
}

.myaccount-content fieldset legend {
  font-size: 16px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.myaccount-content .account-details-form {
  margin-top: 50px;
}

.myaccount-content .account-details-form .single-input-item {
  margin-bottom: 20px;
}

.myaccount-content .account-details-form .single-input-item label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.myaccount-content .account-details-form .single-input-item input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #1f2226;
  font-size: 13px;
  width: 100%;
}

.myaccount-content .account-details-form .single-input-item input:focus {
  border: 1px solid #343538;
}

.myaccount-content .account-details-form .single-input-item button {
  border: none;
  background-color: #e97730;
  text-transform: capitalize;
  padding: 9px 25px 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  z-index: 1;
}

.myaccount-table {
  white-space: nowrap;
  font-size: 14px;
}

.myaccount-table table th,
.myaccount-table .table th {
  padding: 10px;
  font-weight: 600;
  background-color: #f8f8f8;
  border-color: #ccc;
  border-bottom: 0;
  color: #1f2226;
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 10px;
  vertical-align: middle;
  border-color: #ccc;
}

.saved-message {
  background-color: #fff;
  border-top: 3px solid #e97730;
  font-size: 14px;
  padding: 20px 0;
  color: #333;
}

/*-------- Start My Account Page Wrapper --------*/
/*-------- 24. Compare style ---------*/
.compare-table .table tbody tr {
  margin-bottom: 20px;
}

.compare-table .table tbody tr td {
  padding: 20px 20px;
  vertical-align: middle;
  border-color: #e5e5e5;
  color: #000000;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td {
    padding: 20px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td {
    padding: 10px 15px;
  }
}

.compare-table .table tbody tr td.first-column {
  min-width: 175px;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.first-column {
    min-width: 120px;
  }
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 310px;
  vertical-align: bottom;
  text-align: left;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 210px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 210px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 230px;
  }
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 15px;
  display: block;
}

.compare-table .table tbody tr td.product-image-title .image img {
  width: 100%;
}

.compare-table .table tbody tr td.product-image-title .category {
  float: left;
  clear: both;
  color: #e97730;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 16px;
  line-height: 1.2;
  text-transform: capitalize;
  margin-top: 5px;
  font-weight: 500;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td.product-image-title .title {
    font-size: 16px;
  }
}

.compare-table .table tbody tr td.product-image-title .title:hover {
  color: #e97730;
}

.compare-table .table tbody tr td.pro-desc p {
  text-align: left;
  margin: 0;
}

.compare-table .table tbody tr td a.check-btn {
  color: #000000;
  font-size: 15px;
}

.compare-table .table tbody tr td a.check-btn:hover {
  color: #e97730;
}

.compare-table .table tbody tr td.pro-price, .compare-table .table tbody tr td.pro-color, .compare-table .table tbody tr td.pro-stock {
  font-size: 15px;
  font-weight: 400;
  color: #000000;
}

.compare-table .table tbody tr td.pro-remove button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

.compare-table .table tbody tr td.pro-remove button:hover {
  color: #e97730;
}

.compare-table .table tbody tr td.pro-remove button i {
  font-size: 19px;
}

.compare-table .table tbody tr td.pro-ratting i {
  color: #e7a415;
  font-size: 15px;
}

/*------ end Compare Page Wrapper -----*/
/*-------- 25. Checkout style ---------*/
.customer-zone > p {
  font-size: 15px;
  position: relative;
  padding: 14px 30px 14px 60px;
  background-color: #eeeff2;
  margin: 0;
  position: relative;
  color: #333;
  font-weight: 300;
}

.customer-zone > p a {
  color: #333;
  font-weight: 400;
}

.customer-zone > p a:hover {
  color: #e97730;
}

@media only screen and (max-width: 767px) {
  .customer-zone > p {
    padding: 14px 30px 14px 40px;
  }
}

.customer-zone > p:before {
  content: "\e64d";
  color: #6dde6d;
  display: inline-block;
  position: absolute;
  font-family: 'themify';
  left: 15px;
  top: 15px;
  font-size: 18px;
}

.checkout-login-info {
  margin: 20px 0 0;
  display: none;
}

.checkout-login-info p {
  margin: 0 0 12px;
  color: #6d6d6d;
}

.checkout-login-info form {
  display: block;
  overflow: hidden;
}

.checkout-login-info form .sin-checkout-login {
  margin-bottom: 30px;
}

.checkout-login-info form .sin-checkout-login label {
  font-size: 14px;
  text-transform: capitalize;
  margin: 0 0 5px;
}

.checkout-login-info form .sin-checkout-login label span {
  color: red;
}

.checkout-login-info form .sin-checkout-login input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  width: 100%;
  padding: 2px 20px;
}

.checkout-login-info form .sin-checkout-login input:focus {
  border: 1px solid #343538;
}

.checkout-login-info form .button-remember-wrap {
  display: block;
  overflow: hidden;
}

.checkout-login-info form .button-remember-wrap button {
  border: 1px solid #aaa;
  font-size: 13px;
  padding: 10px 30px 9px;
  display: inline-block;
  float: left;
  margin-right: 20px;
  text-transform: uppercase;
}

.checkout-login-info form .button-remember-wrap button:hover {
  background-color: #e97730;
  color: #fff;
  border: 1px solid #e97730;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn {
  display: flex;
  margin: 8px 0 0;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn input {
  height: 15px;
  margin: 0;
  position: relative;
  top: 5px;
  width: 17px;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn label {
  width: 100%;
  font-size: 15px;
  margin: 0 0 0 5px;
}

.checkout-login-info form .lost-password {
  margin: 20px 0 11px;
}

.checkout-login-info form .lost-password a {
  font-size: 15px;
}

.checkout-login-info2 {
  display: none;
  margin: 20px 0 0;
}

.checkout-login-info2 ul li {
  display: inline-block;
}

.checkout-login-info2 ul li a {
  color: #8a8a8a;
  font-size: 12px;
}

.checkout-login-info2 ul li a:hover {
  color: #e97730;
}

.checkout-login-info3 {
  display: none;
  margin-top: 20px;
}

.checkout-login-info3 form input[type="text"] {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  width: 48%;
  font-size: 14px;
  padding: 0 20px;
}

.checkout-login-info3 form input[type="text"]:focus {
  border: 1px solid #1f2226;
}

.checkout-login-info3 form input[type="submit"] {
  background: #1f2226 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  height: 50px;
  margin-left: 6px;
  padding: 5px 30px;
  font-size: 13px;
  text-transform: uppercase;
  width: inherit;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .checkout-login-info3 form input[type="submit"] {
    padding: 5px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-login-info3 form input[type="submit"] {
    padding: 5px 16px;
  }
}

.checkout-login-info3 form input[type="submit"]:hover {
  background-color: #e97730;
}

.billing-info-wrap h3 {
  font-size: 22px;
  position: relative;
  margin: 0 0 55px;
  text-transform: capitalize;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap h3 {
    margin-bottom: 40px;
  }
}

.billing-info-wrap h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -12px;
  background-color: #e97730;
  width: 50px;
  height: 2px;
}

.billing-info-wrap .billing-info label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
  font-weight: 500;
}

.billing-info-wrap .billing-info label abbr {
  color: red;
}

.billing-info-wrap .billing-info input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #000000;
  font-size: 14px;
  width: 100%;
}

.billing-info-wrap .billing-info input:focus {
  border: 1px solid #343538;
}

.billing-info-wrap .billing-info input.billing-address {
  margin-bottom: 20px;
}

.billing-info-wrap .billing-info input::-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}

.billing-info-wrap .billing-info input::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

.billing-info-wrap .billing-select label {
  font-size: 15px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
  font-weight: 500;
}

.billing-info-wrap .billing-select label abbr {
  color: red;
}

.billing-info-wrap .checkout-account input {
  border: 1px solid #9fa0a2;
  display: inline-block;
  float: left;
  height: 15px;
  width: 15px;
  position: relative;
  top: 3px;
  color: #000000;
}

.billing-info-wrap .checkout-account input::-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}

.billing-info-wrap .checkout-account input::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

.billing-info-wrap .checkout-account span {
  color: #000000;
  font-weight: 500;
  margin: 0 0 0 12px;
  font-size: 14px;
  text-transform: capitalize;
}

.billing-info-wrap .checkout-account-toggle label {
  font-size: 15px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.billing-info-wrap .checkout-account-toggle label abbr {
  color: red;
}

.billing-info-wrap .checkout-account-toggle input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  color: #000000;
  font-size: 14px;
  padding: 2px 20px;
  width: 100%;
}

.billing-info-wrap .checkout-account-toggle input:focus {
  border: 1px solid #343538;
}

.billing-info-wrap .checkout-account-toggle input::-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}

.billing-info-wrap .checkout-account-toggle input::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

.billing-info-wrap .different-address {
  display: none;
}

.billing-info-wrap .additional-info-wrap {
  margin: 22px 0 0;
}

.billing-info-wrap .additional-info-wrap label {
  color: #010225;
  font-size: 15px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.billing-info-wrap .additional-info-wrap textarea {
  min-height: 120px;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  padding: 20px;
  color: #000000;
  font-size: 14px;
  width: 100%;
  border: none;
}

.billing-info-wrap .additional-info-wrap textarea::-moz-textarea-placeholder {
  color: #000000;
  opacity: 1;
}

.billing-info-wrap .additional-info-wrap textarea::-webkit-textarea-placeholder {
  color: #000000;
  opacity: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .billing-info-wrap.mr-50 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .billing-info-wrap.mr-50 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap.mr-50 {
    margin-right: 0px;
  }
}

.your-order-area {
  padding: 40px 50px 50px;
  border-width: 2px;
  border-style: solid;
  position: relative;
  border-color: rgba(52, 53, 56, 0.1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .your-order-area {
    padding: 40px 25px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .your-order-area {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .your-order-area {
    padding: 40px 12px 50px;
    margin-top: 30px;
  }
}

.your-order-area h3 {
  font-size: 22px;
  position: relative;
  margin: 0 0 45px;
  text-transform: capitalize;
  width: 100%;
  font-weight: 500;
}

.your-order-area h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -12px;
  background-color: #e97730;
  width: 50px;
  height: 2px;
}

.your-order-area .your-order-wrap .your-order-info {
  border-bottom: 1px solid #e8e8e8;
}

.your-order-area .your-order-wrap .your-order-info ul li {
  font-size: 14px;
  padding: 0 0 7px;
  text-transform: capitalize;
  font-weight: 500;
}

.your-order-area .your-order-wrap .your-order-info ul li span {
  float: right;
  color: #e97730;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal ul li span {
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-info.order-shipping {
  padding: 15px 0 13px;
  display: block;
  overflow: hidden;
}

.your-order-area .your-order-wrap .your-order-info.order-shipping ul li > p {
  float: right;
  font-size: 14px;
  text-align: right;
  color: #181818;
  text-transform: capitalize;
}

.your-order-area .your-order-wrap .your-order-info.order-total {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-total ul li span {
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-middle {
  border-bottom: 1px solid #e8e8e8;
  padding: 24px 0 23px;
}

.your-order-area .your-order-wrap .your-order-middle ul li {
  font-size: 14px;
  margin: 0 0 11px;
}

.your-order-area .your-order-wrap .your-order-middle ul li:last-child {
  margin-bottom: 0;
}

.your-order-area .your-order-wrap .your-order-middle ul li span {
  float: right;
  font-size: 16px;
}

.your-order-area .your-order-wrap .payment-method {
  margin: 40px 0 0;
}

.your-order-area .your-order-wrap .payment-method .sin-payment {
  margin-bottom: 20px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment input {
  width: auto;
  display: inline-block;
  float: left;
  height: auto;
  margin-top: 5px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment label {
  font-size: 15px;
  text-transform: uppercase;
  margin: 0px 0 0 21px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .your-order-area .your-order-wrap .payment-method .sin-payment label {
    display: block;
  }
}

.your-order-area .your-order-wrap .payment-method .sin-payment label img {
  max-width: 140px;
  display: inline-block;
  margin: 0 5px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment label a {
  text-decoration: underline;
}

.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box {
  margin: 6px 0 0;
  display: none;
}

.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box p {
  font-size: 14px;
  margin: 0;
}

.your-order-area .Place-order {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .your-order-area .Place-order {
    margin-top: 20px;
  }
}

.your-order-area .Place-order a {
  background-color: #e97730;
  display: block;
  cursor: pointer;
  padding: 16px 50px 17px;
  text-transform: capitalize;
  width: 100%;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  z-index: 1;
}

/*-------- 21. Wishlist style ---------*/

.wishlist-table-content {
    .table-content {
        table {
            width: 100%;
            thead {
                background-color: #f3f3f3;
                > tr {
                    th {
                        color: $black;
                        font-size: 14px;
                        text-transform: uppercase;
                        padding: 16px 12px 14px;
                        font-weight: 600;
                        &.width-thumbnail {
                            min-width: 130px;
                            @media #{$md-layout} {
                                min-width: 100px;
                            }
                            @media #{$xs-layout} {
                                min-width: 80px;
                            }
                        }
                        &.width-name {
                            min-width: 300px;
                            @media #{$lg-layout} {
                                min-width: 200px;
                            }
                            @media #{$md-layout} {
                                min-width: 100px;
                            }
                            @media #{$xs-layout} {
                                min-width: 150px;
                            }
                        }
                        &.width-price {
                            min-width: 162px;
                            @media #{$md-layout} {
                                min-width: 120px;
                            }
                            @media #{$xs-layout} {
                                min-width: 120px;
                            }
                        }
                        &.width-stock-status {
                            min-width: 170px;
                            @media #{$md-layout} {
                                min-width: 140px;
                            }
                            @media #{$xs-layout} {
                                min-width: 140px;
                            }
                        }
                        &.width-subtotal {
                            min-width: 145px;
                            @media #{$md-layout} {
                                min-width: 100px;
                            }
                            @media #{$xs-layout} {
                                min-width: 100px;
                            }
                        }
                        &.width-remove {
                            min-width: 50px;
                        }
                    }
                }
            }
            tbody > tr {
                border-bottom: 1px solid #e5e5e5;
                td {
                    padding: 23px 12px;
                    &.product-thumbnail {
                        a {
                            display: block;
                            img {
                                width: 80px;
                                @media #{$xs-layout} {
                                    width: 60px;
                                }
                            }
                        }
                    }
                    &.product-name {
                        h5 {
                            display: block;
                            font-size: 15px;
                            color: $black;
                            a {
                                color: $black;
                                &:hover {
                                    color: $theme-color;
                                }
                            }
                        }
                    }
                    &.product-wishlist-price {
                        span {
                            font-size: 15px;
                        }
                    }
                    &.stock-status {
                        span {
                            font-size: 15px;
                        }
                    }
                    &.product-remove {
                        a {
                            font-size: 28px;
                            color: #c3c3c3;
                            display: inline-block;
                            &:hover {
                                color: $theme-color;
                            }
                        }
                    }
                    &.wishlist-cart {
                        a {
                            display: inline-block;
                            font-size: 15px;
                            color: $white;
                            padding: 10px 15px 10px;
                            line-height: 1;
                            white-space: pre;
                            background-color: $theme-color;
                            z-index: 1;
                            @media #{$lg-layout} {
                                font-size: 14px;
                                padding: 10px 12px 9px;
                            }
                            @media #{$md-layout} {
                                font-size: 14px;
                                padding: 10px 12px 9px;
                            }
                            @media #{$xs-layout} {
                                font-size: 14px;
                                padding: 10px 12px 9px;
                            }
                        }
                    }
                }
            }
        }
    }
}





/*-------- 25. Checkout style ---------*/

.customer-zone {
    > p {
        font-size: 15px;
        position: relative;
        padding: 14px 30px 14px 60px;
        background-color: #eeeff2;
        margin: 0;
        position: relative;
        color: #333;
        font-weight: 300;
        a {
            color: #333;
            font-weight: 400;
            &:hover {
                color: $theme-color;
            }
        }
        @media #{$xs-layout} {
            padding: 14px 30px 14px 40px;
        }
        &:before {
            content: "\e64d";
            color: #6dde6d;
            display: inline-block;
            position: absolute;
            font-family: 'themify';
            left: 15px;
            top: 15px;
            font-size: 18px;
        }
    }
}

.checkout-login-info {
    margin: 20px 0 0;
    display: none;
    p {
        margin: 0 0 12px;
        color: #6d6d6d;
    }
    form {
        display: block;
        overflow: hidden;
        .sin-checkout-login {
            margin-bottom: 30px;
            label {
                font-size: 14px;
                text-transform: capitalize;
                margin: 0 0 5px;
                span {
                    color: red;
                }
            }
            input {
                border: 1px solid #e8e8e8;
                height: 50px;
                background-color: transparent;
                width: 100%;
                padding: 2px 20px;
                &:focus {
                    border: 1px solid #343538;
                }
            }
        }
        .button-remember-wrap {
            display: block;
            overflow: hidden;
            button {
                border: 1px solid #aaa;
                font-size: 13px;
                padding: 10px 30px 9px;
                display: inline-block;
                float: left;
                margin-right: 20px;
                text-transform: uppercase;
                &:hover {
                    background-color: $theme-color;
                    color: #fff;
                    border: 1px solid $theme-color;
                }
            }
            .checkout-login-toggle-btn {
                display: flex;
                margin: 8px 0 0;
                input {
                    height: 15px;
                    margin: 0;
                    position: relative;
                    top: 5px;
                    width: 17px;
                }
                label {
                    width: 100%;
                    font-size: 15px;
                    margin: 0 0 0 5px;
                }
            }
        }
        .lost-password {
            margin: 20px 0 11px;
            a {
                font-size: 15px;
            }
        }
    }
}

.checkout-login-info2 {
    display: none;
    margin: 20px 0 0;
    ul {
        li{
            display: inline-block;
            a {
               color: #8a8a8a;
                font-size: 12px;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
}

.checkout-login-info3 {
    display: none;
    margin-top: 20px;
    form {
        input[type="text"] {
            border: 1px solid #e8e8e8;
            height: 50px;
            background-color: transparent;
            width: 48%; 
            font-size: 14px;
            padding: 0 20px;
            &:focus {
                border: 1px solid #1f2226;
            }
        }
        input[type="submit"] {
            background: #1f2226 none repeat scroll 0 0;
            border: medium none;
            color: #fff;
            height: 50px;
            margin-left: 6px;
            padding: 5px 30px;
            font-size: 13px;
            text-transform: uppercase;
            width: inherit;
            @media #{$lg-layout} {
                padding: 5px 15px;
            }
            @media #{$xs-layout} {
                padding: 5px 16px;
            }
            &:hover {
                background-color: $theme-color;
            }
        }
    }
}

.billing-info-wrap {
    h3 {
        font-size: 22px;
        position: relative;
        margin: 0 0 55px;
        text-transform: capitalize;
        font-weight: 500;
        @media #{$xs-layout} {
            margin-bottom: 40px;
        }
        &:before {
            position: absolute;
            content: "";
            left: 0;
            bottom: -12px;
            background-color: $theme-color;
            width: 50px;
            height: 2px;
        }
    }
    .billing-info {
        label {
            font-size: 14px;
            text-transform: capitalize;
            display: block;
            margin: 0 0 5px;
            font-weight: 500;
            abbr {
                color: red;
            }
        }
        input {
            border: 1px solid #e8e8e8;
            height: 50px;
            background-color: transparent;
            padding: 2px 20px;
            color: $black;
            font-size: 14px;
            width: 100%;
            &:focus {
                border: 1px solid #343538;
            }
            &.billing-address {
                margin-bottom: 20px;
            }
            &::-moz-input-placeholder {
                color: $black;
                opacity: 1;
            }
            &::-webkit-input-placeholder {
                color: $black;
                opacity: 1;
            }
        }
    }
    .billing-select {
        label {
            font-size: 15px;
            text-transform: capitalize;
            display: block;
            margin: 0 0 5px;
            font-weight: 500;
            abbr {
                color: red;
            }
        }
    }
    .checkout-account {
        input {
            border: 1px solid #9fa0a2;
            display: inline-block;
            float: left;
            height: 15px;
            width: 15px;
            position: relative;
            top: 3px;
            color: $black;
            &::-moz-input-placeholder {
                color: $black;
                opacity: 1;
            }
            &::-webkit-input-placeholder {
                color: $black;
                opacity: 1;
            }
        }
        span {
            color: $black;
            font-weight: 500;
            margin: 0 0 0 12px;
            font-size: 14px;
            text-transform: capitalize;
        }
    }
    .checkout-account-toggle {
        label {
            font-size: 15px;
            text-transform: capitalize;
            display: block;
            margin: 0 0 5px;
            abbr {
                color: red;
            }
        }
        input {
            border: 1px solid #e8e8e8;
            height: 50px;
            background-color: transparent;
            color: $black;
            font-size: 14px;
            padding: 2px 20px;
            width: 100%;
            &:focus {
                border: 1px solid #343538;
            }
            &::-moz-input-placeholder {
                color: $black;
                opacity: 1;
            }
            &::-webkit-input-placeholder {
                color: $black;
                opacity: 1;
            }
        }
    }
    .different-address {
        display: none;
    }
    .additional-info-wrap {
        margin: 22px 0 0;
        label {
            color: #010225;
            font-size: 15px;
            text-transform: capitalize;
            display: block;
            margin: 0 0 5px;
        }
        textarea {
            min-height: 120px;
            background-color: #f7f7f7;
            border-color: #f7f7f7;
            padding: 20px;
            color: $black;
            font-size: 14px;
            width: 100%;
            border: none;
            &::-moz-textarea-placeholder {
                color: $black;
                opacity: 1;
            }
            &::-webkit-textarea-placeholder {
                color: $black;
                opacity: 1;
            }
        }
    }
    @media #{$lg-layout} {
        &.mr-50 {
            margin-right: 0px;
        }
    }
    @media #{$md-layout} {
        &.mr-50 {
            margin-right: 0px;
        }
    }
    @media #{$xs-layout} {
        &.mr-50 {
            margin-right: 0px;
        }
    }
}

.your-order-area {
	padding: 40px 50px 50px;
	border-width: 2px;
	border-style: solid;
	position: relative;
	border-color: rgba(52,53,56,.1);
    @media #{$lg-layout} {
        padding: 40px 25px 50px;
    }
    @media #{$md-layout} {
        margin-top: 30px;
    }
    @media #{$xs-layout} {
        padding: 40px 12px 50px;
        margin-top: 30px;
    }
    h3 {
        font-size: 22px;
        position: relative;
        margin: 0 0 45px;
        text-transform: capitalize;
        width: 100%;
        font-weight: 500;
        &:before {
            position: absolute;
            content: "";
            left: 0;
            bottom: -12px;
            background-color: $theme-color;
            width: 50px;
            height: 2px;
        }
    }
    .your-order-wrap {
        .your-order-info {
            border-bottom: 1px solid #e8e8e8;
            ul {
                li {
                    font-size: 14px;
                    padding: 0 0 7px;
                    text-transform: capitalize;
                    font-weight: 500;
                    span {
                        float: right;
                        color: $theme-color;
                    }
                }
            }
            &.order-subtotal {
                padding: 15px 0 10px;
                ul {
                    li {
                        span {
                            font-size: 16px;
                        }
                    }
                }
            }
            &.order-shipping {
                padding: 15px 0 13px;
                display: block;
                overflow: hidden;
                ul {
                    li {
                        > p {
                            float: right;
                            font-size: 14px;
                            text-align: right;
                            color: #181818;
                            text-transform: capitalize;
                        }
                    }
                }
            }
            &.order-total {
                padding: 15px 0 10px;
                ul {
                    li {
                        span {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .your-order-middle {
            border-bottom: 1px solid #e8e8e8;
            padding: 24px 0 23px;
            ul {
                li {
                    font-size: 14px;
                    margin: 0 0 11px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    span {
                        float: right;
                        font-size: 16px;
                    }
                }
            }
        }
        .payment-method {
            margin: 40px 0 0;
            .sin-payment {
                margin-bottom: 20px;
                input {
                    width: auto;
                    display: inline-block;
                    float: left;
                    height: auto;
                    margin-top: 5px;
                }
                label {
                    font-size: 15px;
                    text-transform: uppercase;
                    margin: 0px 0 0 21px;
                    display: flex;
                    align-items: center;
                    @media #{$xs-layout} {
                        display: block;
                    }
                    img {
                        max-width: 140px;
                        display: inline-block;
                        margin: 0 5px
                    }
                    a {
                        text-decoration: underline;
                    }
                }
                .payment-box {
                    margin: 6px 0 0;
                    display: none;
                    p {
                        font-size: 14px;
                        margin: 0;
                    }
                }
            }
        }
    }
    .Place-order {
        margin-top: 40px;
        @media #{$xs-layout} {
            margin-top: 20px;
        }
        a {
            background-color: $theme-color;
            display: block;
            cursor: pointer;
            padding: 16px 50px 17px;
            text-transform: capitalize;
            width: 100%;
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            z-index: 1;
        }
    }
}






/*-------- Sidebar style ---------*/

.sidebar-widget {
    &.sidebar-widget-border {
        border-bottom: 1px solid #b7b7b7;
    }
    .search-wrap-2 {
        .search-2-form {
            position: relative;
            input {
                border: 1px solid #b7b7b7;
                height: 45px;
                color: #6d6d6d;
                font-weight: 300;
                font-style: italic;
                padding: 2px 50px 2px 15px;
                &::-moz-input-placeholder {
                    color: #6d6d6d;
                    opacity: 1;
                }
                &::-webkit-input-placeholder {
                    color: #6d6d6d;
                    opacity: 1;
                }
            }
            .button-search {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 15px;
                border: none;
                background-color: transparent;
                font-size: 18px;
                color: $black;
                padding: 0;
            }
        }
    }
    .sidebar-widget-title {
        h3 {
            position: relative;
            font-size: 18px;
            font-weight: 500;
            margin: 0;
            color: #343434;
            line-height: 1;
            padding-left: 12px;
            font-family: $poppins;
            &:before {
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                width: 2px;
                height: 100%;
                background-color: $theme-color;
            }
        }
    }
    .sidebar-widget-title-2 {
        h3 {
            font-size: 24px;
            font-weight: 600;
            margin: 0;
            line-height: 1;
            color: #4a4a4a;
            padding-left: 35px;
            position: relative;
            &:before {
                position: absolute;
                content: "";
                left: 0;
                top: 2px;
                width: 16px;
                height: 16px;
                background-color: $theme-color;
            }
            &:after {
                position: absolute;
                content: "";
                left: 7px;
                top: 7px;
                width: 16px;
                height: 16px;
                background-color: $theme-color;
                border: 2px solid $white;
            }
        }
    }
    .price-filter {
        #slider-range {
            background: rgba(0,0,0,.1);
            border: medium none;
            border-radius: 0px;
            height: 3px;
            margin-bottom: 12px;
            margin-left: auto;
            margin-top: 5px;
            span {
                transition: all .0s ease 0s;
            }
            .ui-slider-range {
                position: absolute;
                display: block;
                border: 0;
                background: #b7b7b7;
                border: none;
                transition: all .0s ease 0s;
            }
            .ui-slider-handle {
                background: $white;
                border: medium none;
                border-radius: 50%;
                height: 13px;
                margin-left: 0;
                width: 13px;
                margin-top: -1px;
                border: 2px solid #b7b7b7;
            }
        }
        .price-slider-amount {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px 0 0;
            .label-input {
                display: flex;
                label {
                    font-size: 14px;
                    color: #575757;
                    font-family: $poppins;
                    margin-right: 2px;
                }
                input {
                    height: auto;
                    width: 50%;
                    font-size: 14px;
                    background-color: transparent;
                    border: none;
                    color: #575757;
                    font-family: $poppins;
                    @media #{$xl-layout} {
                        width: 55%;
                    }
                    @media #{$lg-layout} {
                        width: 65%;
                    }
                }
            }
            button {
                background-color: transparent;
                border: medium none;
                color: #575757;
                line-height: 1;
                padding: 0;
                cursor: pointer;
                font-size: 14px;
                transition: all .3s ease 0s;
                font-family: $poppins;
                &:hover {
                    color: #ed3503;
                }
            }
        }
    }
    .sidebar-list-style {
        ul {
            li {
                display: block;
                margin: 0 0 14px;
                &:last-child {
                    margin: 0 0 0;
                }
                a {
                    color: #575757;
                    font-weight: 300;
                    font-family: $poppins;
                    display: flex;
                    justify-content: space-between;
                    &:hover {
                        color: $theme-color;
                    }
                }
            }
        }
        &.sidebar-widget-color {
            ul {
                li {
                    a {
                        padding-left: 32px;
                        position: relative;
                        &:before {
                            position: absolute;
                            content: "";
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 11px;
                            height: 11px;
                            border-radius: 100%;
                        }
                        &.black {
                            &:before {
                                background-color: $black;
                            }
                        }
                        &.blue {
                            &:before {
                                background-color: #0000ff;
                            }
                        }
                        &.brown {
                            &:before {
                                background-color: #b2aaaa;
                            }
                        }
                        &.red {
                            &:before {
                                background-color: #ff0000;
                            }
                        }
                        &.orange {
                            &:before {
                                background-color: #ffba00;
                            }
                        }
                    }
                }
            }
        }
    }
    .sidebar-widget-tag {
        a {
            color: #575757;
            font-weight: 300;
            font-family: $poppins;
            display: inline-block;
            margin-right: 14px;
            margin-bottom: 10px;
            &:hover {
                color: $theme-color;
            }
        }
    }
    .search-wrap-3 {
        .search-3-form {
            position: relative;
            input {
                border: 1px solid #dcdcdc;
                height: 60px;
                color: #a6a6a6;
                font-weight: 300;
                font-style: italic;
                padding: 2px 70px 2px 25px;
                &::-moz-input-placeholder {
                    color: #a6a6a6;
                    opacity: 1;
                }
                &::-webkit-input-placeholder {
                    color: #a6a6a6;
                    opacity: 1;
                }
            }
            .button-search {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 19px;
                border: none;
                background-color: transparent;
                font-size: 18px;
                color: $black;
                padding: 0;
                color: #818181;
                &:before {
                    position: absolute;
                    content: "";
                    left: -20px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 30px;
                    background-color: #dcdcdc;
                }
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
    .blog-author-content {
        background-color: #f4f4f4;
        padding: 40px 20px 35px;
        > a {
            display: inline-block;
            max-width: 100%;
        }
        h2 {
            font-size: 24px;
            font-weight: 500;
            color: #4a4a4a;
            margin: 16px 0 0;
            line-height: 1;
        }
        h4 {
            font-size: 16px;
            color: #4a4a4a;
            margin: 9px 0 25px;
        }
        .social-icon-style-1 {
            a {
                font-size: 14px;
                margin: 0 8px;
                display: inline-block;
            }
        }
    }
    .sidebar-list-style-2 {
        ul {
            li {
                display: block;
                margin: 0 0 8px;
                &:last-child {
                    margin: 0 0 0px;
                }
                a {
                    font-size: 16px;
                    font-weight: 300;
                    color: #2f2f2f;
                    display: inline-block;
                    font-family: $poppins;
                    &:hover {
                        color: $theme-color;
                    }
                }
            }
        }
    }
    .latest-post-wrap {
        .single-latest-post {
            display: flex;
            margin-bottom: 26px;
            &:last-child {
                margin-bottom: 0;
            }
            .latest-post-img {
                flex: 0 0 115px;
                margin-right: 20px;
                @media #{$lg-layout} {
                    flex: 0 0 80px;
                    margin-right: 10px;
                }
                @media #{$xs-layout} {
                    flex: 0 0 110px;
                    margin-right: 10px;
                }
                a {
                    display: inline-block;
                    img {
                        width: 100%;
                    }
                }
            }
            .latest-post-content {
                span {
                    text-transform: uppercase;
                    font-weight: 500;
                    color: #777777;
                }
                h4 {
                    font-weight: 600;
                    font-size: 16px;
                    color: #4a4a4a;
                    margin: 4px 0 7px;
                    line-height: 23px;
                    @media #{$lg-layout} {
                        line-height: 20px;
                        font-size: 15px;
                        margin: 4px 0 6px;
                    }
                    @media #{$xs-layout} {
                        line-height: 20px;
                        font-size: 15px;
                        margin: 4px 0 6px;
                    }
                    a {
                        color: #4a4a4a;
                        &:hover {
                            color: $theme-color;
                        }
                    }
                }
                .latest-post-btn {
                    a {
                        font-weight: 500;
                        color: #8b8a89;
                        display: inline-block;
                        text-transform: uppercase;
                        &:hover {
                            color: $theme-color;
                        }
                    }
                }
            }
        }
    }
    .sidebar-widget-tag-2 {
        a {
            font-weight: 500;
            color: #959595;
            display: inline-block;
            line-height: 1;
            border: 1px solid #eeeeee;
            padding: 11px 14px;
            margin: 0 11px 15px 0;
            &:hover {
                color: $white;
                background-color: $theme-color;
                border: 1px solid $theme-color;
            }
        }
    }
    .sidebar-banner {
        overflow: hidden;
        a {
            display: block;
            img {
                width: 100%;
                transition: all .3s ease 0s;
                @media #{$md-layout} {
                    width: inherit;
                    max-width: 100%;
                }
            }
        }
        &:hover {
            a {
                img {
                    transform: scale(1.04);
                }
            }
        }
    }
}
@media #{$md-layout} {
    .sidebar-wrapper {
        margin-top: 50px;
    }
}
@media #{$xs-layout} {
    .sidebar-wrapper {
        margin-top: 40px;
        &.blog-sidebar-mt {
            margin-top: 50px;
        }
    }
    .shop-page-responsive {
        &.pb-100 {
            padding-bottom: 55px;
        }
    }
}







/*-------- 06. Product style ---------*/

.timer-style-1 {
    display: flex;
    background-color: $theme-color;
    border-radius: 50px;
    padding: 7px 18px;
    margin-left: 37px;
    @media #{$xs-layout} {
        margin-left: 0px;
        margin-top: 12px;
        display: inline-flex;
    }
    span {
        font-size: 16px;
        font-weight: 500;
        font-family: $poppins;
        color: $white;
        margin-right: 10px;
        line-height: 1;
        display: inline-block;
    }
    .syotimer__body {
        display: flex;
        .syotimer-cell {
            position: relative;
            margin-right: 14px;
            &:before {
                position: absolute;
                content: "";
                right: -8px;
                top: 3px;
                background-color: $white;
                width: 2px;
                height: 2px;
            }
            &:after {
                position: absolute;
                content: "";
                right: -8px;
                bottom: 5px;
                background-color: $white;
                width: 2px;
                height: 2px;
            }
            &:last-child {
                margin-right: 0px;
                &:before {
                    display: none;
                }
                &:after {
                    display: none;
                }
            }
            .syotimer-cell__value {
                font-size: 16px;
                font-weight: 500;
                font-family: $poppins;
                color: $white;
                line-height: 1;
            }
            .syotimer-cell__unit {
                display: none;
            }
        }
    }
}

.product-wrap {
    overflow: hidden;
    position: relative;
    .product-img {
        overflow: hidden;
        position: relative;
        a {
            display: block;
            img {
                width: 100%;
                transition: all .4s ease 0s;
            }
        }
        .product-badge {
            position: absolute;
            z-index: 5;
            &.badge-top {
                top: 11px;
            }
            &.badge-right {
                right: 11px;
            }
            &.badge-pink {
                color: $theme-color-pink;
            }
            span {
                font-size: 16px;
                font-weight: 500;
            }
        }
        .product-badge-2 {
            position: absolute;
            top: 25px;
            right: 30px;
            transition: all .5s ease 0s;
            opacity: 0;
            visibility: hidden;
            z-index: 2;
            span {
                font-size: 16px;
                font-weight: 500;
                color: $theme-color-pink;
            }
        }
        .product-action-3-wrap {
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%) scale(.7);
            transition: all .5s ease 0s;
            opacity: 0;
            visibility: hidden;
            a {
                display: inline-block;
                font-weight: 500;
                color: #202020;
                border-bottom: 1px solid #9d9998;
                text-transform: uppercase;
                line-height: 1;
                &:hover {
                    color: $theme-color;
                    border-bottom: 1px solid $theme-color;
                }
            }
        }
        .product-action-wrap {
            display: inline-block;
            left: 0;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            right: 0;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.4s ease 0s;
            .product-action-btn-1 {
                background-color: #fff;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                display: inline-block;
                font-size: 23px;
                height: 45px;
                margin: 0 3px;
                text-align: center;
                width: 45px;
                border: none;
                border-radius: 6px;
                transition: all 0.4s ease 0s;
                i {
                    line-height: 45px;
                }
                &:first-child {
                    transform: translateY(-20px);
                }
                &:last-child {
                    transform: translateY(20px);
                }
                &:hover {
                    color: $white;
                    background-color: $theme-color;
                }
            }
        }
        .product-action-2-wrap {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -20px;
            opacity: 0;
            visibility: hidden;
            transition: all .4s ease 0s;
            .product-action-btn-2 {
                border: none;
                color: #fff;
                padding: 0;
                display: flex;
                align-items: center;
                font-weight: 500;
                background-color: $black;
                transition: all .4s ease 0s;
                width: 100%;
                padding: 9px 10px 10px;
                justify-content: center;
                i {
                    font-size: 22px;
                    margin-right: 5px;
                }
            }
            &:hover {
                .product-action-btn-2 {
                    background-color: $theme-color;
                }
            }
        }
    }
    .product-content {
        h3 {
            font-weight: 500;
            margin: 0 0 10px;
            font-size: 16px;
            a {
                color: $black;
                &:hover {
                    color: $theme-color;
                }
            }
        }
        &.product-content-position {
            position: absolute;
            bottom: 40px;
            left: 40px;
            z-index: 2;
            transition: all .5s ease 0s;
            opacity: 0;
            visibility: hidden;
        }
    }
    &.product-overly {
        &:before {
            position: absolute;
            content: "";
            left: 40px;
            right: 40px;
            top: 40px;
            bottom: 40px;
            background-color: rgba(245, 245, 245, 0.88);
            transition: all .5s ease 0s;
            z-index: 1;
            pointer-events: none;
            opacity: 0;
            visibility: hidden;
        }
    }
    &:hover {
        .product-img {
            a {
                img {
                    transform: scale(1.1);
                }
            }
            .product-badge-2 {
                top: 15px;
                right: 20px;
                opacity: 1;
                visibility: visible;
            }
            .product-action-3-wrap {
                opacity: 1;
                visibility: visible;
                transform: translateY(-50%) scale(1);
            }
        }
        .product-action-wrap {
            opacity: 1;
            visibility: visible;
            .product-action-btn-1 {
                &:first-child {
                    transform: translateY(0px);
                }
                &:last-child {
                    transform: translateY(0px);
                }
            }
        }
        .product-action-2-wrap {
            bottom: 0px;
            opacity: 1;
            visibility: visible;
        }
        &.product-overly {
            &:before {
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                opacity: 1;
                visibility: visible;
            }
        }
        .product-content {
            &.product-content-position {
                bottom: 20px;
                left: 25px;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.product-nav-1 {
	position: absolute;
	z-index: 999;
	right: 0;
	top: 0;
    @media #{$xs-layout} {
        right: 45%;
        top: 20px;
        transform: translateX(50%);
    }
    @media #{$sm-layout} {
        right: 47%;
    }
    i {
        font-size: 24px;
        color: #555252;
        width: 30px;
        height: 30px;
        text-align: center;
        border: 1px solid #e1e1e1;
        transition: all .3s ease 0s;
        &:hover {
            color: $white;
            background-color: $theme-color;
        }
    }
    &.product-prev-1 {
        margin-right: 35px;
    }
}

.product-slider-active-1 {
    &.swiper-container {
        padding: 90px 0 0;
        @media #{$xs-layout} {
            padding: 85px 0 0;
        }
    }
}

.home-single-product-img {
    margin-left: 70px;
    overflow: hidden;
    @media #{$lg-layout} {
        margin-left: 0px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
        margin-bottom: 25px;
    }
    a {
        display: block;
        img {
            width: 100%;
            transition: all .4s ease 0s;
            transform: scale(1);
        }
    }
    &:hover {
        a {
            img {
                transform: scale(.9);
            }
        }
    }
}

.home-single-product-content {
    h2 {
        font-weight: 600;
        font-size: 30px;
        margin: 0;
    }
    h3 {
        color: $theme-color;
        font-weight: 600;
        font-size: 24px;
        margin: 10px 0 28px;
        @media #{$md-layout} {
            margin: 10px 0 15px;
        }
        @media #{$xs-layout} {
            margin: 10px 0 15px;
        }
    }
    p {
        font-family: $montserrat;
        font-weight: 500;
        margin: 0;
    }
}

.product-color {
    display: flex;
    align-items: center;
    margin: 32px 0 56px;
    @media #{$lg-layout} {
        margin: 25px 0 31px;
    }
    @media #{$md-layout} {
        margin: 20px 0 26px;
    }
    @media #{$xs-layout} {
        margin: 20px 0 26px;
    }
    span {
        color: #303030;
        font-size: 16px;
        font-weight: 600;
        display: inline-block;
        margin: 0 20px 0 0;
    }
    ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li {
            margin-right: 15px;
            display: flex;
            @media #{$lg-layout} {
                margin-right: 12px;
            }
            &:last-child {
                margin-right: 0;
            }
            a {
                width: 20px;
                height: 20px;
                font-size: 0;
                display: inline-block;
                position: relative;
                &.pink {
                    background-color: #e930d5;
                }
                &.yellow {
                    background-color: #e97730;
                }
                &.purple {
                    background-color: #8b30e9;
                }
            }
        }
    }
    &.product-details-color {
        margin: 22px 0 32px;
        @media #{$md-layout} {
            margin: 22px 0 20px;
        }
        @media #{$xs-layout} {
            margin: 22px 0 20px;
        }
        ul {
            li {
                a {
                    position: relative;
                    &.active {
                        &:before {
                            position: absolute;
                            content: "\e64d";
                            font-family: 'themify';
                            top: 50%;
                            left: 50%;
                            transform: translateY(-50%) translateX(-50%);
                            font-size: 14px;
                            color: $white;
                            line-height: 1;
                        }
                    }
                }
            }
        }
    }
}

.product-details-action-wrap {
	display: flex;
	align-items: center;
}

.product-quality {
    width: 80px;
    overflow: hidden;
    position: relative;
    border: 1px solid #e8e8e8;
    > input {
        width: 83px;
        border: none;
        height: 60px;
        line-height: 50px;
        text-align: center;
        font-size: 14px;
        color: #8f8f8f;
        padding: 0;
        font-family: $poppins;
        &::-moz-input-placeholder {
            color: #8f8f8f;
            opacity: 1;
        }
        &::-webkit-input-placeholder {
            color: #8f8f8f;
            opacity: 1;
        }
    }
    .qtybutton {
        position: absolute;
        color: #8f8f8f;
        left: 10px;
        cursor: pointer;
        
        &.inc {
            left: auto;
            right: 10px;
            font-size: 20px;
            top: 15px;
            
        }
        &.dec {
            top: -2px;
            font-size: 30px;
            line-height: 60px;
        }
    }
}

.single-product-cart {
    margin: 0 24px 0 10px;
    &.mrg-none {
        margin: 0 24px 0 0px;
    }
    @media #{$xs-layout} {
        margin: 0 10px 0 10px;
    }
    > a {
        display: inline-block;
        font-weight: 600;
        color: $white;
        background-color: $theme-color;
        padding: 20px 37px;
        z-index: 1;
        @media #{$xs-layout} {
            padding: 20px 17px;
        }
    }
}

.single-product-wishlist {
    margin-right: 23px;
}

.single-product-wishlist , 
.single-product-compare {
    a {
        font-size: 18px;
        color: #010101;
        &:hover {
            color: $theme-color;
        }
    }
}

.tab-content {
    &.jump {
        .tab-pane {
            display: block;
            height: 0;
            opacity: 0;
            overflow: hidden;
            &.active {
                display: block;
                height: auto;
                opacity: 1;
                overflow: visible;
                animation-name: lastudioZoomIn;
                animation-duration: 500ms;
                animation-timing-function: cubic-bezier(0.26, 0.69, 0.37, 0.96);
                animation-play-state: running;
            }
        }
    }
}

.quickview-modal-style {
    .modal-dialog {
        max-width: 1070px; 
        @media #{$lg-layout} {
            max-width: 910px;
        }
        @media #{$md-layout} {
            max-width: 700px;
        }
        @media #{$xs-layout} {
            max-width: 320px;
            margin: 0 auto;
        }
        @media #{$sm-layout} {
            max-width: 500px;
            margin: 0 auto;
        }
        @media #{$esm-layout} {
            max-width: 450px;
            margin: 0 auto;
        }
        .modal-content {
            border: none;
            border-radius: 0;
            position: relative;
            padding: 20px;
            @media #{$xs-layout} {
                padding: 15px 15px 30px;
            }
            .modal-header {
                padding: 0;
                position: absolute;
                right: 20px;
                top: 20px;
                z-index: 9;
                @media #{$xs-layout} {
                    right: 15px;
                    top: 15px;
                }
                a {
                    background-color: #333; 
                    font-size: 15px;
                    color: $white;
                    text-align: center;
                    height: 40px;
                    width: 40px;
                    line-height: 42px;
                    @media #{$xs-layout} {
                        font-size: 11px;
                        height: 32px;
                        width: 32px;
                        line-height: 32px;
                    }
                    i {
                        line-height: 1;
                    }
                    &:hover {
                        background-color: $theme-color;
                        color: $white;
                    }
                }
            }
            .modal-body {
                padding: 0;
                .modal-img-wrap {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
.grid-sizer {
	width: 25%;
}

.product-price {
    span {
        color: #808080;
        font-size: 18px;
        font-weight: 500;
        &.new-price {
            color: $theme-color-pink;
        }
        &.old-price {
            margin-right: 13px;
            color: #555252;
            text-decoration: line-through;
        }
    }
}

.tab-style-1 {
    @media #{$xs-layout} {
        margin: 35px 0 0;
        justify-content: center;
    }
    a {
        font-size: 18px;
        display: inline-block;
        font-weight: 500;
        color: #b9b9b9;
        font-family: $poppins;
        border-bottom: 2px solid transparent;
        margin: 0 23px 0 0;
        padding: 0 0 12px;
        @media #{$xs-layout} {
            margin: 0 15px 0 0;
            font-size: 16px;
            padding: 0 0 5px;
        }
        @media #{$sm-layout} {
            margin: 0 20px 0 0;
            font-size: 18px;
        }
        &:last-child {
            margin: 0 0 0 0;
        }
        &.active {
            color: $theme-color;
            border-bottom: 2px solid $theme-color;
        }
    }
    &.tab-center {
        justify-content: center;
    }
    &.tab-border-none {
        a {
            border-bottom: none;
            padding: 0 0 0px;
            &.active {
                border-bottom: none;
            }
            @media #{$xs-layout} {
                padding: 0 0 3px;
            }
        }
    }
    &.tab-style-1-margin {
        margin: 0 0 55px;
        @media #{$xs-layout} {
            margin: 0 0 30px;
        }
    }
    &.tab-style-1-modify {
        margin: 0 0 60px;
        border-bottom: 2px solid #f1f1f1;
        @media #{$xs-layout} {
            margin: 0 0 30px;
        }
        a {
            padding: 0 0 6px;
            margin: 0 55px -2px 0;
            @media #{$xs-layout} {
                margin: 0 11px 8px 0;
            }
            @media #{$sm-layout} {
                margin: 0 15px -2px 0;
            }
            &:last-child {
                margin: 0 0px -2px 0;
            }
        }
    }
}

@media #{$xs-layout} {
    .product-area {
        &.pb-60 {
            padding-bottom: 30px;
        }
    }
}






/*-------- 11. Category style ---------*/

.single-category-weap {
    .category-img {
        a {
            img {
                width: 100%;
                transition: all .4s ease 0s;
            }
        }
    }
    .category-content {
        h4 {
            font-size: 16px;
            margin: 0;
            a {
                color: $black;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
    &:hover {
        .category-img {
            a {
                img {
                    transform: scale(.9);
                }
            }
        }
    }
}
.slider-category-area {
    position: relative;
}
.category-area-position {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 46px;
}
.single-category-wrap-2 {
    .category-img-2 {
        a {
            display: inline-block;
            position: relative;
            overflow: hidden;
            .category-normal-img {
                bottom: 0px;
                opacity: 1;
                visibility: visible;
                transition: all .3s ease 0s;
                position: relative;
            }
            .category-hover-img {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                left: 0;
                bottom: -20px;
                transition: all .4s ease 0s;
            }
            img {
                max-width: 100%;
            }
        }
    }
    .category-content-2 {
        margin-top: 13px;
        h4 {
            font-size: 16px;
            color: $white;
            font-weight: 500;
            margin: 0;
            a {
                color: $white;
            }
        }
        &.category-content-2-black {
            h4 {
                font-size: 16px;
                color: $black;
                font-weight: 500;
                margin: 0;
                a {
                    color: $black;
                }
            }
        }
    }
    &:hover {
        .category-img-2 {
            a {
                .category-normal-img {
                    opacity: 0;
                    visibility: hidden;
                    bottom: 20px;
                }
                .category-hover-img {
                    opacity: 1;
                    visibility: visible;
                    bottom: 0px;
                }
            }
        }
    }
}
.single-category-wrap-3 {
    position: relative;
    overflow: hidden;
    a {
        display: block;
        img {
            width: 100%;
            transition: all .5s ease 0s;
        }
    }
    .category-content-3 {
        position: absolute;
        left: 20px;
        bottom: 0px;
        background-color: $white;
        padding: 18px 64px 14px 20px;
        transition: all .5s ease 0s;
        opacity: 0;
        visibility: hidden;
        @media #{$md-layout} {
            padding: 18px 20px 14px 20px;
        }
        h3 {
            font-size: 18px;
            font-weight: 500;
            margin: 0 0 5px;
            line-height: 1;
            letter-spacing: .5px;
        }
        span {
            font-weight: 500;
            letter-spacing: .2px;
        }
    }
    &:hover {
        a {
            img {
                transform: scale(1.1);
                transition: all .3s ease 0s;
            }
        }
        .category-content-3 {
            bottom: 20px;
            opacity: 1;
            visibility: visible;
        }
    }
}











/*-------- 02. Header style ---------*/

.logo {
    a {
        display: inline-block;
        img {
            max-width: 100%;
        }
    }
}
.welcome-text {
    p {
        color: #383837;
        margin: 0;
        font-family: $montserrat;
    }
}
.language-currency-wrap {
	display: flex;
    justify-content: flex-end;
    .border-style {
        position: relative;
        &::before {
            background-color: #383838;
            position: absolute;
            height: 12px;
            width: 1px;
            content: "";
            right: -21px;
            top: 50%;
            transform: translateY(-50%);
            @media #{$xs-layout} {
                right: -18px;
            }
            @media #{$sm-layout} {
                right: -21px;
            }
        }
    }
    .currency-wrap {
        margin-right: 44px;
        @media #{$xs-layout} {
            margin-right: 35px;
        }
        @media #{$sm-layout} {
            margin-right: 44px;
        }
    }
    .language-wrap {
        a {
            img {
                margin-right: 5px;
                margin-top: -3px;
            }
        }
    }
    .currency-wrap , 
    .language-wrap {
        position: relative;
        > a {
            color: #383838;
            display: inline-block;
            line-height: 51px;
            i {
                font-size: 9px;
            }
        }
        .currency-dropdown , 
        .language-dropdown {
            background: #fff none repeat scroll 0 0;
            padding: 25px 15px 25px;
            position: absolute;
            right: 0;
            top: 100%;
            z-index: 9999;
            box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
            max-height: 636px;
            overflow-y: auto;
            display: none;
            width: 135px;
            ul {
                li {
                    display: block;
                    padding: 0 0 12px;
                    a {
                        display: block;
                        font-size: 13px;
                    }
                    &:last-child {
                        padding: 0 0 0px;
                    }
                }
            }
        }
        &:hover {
            > a {
                color: $theme-color;
            }
        }
    }
    &.language-currency-wrap-modify {
        justify-content: flex-start;
        .currency-wrap , 
        .language-wrap {
            .currency-dropdown , 
            .language-dropdown {
                width: 120px;
                left: 0;
                right: auto;
                @media #{$xs-layout} {
                    width: 110px;
                }
                @media #{$sm-layout} {
                    width: 120px;
                }
            }
        }
    }
}

.main-menu {
    > nav {
        > ul {
            > li {
                display: inline-flex;
                margin-right: 36px;
                position: relative;
                @media #{$lg-layout} {
                    margin-right: 16px;
                }
                &:last-child {
                    margin-right: 0;
                }
                > ul {
                    background: #fff none repeat scroll 0 0;
                    box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
                    display: block;
                    left: 0;
                    padding: 28px 25px 28px;
                    position: absolute;
                    top: 100%;
                    transform: rotateX(90deg);
                    transform-origin: center top 0;
                    transition: all 0.5s ease 0s;
                    visibility: hidden;
                    z-index: 99;
                    li {
                        display: block;
                        position: relative;
                        line-height: 1;
                        margin-bottom: 20px;
                        &:last-child {
                            margin-bottom: 0px;
                        }
                        a {
                            display: block;
                            color: $black;
                            font-weight: 400;
                            font-size: 14px;
                            text-transform: capitalize;
                        }
                        &:hover {
                            > a {
                                color: $theme-color;
                            }
                        }
                    }
                    &.sub-menu-style {
                        width: 190px;
                        text-align: left;
                    }
                    &.mega-menu-style {
                        width: 750px;
                        padding: 28px 30px 0px;
                        text-align: left;
                        &.mega-menu-mrg-1 {
                            left: -85px;
                            @media #{$lg-layout} {
                                left: -164px;
                            }
                        }
                        > li {
                            > ul {
                                display: flex;
                                flex-wrap: wrap;
                                > li {
                                    flex: 0 0 33.333%;
                                    max-width: 33.333%;
                                    > a {
                                        &.dropdown-title {
                                            font-size: 16px;
                                            font-weight: 600;
                                            margin-bottom: 30px;
                                        }
                                    }
                                    ul {
                                        li {
                                            margin-bottom: 22px;
                                            &:last-child {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                > a {
                    color: #383838;
                    font-weight: 500;
                    line-height: 120px;
                    display: inline-block;
                    position: relative;
                    &::after {
                        content: "";
                        height: 2px;
                        right: 0px;
                        bottom: 0px;
                        left: auto;
                        width: 0;
                        position: absolute;
                        transition: all .4s ease 0s;
                        background-color: $theme-color;
                    }
                }
                &:hover {
                    > a {
                        color: $theme-color;
                        &::after {
                            left: 0px;
                            right: auto;
                            width: 100%;
                            transition: all .4s ease 0s;
                        }
                    }
                    > ul {
                        transform: rotateX(0deg);
                        visibility: visible;
                    }
                }
            }
        }
    }
}

.header-action-wrap {
	display: flex;
	justify-content: flex-end;
    .header-action-style {
        margin-right: 17px;
        @media #{$xs-layout} {
            margin-right: 7px;
        }
        &:last-child {
            margin-right: 0px;
        }
        a {
            font-size: 23px;
            line-height: 1;
            display: flex;
        }
    }
    .header-action-cart {
        margin-right: 10px !important;
        @media #{$md-layout} {
            margin-right: 17px !important;
        }
        a {
            position: relative;
            span {
                &.product-count {
                    position: absolute;
                    top: -4px;
                    right: -10px;
                    width: 21px;
                    height: 21px;
                    line-height: 21px;
                    border-radius: 100%;
                    font-size: 14px;
                    font-weight: 500;
                    display: inline-block;
                    text-align: center;
                    color: $white;
                    font-family: $poppins;
                    @media #{$md-layout} {
                        top: -4px;
                        right: -6px;
                        width: 18px;
                        height: 18px;
                        line-height: 18px;
                        font-size: 11px;
                    }
                    @media #{$xs-layout} {
                        top: -4px;
                        right: -6px;
                        width: 18px;
                        height: 18px;
                        line-height: 18px;
                        font-size: 11px;
                    }
                    &.bg-black {
                        background-color: $black;
                    }
                }
            }
        }
    }
}

.header-height-1 {
	min-height: 171px;
    @media #{$md-layout} {
        min-height: 83px;
    }
    @media #{$xs-layout} {
        min-height: 73px;
    }
}

.header-height-2 {
	min-height: 120px;
    @media #{$md-layout} {
        min-height: 85px;
    }
    @media #{$xs-layout} {
        min-height: 75px;
    }
}

.sticky-bar {
    &.stick {
        animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
        left: 0;
        background-color: #ffffff;
        transition: all .3s ease 0s;
        @media #{$md-layout} {
            &.header-bottom {
                padding: 25px 0;
            }
        }
        @media #{$xs-layout} {
            &.header-bottom {
                padding: 20px 0;
            }
        }
    }
}

.stick {
    .main-menu {
        > nav {
            > ul {
                > li {
                    > a {
                        line-height: 80px;
                    }
                }
            }
        }
    }
}

.header-search-1 {
    position: relative;
    .search-toggle {
        z-index: 99;
        i {
            display: block;
            &.s-close {
                font-size: 35px;
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .s-close {
            opacity: 0;
        }
        &.open {
            .s-close {
                opacity: 1;
            }
            .s-open {
                opacity: 0;
            }
        }
    }
}

.search-wrap-1 {
    background-color: transparent;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease 0s;
    visibility: hidden;
    width: 340px;
    z-index: -99;
    padding-bottom: 1px;
    margin-right: 10px;
    @media #{$xs-layout} {
        top: 200%;
        transform: inherit;
        width: 300px;
        left: -154px;
        margin-right: 0;
    }
    @media #{$sm-layout} {
        top: 50%;
        transform: translateY(-50%);
        width: 300px;
        left: inherit;
        margin-right: 10px;
    }
    form {
        position: relative;
        overflow: hidden;
        input {
            background-color: #fff;
            border: 1px solid #E2DCDC;
            color: $black;
            line-height: 30px;
            padding: 5px 60px 5px 20px;
            width: 100%;
            border-radius: 50px;
            &::-moz-input-placeholder {
                color: $black;
                opacity: 1;
            }
            &::-webkit-input-placeholder {
                color: $black;
                opacity: 1;
            }
        }
        button {
            &.button-search {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                padding: 0;
                border: none;
                background-color: transparent;
                height: 100%;
                font-size: 18px;
                border-left: 1px solid #E2DCDC;
                padding: 0 15px;
                color: $black;
                line-height: 45px;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
    &.open {
        opacity: 1;
        right: 100%;
        visibility: visible;
        z-index: 99;
        @media #{$xs-layout} {
            right: inherit;
        }
        @media #{$sm-layout} {
            right: 100%;
        }
    }
}

.main-wrapper {
    .body-overlay {
        background-color: #232324;
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        transition: all 0.5s ease-in-out 0s;
        visibility: hidden;
        width: 100%;
        z-index: 9999;
    }
    &.overlay-active {
        .body-overlay {
            opacity: .7;
            visibility: visible;
            z-index: 9999;
        }
    }
} 

.sidebar-cart-active {
	position: fixed;
	top: 0;
	width: 420px;
	padding: 104px 50px 50px;
	min-height: 100vh;
	bottom: 0;
	z-index: 9999;
	right: 0;
	visibility: hidden;
	opacity: 0;
	transition: all 250ms ease-in;
	transform: translate(200px,0);
	box-shadow: 0 0 87px 0 rgba(0,0,0,.09);
    background-color: #fff;
    &.inside {
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
    }
    @media #{$md-layout} {
        width: 350px;
        padding: 70px 30px 50px;
    }
    @media #{$xs-layout} {
        padding: 60px 15px 50px;
        width: 300px;
    }
    @media #{$sm-layout} {
        padding: 60px 30px 50px;
        width: 340px;
    }
    .sidebar-cart-all {
        .cart-close {
            position: absolute;
            top: 37px;
            right: 50px;
            font-size: 50px;
            line-height: 30px;
            color: #6D6D6D;
            transition: all 250ms ease-out;
            @media #{$md-layout} {
                font-size: 40px;
                right: 19px;
                top: 25px;
            }
            @media #{$xs-layout} {
                top: 27px;
                right: 17px;
                font-size: 30px;
            }
            &:hover {
                transform: rotate(90deg);
                color: $theme-color;
            }
        }
        .cart-content {
            > h3 {
                font-size: 18px;
                font-weight: 600;
                margin: 0 0 35px;
            }
            ul {
                margin: 0 -30px 0 0;
                padding: 0 30px 0 0;
                max-height: 310px;
                max-height: calc(100vh - 430px);
                overflow: auto;
                @media #{$md-layout} {
                    max-height: calc(100vh - 365px);
                }
                @media #{$xs-layout} {
                    max-height: calc(100vh - 360px);
                }
                li {
                    display: flex;
                    margin-bottom: 30px;
                    .cart-img {
                        flex: 0 0 70px;
                        a {
                            img {
                                width: 100%;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                            }
                        }
                    }
                    .cart-title {
                        margin-left: 20px;
                        h4 {
                            font-size: 15px;
                            margin: 0 0 8px;
                            font-weight: 500;
                            a {
                                color: $black;
                                &:hover {
                                    color: $theme-color;
                                }
                            }
                        }
                        span {
                            font-size: 15px;
                            color: $black;
                        }
                    }
                    .cart-delete {
                        display: flex;
                        flex-grow: 100;
                        justify-content: flex-end;
                        a {
                            display: block;
                            font-size: 20px;
                            height: 20px;
                            line-height: 17px;
                            overflow: hidden;
                            width: 20px;
                            text-align: right;
                            color: $black;
                            &:hover {
                                color: $theme-color;
                            }
                        }
                    }
                }
            }
            .cart-total {
                display: block;
                width: 100%;
                margin: 16px 0 26px;
                h4 {
                    font-size: 16px;
                    margin: 0;
                    font-weight: 600;
                    color: $black;
                    @media #{$md-layout} {
                        font-size: 15px;
                    }
                    @media #{$xs-layout} {
                        font-size: 15px;
                    }
                    span {
                        font-size: 15px;
                        color: $black;
                        float: right;
                        font-weight: 500;
                    }
                }
            }
            .cart-btn , 
            .checkout-btn {
                display: block;
                width: 100%;
                a {
                    width: 100%;
                    display: block;
                    text-align: center;
                    padding: 18px 20px 17px;
                    background-color: $black;
                    color: #fff;
                    text-transform: capitalize;
                    font-size: 16px;
                    line-height: 1;
                    font-weight: 500;
                    z-index: 1;
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
            .cart-btn {
                margin-bottom: 10px;
            }
        }
    }
}

.header-margin {
    margin: 30px 30px 0;
    @media #{$md-layout} {
        margin: 38px 38px 0;
    }
    @media #{$xs-layout} {
        margin: 0px 0px 0;
    }
}

.main-wrapper-2 {
    .body-overlay-2 {
        background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        transition: all 0.5s ease-in-out 0s;
        visibility: hidden;
        width: 100%;
        z-index: 9999;
    }
    &.overlay-active-2 .body-overlay-2 {
        opacity: 1;
        visibility: visible;
    }
} 

.off-canvas-active {
	position: fixed;
	top: 0;
	width: 370px;
	min-height: 100vh;
	bottom: 0;
	z-index: 111;
	right: 0;
	visibility: hidden;
	opacity: 1;
	transition: all 400ms ease-out;
	transform: translate(370px,0);
    background-color: #fff;
    box-shadow: 0 0 87px 0 rgba(0,0,0,.09);
    z-index: 9999;
    @media #{$md-layout} {
        width: 320px;
    }
    @media #{$xs-layout} {
        width: 280px;
    }
    @media #{$sm-layout} {
        width: 300px;
    }
    &.inside {
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
    }
    .off-canvas-close {
        position: absolute;
        left: -40px;
        top: 0px;
        z-index: 9;
        width: 40px;
        height: 40px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        background-color: $theme-color;
        @media #{$xs-layout} {
            width: 30px;
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            left: -30px;
        }
        i {
            transition: all .3s ease-in-out;
            display: inline-block;
            color: #fff;
            line-height: 40px;
            @media #{$xs-layout} {
                line-height: 30px;
            }
        }
        &:hover i {
            transform: rotate(90deg);
        }
    }
    & .off-canvas-wrap {
        padding: 40px 30px 50px;
        overflow: auto;
        height: 100%;
        @media #{$xs-layout} {
            padding: 30px 20px 50px;
        }
        .off-canvas-margin-padding {
            border-bottom: 1px solid #ddd;
            margin-bottom: 27px;
            padding-bottom: 33px;
        }
        .off-canvas-margin-padding-2 {
            border-bottom: 1px solid #ddd;
            margin-bottom: 22px;
            padding-bottom: 29px;
        }
    }
}

.slinky-mobile-menu {
	text-align: left;
    ul {
        li {
            a {
                color: $black;
                span {
                    color: #383838;
                    font-size: 14px;
                    font-weight: 500;
                }
                &:not(.back) {
                    padding: 8px 0px;
                }
                &:hover {
                    span {
                        color: $theme-color;
                    }
                }
            }
        }
    }
    &.slinky-theme-default {
        .next {
            &::after {
                font-size: 11px;
                color: $black;
            }
            &:hover {
                &::after {
                    color: $theme-color;
                }
            }
        }
        .back {
            &::before {
                font-size: 11px;
                width: 30px;
                height: 30px;
                line-height: 30px;
            }
        }
    }
}

.slinky-theme-default {
    background: transparent;
    ul {
        li {
            a {
                position: relative;
                &:not(.back) {
                    color: #fff;
                    &:hover {
                        color: $theme-color;
                        background: transparent;
                    }
                }
            }
        }
    }
    .next {
        &::after {
            content: "\e649";
            font-family: themify;
            font-weight: 900;
            opacity: 1;
            transition: all .3 ease 0s;
            color: $white;
            background: none;
        }
        &:hover {
            &::after {
                color: $theme-color;
            }
        }
    }
    .back {
        &::before {
            content: "\e649";
            font-family: themify;
            font-weight: 900;
            opacity: 1;
            transition: all .3 ease 0s;
            color: #fff;
            background: none;
            padding: 0;
            background-color: $black;
            text-align: center;
            margin: 0 0 13px 0px;
            border-radius: 3px;
        }
        &:hover {
            &::before {
                font-family: themify;
                background-color: $theme-color;
                opacity: 1;
            }
        }
    }
}

@media #{$md-layout} {
    .header-responsive-padding {
        padding: 25px 0;
    }
}
@media #{$xs-layout} {
    .header-responsive-padding {
        padding: 20px 0;
    }
}





/*-----------------------------------------------------------------------------------

    Template Name: Urdan - Minimal eCommerce HTML Template
    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Template default CSS
    02. Header style
    03. Slider style
    04. Banner style
    05. Section title style
    06. Product style
    07. Service style
    08. Blog style
    09. Footer style
    10. Brand logo style
    11. Category style
    12. Subscribe style
    13. Testimonial style
    14. Product details style
    15. Breadcrumb style
    16. Sidebar style
    17. Shop style
    18. About us style
    19. Contact us style
    20. Cart style
    21. Wishlist style
    22. Login register style
    23. My account style
    24. Compare style
    25. Checkout style

-----------------------------------------------------------------------------------*/

@import "variabls";
@import "common";
@import "header";
@import "slider";
@import "banner";
@import "section-title";
@import "product";
@import "service";
@import "blog";
@import "footer";
@import "brand-logo";
@import "category";
@import "subscribe";
@import "testimonial";
@import "product-details";
@import "breadcrumb";
@import "sidebar-style";
@import "shop";
@import "about-us";
@import "contact-us";
@import "cart";
@import "wishlist";
@import "login-register";
@import "my-account";
@import "compare";
@import "checkout";





















